import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

interface IQueryDataType {
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
        type: string
        start_ate: string
        end_date: string
      }[]
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    location_group_ids: number[]
  }
  iStartDate?: string
  iEndDate?: string
}

const query = gql`
  query BbbLocationList($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    listLocationDetails: listLocationDetailsV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }
  }
`

export const bbbLocationListConfigs = {
  trackedOnPnL: 'boolean',
  entityName: 'string',
  locationCode: 'string',
  locationName: 'string',
  status: 'string',
} as const

const useBbbLocationList = () => {
  const { variables } = useVariables()
  const { data, loading } = useQuery<IQueryDataType, IQueryVariablesType>(
    query,
    {
      variables: {
        iFilter: {
          location_group_ids: variables.bbbConstants?.ALL_LOCATION_GROUP_ID
            ? [variables.bbbConstants.ALL_LOCATION_GROUP_ID]
            : [],
        },
        iStartDate: moment().format('YYYY-MM-DD'),
        iEndDate: moment().format('YYYY-MM-DD'),
      },
      skip: !variables.bbbConstants,
    },
  )

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const locations = data.listLocationDetails.nodes.map((n) => {
        const entity = n.locationGroups.find((lg) => lg.type === 'Legal Entity')

        return {
          id: n.id.toString(),
          parentId: 'summary',
          trackedOnPnL: n.locationGroups.some((lg) => lg.type === 'Company'),
          entityName: entity?.name,
          locationCode: n.code,
          locationName: n.name,
          status:
            n.code < '0100' &&
            !n.locationGroups.some((lg) => lg.type === 'Brand')
              ? 'CLOSED'
              : '',
        }
      })

      return [
        {
          id: 'summary',
          parentId: 'root',
          entityName: 'TOTAL',
          locationCode: locations.filter((l) => l.trackedOnPnL).length,
        },
        ...locations.sort((a, b) => {
          if (!a.entityName && b.entityName) return 1

          if (a.entityName && !b.entityName) return -1

          return a.entityName === b.entityName
            ? a.locationCode.localeCompare(b.locationCode)
            : (a.entityName || '').localeCompare(b.entityName || '')
        }),
      ]
    }, [data]),
    loading,
  }
}

export default useBbbLocationList
