import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const fwWingstopListLocationGuestKpisConfigs = {
  smgDigitalOsatTotalCount: 'number',
  smgDigitalOsatFiveStarPercentage: 'percent',
  smgNonDigitalOsatTotalCount: 'number',
  smgNonDigitalOsatFiveStarPercentage: 'percent',
  smgDeliveryOsatTotalCount: 'number',
  smgDeliveryOsatFiveStarPercentage: 'percent',
  smgQscCount: 'number',
  smgQscScore: 'number',
  smgWingyouCount: 'number',
  smgWingyouScore: 'percent',
  smgDissatisfactionCount: 'number',
  smgDissatisfactionPercentage: 'percent',
  smgInaccurateCount: 'number',
  smgInaccuratePercentage: 'percent',
} as const

const useFwWingstopListLocationGuestKpis = buildMetricValuesHook({
  groupFilterType: 'store',
  metrics: {
    groups: ['Expo Module - Location SMG'],
  },
})

export default useFwWingstopListLocationGuestKpis
