import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

interface IInventoryConfig {
  defaultInventoryCategory?: string
  includeVendorInPurchaseTable: boolean
  varianceFilter: {
    typeVisible: boolean
    kpiVisible: boolean
    unitVisible: boolean
  }
  varianceTableTitle?: string
  topFiveKpi?: 'variancePercent' | 'avtPercent'
  shouldUseLatestWeek?: boolean
  queryVersion?: number
  hasDailyInventory: boolean
  modules: string[]
  hasKeyItems?: boolean
}

const defaultConfig = {
  defaultInventoryCategory: undefined,
  includeVendorInPurchaseTable: false,
  varianceFilter: {
    typeVisible: true,
    kpiVisible: true,
    unitVisible: true,
  },
  varianceTableTitle: 'Variance',
  topFiveKpi: 'variancePercent' as const,
  shouldUseLatestWeek: false,
  hasDailyInventory: true,
  modules: ['purchaseTable', 'inventoryTable', 'topFiveTable'],
}

const useInventoryConfig = (): IInventoryConfig => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'irmg_bk':
      case 'irmg_pop':
      case 'supreme_pop':
      case 'supreme_bk':
      case 'timoney_bk':
      case 'mbn_bk':
      case 'demo_bk':
      case 'demo_pop':
        return {
          ...defaultConfig,
          defaultInventoryCategory: 'Food',
          includeVendorInPurchaseTable: false,
        }
      case 'ghai_pop':
        return {
          ...defaultConfig,
          defaultInventoryCategory: 'Food',
          includeVendorInPurchaseTable: false,
          modules: [
            'purchaseTable',
            'dailyInventoryAnalysis',
            'inventoryTable',
            'topFiveTable',
          ],
        }
      case 'jitb':
        return {
          ...defaultConfig,
          defaultInventoryCategory: 'Food',
          includeVendorInPurchaseTable: false,
          varianceFilter: {
            typeVisible: false,
            kpiVisible: false,
            unitVisible: true,
          },
        }
      case 'viking_bk':
      case 'sandbox_bk':
        return {
          ...defaultConfig,
          defaultInventoryCategory: 'Food',
          includeVendorInPurchaseTable: true,
        }
      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          ...defaultConfig,
          defaultInventoryCategory: 'Food',
          includeVendorInPurchaseTable: true,
          varianceTableTitle: 'AvT',
          topFiveKpi: 'avtPercent',
          varianceFilter: {
            typeVisible: true,
            kpiVisible: false,
            unitVisible: false,
          },
          shouldUseLatestWeek: true,
          queryVersion: 2,
        }
      case 'bibibop':
        return {
          ...defaultConfig,
          defaultInventoryCategory: 'Food',
          includeVendorInPurchaseTable: true,
          varianceTableTitle: 'AvT',
          topFiveKpi: 'avtPercent',
          varianceFilter: {
            typeVisible: true,
            kpiVisible: false,
            unitVisible: false,
          },
          shouldUseLatestWeek: true,
          queryVersion: 2,
        }
      case 'mwb':
        return {
          ...defaultConfig,
          hasDailyInventory: false,
          modules: ['topFiveTable', 'purchaseTable', 'inventoryTable'],
          hasKeyItems: true,
          topFiveKpi: 'avtPercent',
        }

      case 'burgerworks':
        return {
          ...defaultConfig,
          hasDailyInventory: false,
          modules: ['topFiveTable', 'purchaseTable', 'inventoryTable'],
        }
      default:
        return defaultConfig
    }
  }, [brand])
}
export default useInventoryConfig
