import useFwWingstopListLocationGroupLossPreventionKpis, {
  fwWingstopListLocationGroupLossPreventionKpisConfigs,
} from './useListLocationGroupLossPreventionKpis'
import useFwWingstopListLocationGuestKpis, {
  fwWingstopListLocationGuestKpisConfigs,
} from './useListLocationGuestKpis'
import useFwWingstopListLocationLossPreventionKpis, {
  fwWingstopListLocationLossPreventionKpisConfigs,
} from './useListLocationLossPreventionKpis'

export const configs = {
  fwWingstopListLocationGroupLossPreventionKpis:
    fwWingstopListLocationGroupLossPreventionKpisConfigs,
  fwWingstopListLocationLossPreventionKpis:
    fwWingstopListLocationLossPreventionKpisConfigs,
  fwWingstopListLocationGuestKpis: fwWingstopListLocationGuestKpisConfigs,
}

export const api = {
  fwWingstopListLocationGroupLossPreventionKpis:
    useFwWingstopListLocationGroupLossPreventionKpis,
  fwWingstopListLocationLossPreventionKpis:
    useFwWingstopListLocationLossPreventionKpis,
  fwWingstopListLocationGuestKpis: useFwWingstopListLocationGuestKpis,
}
