import { useMemo, useState } from 'react'

export interface IDataType {
  bbbLocationList?: {
    open: boolean
    onOpen: () => void
    onClose: () => void
  }
}

const useBbbLocationList = () => {
  const openHook = useState<boolean>(false)

  return {
    bbbLocationList: useMemo(
      (): IDataType['bbbLocationList'] => ({
        open: openHook[0],
        onOpen: () => openHook[1](true),
        onClose: () => openHook[1](false),
      }),
      [openHook],
    ),
  }
}

export default useBbbLocationList
