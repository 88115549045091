import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import Popover from '@material-ui/core/Popover'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'
import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_12_MONTHS,
  TYPE_TRAILING_90_DAYS,
  TYPE_TRAILING_364_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'

import { useVariables } from '../variables'
import useDate from '../variables/hooks/common/date'
import DateFilterProvider, { useDateFilter } from './DateFitlerProvider'
import Select from './Select'
import useFirstDateOfYear from './hooks/useFirstDateOfYear'
import useYoyDates from './hooks/useYoyDates'
import useYtdEndDate from './hooks/useYtdEndDate'

const DATE_FILTER_TYPES = [
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_90_DAYS,
  TYPE_TRAILING_364_DAYS,
  TYPE_TRAILING_12_MONTHS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
  TYPE_CUSTOM,
] as const

export interface IPropsType {
  types?: typeof DATE_FILTER_TYPES[number][]
  defaultType?: typeof DATE_FILTER_TYPES[number]
  isDailyCustom?: boolean
  startDate?: string
  endDate?: string | 'now'
}

const StyledTextField = styled(TextField)`
  width: 300px;
  font-family: Lexend-Regular;
  cursor: pointer;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }

  > *,
  input {
    font-family: Lexend-Regular;
    cursor: pointer;
  }

  .MuiInputBase-root {
    border-radius: 0;
  }
`

const PopoverContentContainer = styled.div`
  width: 500px;
`

const DATE_DISPLAY_FORMAT = 'M/D/YY'

export const getTypeMapping = (
  type: typeof DATE_FILTER_TYPES[number],
  isDailyCustom?: boolean,
) => {
  if (type === TYPE_CUSTOM)
    return isDailyCustom ? 'custom_date' : 'custom_date_range'

  return type
}

const DateFilter = ({
  types,
  defaultType,
  isDailyCustom,
  startDate,
  endDate,
}: IPropsType) => {
  // FIXME: should use useVariables
  const { variables } = useVariables()
  const tempDate = useDate({
    types: types?.map((t) => getTypeMapping(t, isDailyCustom)),
    defaultType: !defaultType
      ? defaultType
      : getTypeMapping(defaultType, isDailyCustom),
    startDate,
    endDate,
  })
  const date = variables.date || tempDate.date
  const matches = useMediaQuery(MOBILE_WIDTH)
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLDivElement>(
    null,
  )
  const [open, setOpen] = useState(false)

  const onOpen = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      if (!date?.value) return

      setPopoverAnchorEl(
        (e.target as HTMLInputElement).parentElement as HTMLDivElement,
      )
      setOpen(true)
    },
    [date, setPopoverAnchorEl, setOpen],
  )
  const onClose = useCallback(() => {
    setPopoverAnchorEl(null)
    setOpen(false)
  }, [setPopoverAnchorEl, setOpen])

  const value = useMemo(() => {
    if (!date?.value) return

    const {
      type,
      year,
      dateRange: { startDate, endDate },
      displayName,
    } = date.value

    switch (type) {
      case 'year':
        return `Year ${year}`

      case 'custom_date':
      case 'custom_date_range':
        return startDate.isSame(endDate)
          ? startDate.format(DATE_DISPLAY_FORMAT)
          : `${startDate.format(DATE_DISPLAY_FORMAT)} to ${endDate.format(
              DATE_DISPLAY_FORMAT,
            )}`

      default:
        return displayName
    }
  }, [date])

  return (
    <>
      <StyledTextField
        value={value || ''}
        onChange={() => {}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!date ? <CircularProgress size={20} /> : <ArrowDropDownIcon />}
            </InputAdornment>
          ),
          readOnly: true,
        }}
        onClick={onOpen}
        variant="outlined"
        margin="dense"
        size="small"
        placeholder="Loading..."
      />

      {!date || !open ? null : !matches ? (
        <Popover
          onClose={onClose}
          anchorEl={popoverAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open
        >
          <PopoverContentContainer>
            <Select
              onClose={onClose}
              value={date.value}
              options={date.options}
              setDate={date.setDate}
              startDate={date.startDate}
              endDate={date.endDate}
            />
          </PopoverContentContainer>
        </Popover>
      ) : (
        <Dialog onClose={onClose} fullWidth open>
          <Select
            onClose={onClose}
            value={date.value}
            options={date.options}
            setDate={date.setDate}
            startDate={date.startDate}
            endDate={date.endDate}
          />
        </Dialog>
      )}
    </>
  )
}

export {
  DateFilterProvider,
  useDateFilter,
  useFirstDateOfYear,
  useYoyDates,
  useYtdEndDate,
}
export default DateFilter
