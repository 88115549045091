import useLocationDayPartPie, {
  locationDayPartPieConfigs,
} from './useLocationDayPartPie'
import useTrendLocationGroupDiscount, {
  trendLocationGroupDiscountConfigs,
} from './useTrendLocationGroupDiscounts'
import useTrendLocationGroupFinancialKpis, {
  trendLocationGroupFinancialKpisConfigs,
} from './useTrendLocationGroupFinancialKpis'
import useTrendLocationGroupLossPreventions, {
  trendLocationGroupLossPreventionConfigs,
} from './useTrendLocationGroupLossPreventions'
import useTrendLocationRevenueCenterSales, {
  trendLocationRevenueCenterSalesConfigs,
} from './useTrendLocationRevenueCenterSales'

export const configs = {
  trendLocationGroupFinancialKpis: trendLocationGroupFinancialKpisConfigs,
  trendLocationGroupDiscount: trendLocationGroupDiscountConfigs,
  trendLocationGroupLossPrevention: trendLocationGroupLossPreventionConfigs,
  locationDayPartPie: locationDayPartPieConfigs,
  trendLocationRevenueCenterSales: trendLocationRevenueCenterSalesConfigs,
}

export const api = {
  trendLocationGroupFinancialKpis: useTrendLocationGroupFinancialKpis,
  trendLocationGroupDiscount: useTrendLocationGroupDiscount,
  trendLocationGroupLossPrevention: useTrendLocationGroupLossPreventions,
  locationDayPartPie: useLocationDayPartPie,
  trendLocationRevenueCenterSales: useTrendLocationRevenueCenterSales,
}
