import { TableOptions } from '@tanstack/react-table'
import { useEffect, useMemo, useRef, useState } from 'react'

import { useVariables } from '../../variables'
import { sorter as linkSorter } from '../Link'
import { IApiKeyType, configs } from './useApi'
import { IDataType } from './useData'

export interface ITableStateOptionType<K extends IApiKeyType> {
  sorting?: {
    id: keyof typeof configs[K] | `<%- ${string} %>`
    desc: boolean
  }[]
  expanded?: true | string[]
}

const useTableState = <K extends IApiKeyType>(
  data: IDataType[],
  isReady: boolean,
  { sorting, expanded }: ITableStateOptionType<K>,
): Omit<TableOptions<IDataType>, 'data' | 'columns' | 'getCoreRowModel'> => {
  const { template } = useVariables()
  const initialSorting = useMemo(
    () =>
      (sorting || [])
        .map((s) => ({
          ...s,
          id: template(s.id as string) as string,
        }))
        .filter(({ id }) => id),
    [sorting, template],
  )
  const [internalSorting, setInternalSorting] = useState(initialSorting)
  const [internalExapand, setInternalExpand] = useState({})
  const isLoadedRef = useRef({ sorting: false, expanded: false })

  useEffect(() => {
    if (isLoadedRef.current.sorting || !isReady) return

    if (initialSorting.length === 0) return

    setInternalSorting(initialSorting)
    isLoadedRef.current.sorting = true
  }, [isReady, initialSorting, setInternalSorting])

  useEffect(() => {
    if (isLoadedRef.current.expanded || !isReady) return

    if (expanded instanceof Array)
      setInternalExpand(
        expanded.reduce(
          (result, key) => ({
            ...result,
            [key]: true,
          }),
          {},
        ),
      )

    isLoadedRef.current.expanded = true
  }, [isReady, data, expanded, setInternalExpand])

  return {
    initialState: {
      expanded: !expanded ? {} : true,
    },
    state: {
      expanded: internalExapand,
      sorting: internalSorting,
    },
    onExpandedChange: setInternalExpand,
    onSortingChange: setInternalSorting,
    sortingFns: {
      link: linkSorter,
    },
  }
}

export default useTableState
