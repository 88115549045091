import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const bibibopIntraday: IConfigsType = {
  'bibibop-corporate-intraday-65544b7b': {
    '/:brand/corporate-today': {
      variables: ['allStores'],
      intraday: {
        type: 'bar-chart',
        title: 'Today',
        api: 'bbbIntradaySales',
        width: 1100,
        height: 500,
        label: 'formattedHour',
        datasets: [
          {
            key: 'netSales',
            label: 'Hourly Sales',
            borderColor: COLORS.Expo,
            backgroundColor: COLORS.Expo,
            chartType: 'bar',
          },
          {
            key: 'cumulativeSales',
            label: 'Daily Sales',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
            chartType: 'line',
          },
          {
            key: 'cumulativeLaborPercent',
            label: 'Daily Labor %',
            borderColor: COLORS.Mango,
            backgroundColor: COLORS.Mango,
            chartType: 'line',
            yAxisID: 'y1',
            decimal: 1,
          },
          {
            key: 'burdenedLaborPercent',
            label: 'Burdened Labor %',
            borderColor: COLORS.DarkOrchid,
            backgroundColor: COLORS.DarkOrchid,
            chartType: 'line',
            yAxisID: 'y1',
            decimal: 1,
          },
          {
            key: 'netSalesBudget',
            label: 'Daily Sales Target',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
            chartType: 'line',
          },
          {
            key: 'lastYearSalesPlusTenPercent',
            label: 'Last Year + 10%',
            borderColor: COLORS.Mint,
            backgroundColor: COLORS.Mint,
            chartType: 'line',
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
          y1: {
            type: 'percent',
            decimal: 0,
          },
        },
        options: {
          scales: {
            y1: {
              position: 'right',
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      },
    },
  },
  bibibop: {
    '/:brand/today': {
      groupFilter: {
        api: 'locationFilter',
      },
      intraday: {
        type: 'bar-chart',
        title: 'Today',
        api: 'bbbIntradaySales',
        width: 1100,
        height: 500,
        label: 'formattedHour',
        datasets: [
          {
            key: 'netSales',
            label: 'Hourly Sales',
            borderColor: COLORS.Expo,
            backgroundColor: COLORS.Expo,
            chartType: 'bar',
          },
          {
            key: 'cumulativeSales',
            label: 'Daily Sales',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
            chartType: 'line',
          },
          {
            key: 'cumulativeLaborPercent',
            label: 'Daily Labor %',
            borderColor: COLORS.Mango,
            backgroundColor: COLORS.Mango,
            chartType: 'line',
            yAxisID: 'y1',
            decimal: 1,
          },
          {
            key: 'burdenedLaborPercent',
            label: 'Burdened Labor %',
            borderColor: COLORS.DarkOrchid,
            backgroundColor: COLORS.DarkOrchid,
            chartType: 'line',
            yAxisID: 'y1',
            decimal: 1,
          },
          {
            key: 'netSalesBudget',
            label: 'Daily Sales Target',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
            chartType: 'line',
          },
          {
            key: 'lastYearSalesPlusTenPercent',
            label: 'Last Year + 10%',
            borderColor: COLORS.Mint,
            backgroundColor: COLORS.Mint,
            chartType: 'line',
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
          y1: {
            type: 'percent',
            decimal: 0,
          },
        },
        options: {
          scales: {
            y1: {
              position: 'right',
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      },
    },
  },
}

export default bibibopIntraday
