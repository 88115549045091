import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useDateRange from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'

import { useDateFilter } from '../../../dateFilter'
import { useVariables } from '../../../variables'
import { revenueCenterSalesConfigs } from '../../../variables'
import { IApiDataType } from '../../types'

type IUnitType = 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'

type IMetricDataType = Record<
  string,
  {
    unit: IUnitType
    value: number
  }
>

interface IDataType {
  trendLocationMetricValuesByDateRangeNumber: {
    nodes: {
      businessMonth: number
      businessYear: number
      startDate: string
      endDate: string
      metricData: IMetricDataType
    }[]
  }
}

const query = gql`
  query TrendRevenueCenterSales(
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
    $iDateRangeNumber: Int!
  ) {
    trendLocationMetricValuesByDateRangeNumber(
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
      iDateRangeNumber: $iDateRangeNumber
    ) {
      nodes {
        businessMonth
        businessYear
        startDate
        endDate
        metricData
      }
    }
  }
`

const formatDateString = (dateStr: string) => moment(dateStr).format('M/D/YY')

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    switch (value.unit) {
      case 'PERCENTAGE':
      case 'DOLLAR':
        return {
          ...result,
          [_.camelCase(key)]: value.value * 100,
        }
      default:
        return {
          ...result,
          [_.camelCase(key)]: value.value,
        }
    }
  }, {})

export const trendLocationRevenueCenterSalesConfigs = {
  ...revenueCenterSalesConfigs,
  netSales: 'price',
  period: 'string',
} as const

const useTrendLocationRevenueCenterSales = () => {
  const { groupFilter } = useGroupFilter()
  const { endDate } = useDateFilter()
  const { variables } = useVariables()
  const dateRange = variables.lastTimeRange?.value[0][0] || ''
  const { dateRangeNumber, dateRangeGroupBy } = useDateRange(dateRange)

  let correctedEndDate = moment(endDate, 'YYYY-MM-DD').clone()
  const startOfToday = moment().startOf('day')
  if (correctedEndDate.isAfter(startOfToday)) {
    correctedEndDate = startOfToday
  }
  const correctedEndDateStr = correctedEndDate.format('YYYY-MM-DD')
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iEndDate: correctedEndDateStr,
      iGroupBy: dateRangeGroupBy,
      iFilter: {
        location_ids: groupFilter?.ids,
        metric_groups: [
          'Expo Module - Revenue Center Sales',
          'Expo Module - Fundamental Sales',
        ],
      },
      iDateRangeNumber: dateRangeNumber,
    },
    skip: !endDate || !dateRangeGroupBy || !dateRangeNumber,
  })

  return {
    data: useMemo((): IApiDataType => {
      const trendData = data?.trendLocationMetricValuesByDateRangeNumber.nodes

      if (!trendData) return null

      const result = trendData.map((td) => {
        const period = ((groupBy: string) => {
          switch (groupBy) {
            case 'last_x_periods':
              return `P${td.businessMonth} ${td.businessYear}`

            case 'last_x_weeks':
              return `${formatDateString(td.startDate)} - ${formatDateString(
                td.endDate,
              )}`

            default:
              return `${formatDateString(td.endDate)}`
          }
        })(dateRangeGroupBy)

        return {
          period,
          ...format(td.metricData),
        }
      })

      return result
    }, [data]),
    loading,
  }
}

export default useTrendLocationRevenueCenterSales
