import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'

interface IGuestSummaryNodeType {
  problemPercentage: number
  mostCommonProblem: string[]
  mostCommonDay: string[]
  mostCommonDaypart: string[]
}

interface IGuestSummaryType {
  locationGuestSummaryV2: {
    nodes: IGuestSummaryNodeType[]
  }
}

const query = gql`
  query LocationGuestSummaryV2(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
  ) {
    locationGuestSummaryV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocationId: $iLocationId
    ) {
      nodes {
        problemPercentage
        mostCommonProblem
        mostCommonDay
        mostCommonDaypart
      }
    }
  }
`

const useLocationGuestAiSummary = (): IApiDataType => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<IGuestSummaryType>(query, {
    variables: {
      iLocationId: groupFilter?.ids?.[0],
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const summary = data?.locationGuestSummaryV2.nodes[0]

      const summaryMessage = (() => {
        if (!summary)
          return `Great job!  None of your SMG responses had a problem!`

        if (summary.problemPercentage) {
          return `${summary.problemPercentage}% of your SMG responses had a problem.`
        } else {
          return `There are no SMG comments.`
        }
      })()
      const improvementMessage = (() => {
        if (!summary) return null

        let topProblems = ''
        if (summary.mostCommonProblem?.length === 1) {
          topProblems = `The top problem was: ${summary.mostCommonProblem[0]}.`
        } else if (summary.mostCommonProblem?.length > 1) {
          topProblems = `The top problems were: ${summary.mostCommonProblem?.join(
            ', ',
          )}.`
        }
        const mostCommonDay = !summary.mostCommonDay
          ? ''
          : `${summary.mostCommonDay
              .map((day: string) => `${day}s`)
              .join(', ')} have the most negative reviews.`

        const mostCommonDaypart = !summary.mostCommonDaypart
          ? ''
          : `${summary.mostCommonDaypart.join(', ')} ${
              summary.mostCommonDaypart.length > 1 ? 'have' : 'has'
            } the most negative reviews.`

        return topProblems + mostCommonDay + mostCommonDaypart
      })()

      return {
        conclusion: summaryMessage,
        insight: improvementMessage,
      }
    }, [data]),
    loading,
  }
}

export default useLocationGuestAiSummary
