import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IOptionsType } from '../types'

const queries = gql`
  query RevenueCenterSalesDefs($metricGroup: String!) {
    locationMetricDefs(condition: { metricGroup: $metricGroup }) {
      nodes {
        name
        code
      }
    }
  }
`

const useFarwestRevenueCenterSales = () => {
  const { data, loading } = useQuery(queries, {
    variables: {
      metricGroup: 'Expo Module - Revenue Center Sales This Year',
    },
  })

  return {
    data: useMemo(() => {
      const options: IOptionsType = [
        {
          value: 'net_sales',
          label: 'Total',
        },
      ]

      data?.locationMetricDefs.nodes.forEach(
        (d: { name: string; code: string }) => {
          switch (d.code) {
            case 'revenue_center_sales_online_this_year':
              options.push({
                value: d.code,
                label: 'Online (Pick Up)',
              })
              break

            case 'revenue_center_sales_takeoutcallincallcenter_this_year':
              options.push({
                value: d.code,
                label: 'Take Out - Call in/Call Center',
              })
              break

            case 'revenue_center_sales_dispatch_this_year':
              options.push({
                value: d.code,
                label: 'Online (Dispatch)',
              })
              break

            default:
              options.push({
                value: d.code,
                label: d.name
                  .replace('Revenue Center Sales - ', '')
                  .replace('This Year', ''),
              })
              break
          }
        },
      )

      return options
    }, [data]),
    loading,
  }
}

export default useFarwestRevenueCenterSales
