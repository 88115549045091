import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const mwbConfig: IConfigsType = {
  mwb: {
    '/:brand/systemwide_overview': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      systemwideOverview: {
        type: 'list',
        api: 'systemwideOverviewKpis',
        title: 'Systemwide Overview',
        fieldWidth: '120px',
        fields: [
          {
            content: {
              key: 'netSales',
            },
            bottom: 'Net Sales',
          },
          {
            content: {
              key: 'averageWeeklySales',
            },
            bottom: 'Company Average Weekly Sales',
          },
          {
            content: {
              key: 'netSalesSssPercent',
            },
            bottom: 'SSS %',
          },
          {
            content: {
              key: 'checkCount',
            },
            bottom: 'Check Count',
          },
          {
            content: {
              key: 'checkAverage',
              decimal: 2,
            },
            bottom: 'Check Average',
          },
          {
            content: {
              key: 'variableLaborCost',
            },
            bottom: 'Variable Labor %',
          },
          {
            content: {
              key: 'actualFbPercent',
            },
            bottom: 'Actual %',
          },
          {
            content: {
              key: 'theoreticalFbPercent',
            },
            bottom: 'Theoretical %',
          },
          {
            content: {
              key: 'avtFbPercent',
              color: '<%- value > 0.5 ? colors.Pomodoro : "" %>',
            },
            bottom: 'Variance %',
          },
        ],
      },
      breakdownByStore: {
        type: 'table',
        api: 'systemwideOverview',
        title: 'Breakdown by Store',
        pageSize: 20,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '200px',
          },
        },
        columns: [
          {
            key: 'Sales',
            title: 'Net Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'netSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Weekly Sales',
            title: 'Weekly Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'avgWeeklySales',
                title: '$',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'AvT',
            title: 'AvT',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'actualFbPercent',
                title: 'Actual %',
                align: 'center',
                sortable: true,
              },
              {
                key: 'theoreticalFbPercent',
                title: 'Theoretical %',
                align: 'center',
                sortable: true,
              },
              {
                key: 'avtFbPercent',
                title: 'Variance %',
                align: 'center',
                sortable: true,
                danger: 'positive',
                dangerThreshold: 0.5,
              },
            ],
          },
          {
            key: 'VariableLabor',
            title: 'Variable Labor',
            align: 'center',
            children: [
              {
                key: 'variableLaborCost',
                title: '%',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      revenueCenterAndDayPart: {
        type: 'grid',
        children: {
          revenueCenter: {
            type: 'table-v2',
            api: 'locationGroupRevenueCenter',
            title: 'Revenue Center',
            columns: [
              {
                key: 'name',
                title: 'Revenue Center',
                sortable: true,
              },
              {
                key: 'sales',
                title: 'Net Sales',
                unit: 'dollar',
                sortable: true,
              },
              {
                key: 'percentSales',
                title: '% Net Sales',
                sortable: true,
              },
              {
                key: 'checkCount',
                title: 'Check Count',
                sortable: true,
              },
              {
                key: 'checkAverage',
                title: 'Check Average',
                unit: 'dollar',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          dayPart: {
            type: 'table-v2',
            api: 'locationGroupDayPart',
            title: 'Day Part',
            columns: [
              {
                key: 'name',
                title: 'Day Part',
                sortable: true,
              },
              {
                key: 'sales',
                title: 'Sales',
                unit: 'dollar',
                sortable: true,
              },
              {
                key: 'salesPerHour',
                title: 'Sales per Hour',
                unit: 'dollar',
                sortable: true,
              },
              {
                key: 'percentSales',
                title: '% Net Sales',
                sortable: true,
              },
              {
                key: 'checkCount',
                title: 'Check Count',
                sortable: true,
              },
              {
                key: 'checkAverage',
                title: 'Check Average',
                unit: 'dollar',
                decimal: 2,
                sortable: true,
              },
            ],
          },
        },
      },
      averageWeeklySales: {
        type: 'line-chart',
        title: 'Average Weekly Sales',
        api: 'trendLocationGroupFinancialKpis',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'avgWeeklySales',
            label: 'Net Sales',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              title: {
                text: '$',
              },
            },
          },
        },
      },
      map: {
        type: 'map',
        api: 'listGeoInfo',
      },
    },
  },
}

export default mwbConfig
