import _ from 'lodash'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import getDateRanges from 'pared/data/getDateRanges'
import { getBrandDefaultCalendarStartDate } from 'pared/utils/brand'

import { IDateOptionNodeType, IDateOptionType, IDateType } from './types'
import useDateOptions from './useDateOptions'
import useGetCalendar from './useGetCalendar'
import useInfos from './useInfos'
import useSetDate from './useSetDate'

export interface IOptionType {
  types?: IDateType[]
  defaultType?: IDateType
  startDate?: string | 'this_year'
  endDate?: string | 'now'
}

export interface IDateDataType {
  value: IDateOptionNodeType
  options: IDateOptionType[]
  setDate: (date: IDateOptionNodeType) => void
  startDate: moment.Moment
  endDate?: moment.Moment
  getInfo: (diff: number) =>
    | undefined
    | {
        displayName: string
        dateRange: IDateOptionNodeType['dateRange']
        yoy?: {
          displayName?: string
          dateRange?: IDateOptionNodeType['dateRange']
        }
      }
  getCalendar: ReturnType<typeof useGetCalendar>
}

export interface IDataType {
  date?: IDateDataType
}

const useDate = ({
  types = ['week', 'period', 'quarter', 'year', 'yesterday', 'trailing_7_days'],
  defaultType = types.includes('period') ? 'period' : types[0],
  startDate: customStartDate,
  endDate: customEndDate,
}: IOptionType) => {
  const brandDefaultCalendarStartDate = getBrandDefaultCalendarStartDate()
  const { startDate, startDateStr, endDate, endDateStr } = useMemo(() => {
    const startDateStr = (() => {
      switch (customStartDate) {
        case 'this_year':
          return moment().startOf('years').format('MM/DD/YYYY')

        default:
          return customStartDate
            ? moment.utc(customStartDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
            : brandDefaultCalendarStartDate || '01/01/2019'
      }
    })()
    const endDateStr = (() => {
      if (!customEndDate) return

      return (
        customEndDate === 'now'
          ? moment.utc()
          : moment.utc(customEndDate, 'YYYY-MM-DD')
      ).format('MM/DD/YYYY')
    })()

    return {
      startDate: moment.utc(startDateStr, 'MM/DD/YYYY'),
      startDateStr,
      endDate: endDateStr ? moment.utc(endDateStr, 'MM/DD/YYYY') : undefined,
      endDateStr,
    }
  }, [customStartDate, customEndDate, brandDefaultCalendarStartDate])
  const { search } = useLocation()
  const state = useAsync(
    () => getDateRanges(startDateStr, endDateStr),
    [search, startDateStr, endDateStr],
  )
  const dateRangeMap = state.value?.dateRangeMap || null
  const { currentDate, setDate } = useSetDate(types, dateRangeMap)
  const dateOptions = useDateOptions(types, currentDate, dateRangeMap)
  const infos = useInfos(types, currentDate, dateOptions)
  const getCalendar = useGetCalendar(dateRangeMap)

  useEffect(() => {
    if (!dateOptions || infos.date) return

    const option = dateOptions.find(
      (o) => 'type' in o && o.type === defaultType,
    )

    if (option) setDate(option as IDateOptionNodeType)
  }, [defaultType, dateOptions, infos, setDate])

  return {
    date: useMemo((): IDataType['date'] => {
      const periodCalendar = getCalendar('period')

      if (!dateOptions || !periodCalendar || !infos.date) return

      return {
        value: infos.date,
        options: dateOptions,
        setDate,
        startDate: moment.utc(startDate, 'MM/DD/YYYY'),
        endDate: endDate ? moment.utc(endDate, 'MM/DD/YYYY') : undefined,
        getInfo: (diff: number) => {
          const info = infos.dateByDiff[diff]

          if (!info) {
            if (
              infos.date?.type === 'custom_date' ||
              infos.date?.type === 'yesterday'
            ) {
              const date = infos.date.dateRange.startDate
                .clone()
                .add(diff, 'days')

              return {
                displayName: date.format('M/D/YY'),
                dateRange: {
                  startDate: date,
                  startDateStr: date.format('YYYY-MM-DD'),
                  endDate: date,
                  endDateStr: date.format('YYYY-MM-DD'),
                },
              }
            }

            return
          }

          const yoy = Object.values(infos.dateByDiff).find(
            (i) => info.year - 1 === i.year && info.id === i.id,
          )

          return {
            displayName: info.id,
            dateRange: info.dateRange,
            yoy: yoy && {
              displayName: yoy.id,
              dateRange: yoy.dateRange,
            },
          }
        },
        getCalendar,
      }
    }, [startDate, endDate, dateOptions, infos, setDate, getCalendar]),
  }
}

export default useDate
