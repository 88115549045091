import useBbbConstants, {
  IDataType as IBbbConstantsDataType,
} from './useBbbConstants'
import useBbbLocationList, {
  IDataType as IBbbLocationListDataType,
} from './useBbbLocationList'
import useBbbPnl, {
  IDataType as IBbbPnlDataType,
  IOptionType as IBbbPnlOptionType,
} from './useBbbPnl'

export type IVariablesType =
  | 'bbbPnl'
  | ({ type: 'bbbPnl' } & IBbbPnlOptionType)
  | 'bbbLocationList'
  | 'bbbConstants'
export type IDataType = IBbbPnlDataType &
  IBbbLocationListDataType &
  IBbbConstantsDataType

export const hooks = [
  ['bbbPnl', useBbbPnl],
  ['bbbLocationList', useBbbLocationList],
  ['bbbConstants', useBbbConstants],
] as const
