import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'
import { toUsdStr } from 'pared/utils/number'

import { IApiDataType } from '../../../types'

interface IDirectorInfo {
  id: number
  given_name: string
  family_name: string
  preferred_name: string
}

interface ILocationInfo {
  id: number
  code: string
  name: string
  director: IDirectorInfo
}
interface ICorporateLossPreventionLocations extends ILocationInfo {
  discount: number
  expected: number
  opportunityCost: number
  annualOpportunityCost: number
}

interface ICorporateLossPreventionSummaryDataType {
  type: string
  locations: ICorporateLossPreventionLocations[]
  annualOpportunityCost: number
  opportunityCost: number
  totalNetSales: number
  annualTotalNetSales: number
}

interface ILocationLossPreventionSummaryNodeType {
  locationCode: string
  locationName: string
  amount: number
  employeeDiscountInfos: {
    employeeName: string
  }[]
  annualOpportunityCost: number
}

type ILossPreventionSummaryType =
  | {
      locationULossPreventionSummary: {
        nodes: ILocationLossPreventionSummaryNodeType[]
      }
    }
  | {
      corporateULossPreventionSummary: {
        nodes: ICorporateLossPreventionSummaryDataType[]
      }
    }

const query = gql`
  query LocationLossPreventionSummary(
    $iBrandLocationGroupId: Int!
    $iLocationGroupId: Int!
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iMetricInfo: JSON!
    $hasGroupBy: Boolean!
  ) {
    locationULossPreventionSummary(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iMetricInfo: $iMetricInfo
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationCode
        locationName
        amount
        employeeDiscountInfos
        annualOpportunityCost
      }
    }

    corporateULossPreventionSummary(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) @include(if: $hasGroupBy) {
      nodes {
        type
        locations
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`

const useULossPrevention = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const locationGroupId = groupFilter?.ids[0] || 0
  const locationId = groupFilter?.ids[0] || 0
  const { brand } = useBrands()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const { data, loading } = useQuery<ILossPreventionSummaryType>(query, {
    variables: {
      iBrandLocationGroupId: brandLocationGroupId,
      iLocationGroupId: locationGroupId,
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
      iMetricInfo: {
        grossSalesMetric: 'gross_sales',
        discountCompsTotalMetric: 'discount_comps_total',
        discountCompsPercentTotalMetric: 'discount_comps_percent_total',
      },
      hasGroupBy,
    },
    skip: !brandLocationGroupId || !groupFilter || !startDate || !endDate,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      if ('locationULossPreventionSummary' in data) {
        const summary = data?.locationULossPreventionSummary.nodes[0]
        const annualOpportunityCost =
          summary.annualOpportunityCost > 0
            ? summary.annualOpportunityCost / 100.0
            : 0

        const employeeDiscounting = (() => {
          if (!summary) return null

          const employeeDiscountInfos = summary.employeeDiscountInfos || []
          if (employeeDiscountInfos.length === 0)
            return 'No employees are discounting above the Company Average for this time period.'

          return `<strong>${employeeDiscountInfos[0].employeeName}</strong> is discounting more than company averages.  Consider retraining or addressing issue with your team member.`
        })()

        return {
          title: 'Loss Prevention',
          total: annualOpportunityCost,
          detail: `${employeeDiscounting} ${
            annualOpportunityCost
              ? `Annual opportunity cost for all employees: ${toUsdStr(
                  annualOpportunityCost,
                )}`
              : ''
          }`,
        }
      } else {
        const corprateSummary = data.corporateULossPreventionSummary.nodes
        const totalStoreCount = corprateSummary?.reduce(
          (total: number, row: ICorporateLossPreventionSummaryDataType) =>
            total + (row?.locations?.length || 0),
          0,
        )

        const LossPreventionAnnualOpportunityCost = corprateSummary?.reduce(
          (total, row) => total + row.annualOpportunityCost,
          0,
        )

        const lossPreventionBasisPoint = (
          (10000.0 * LossPreventionAnnualOpportunityCost) /
          corprateSummary[0]?.annualTotalNetSales
        ).toFixed(0)

        return {
          title: 'Loss Prevention',
          total: LossPreventionAnnualOpportunityCost,
          detail: `
          For the trailing 30 days, ${totalStoreCount} restaurants are
          discounting more than expected.
          Consider retraining your team to save up to
          ${toUsdStr(LossPreventionAnnualOpportunityCost)} annually -
          which amounts to a ${lossPreventionBasisPoint} basis points
          improvement for the entire company.
        `,
          hasDetails: true,
        }
      }
    }, [data]),
    loading,
  }
}

export default useULossPrevention
