import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiType } from '../../types'

const QUERY_NAME = 'listLocationMetricValues'
const query = gql`
  query (
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    ${QUERY_NAME}(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        metricData
      }
    }
  }
`

export const locationDayPartConfigs = {
  name: 'string',
  sales: 'price',
  checkCount: 'number',
  checkAverage: 'price',
  percentSales: 'percent',
  salesPerHour: 'price',
} as const

const useLocationDayPart = (): IApiType => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()

  const iFilter = {
    location_ids: groupFilter?.ids,
    metric_groups: [
      'Expo Module - Day Part Sales',
      'Expo Module - Day Part Check Count',
      'Expo Module - Day Part Check Average',
      'Expo Module - Day Part Sales Per Hour',
      'Expo Module - Fundamental Sales',
    ],
  }

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const nodes = data?.[QUERY_NAME].nodes as
        | {
            locationId: string
            metricData: {
              [key: string]: {
                name: string
                unit: string
                value: number
              }
            }
          }[]
        | undefined

      if (!nodes) return null
      const mappedData: {
        [dayPart: string]: {
          [kpi: string]: number | string
        }
      } = {}
      const metricData = nodes[0]?.metricData
      let netSales = 0

      for (const key in metricData) {
        if (key === 'net_sales') {
          if (metricData[key].unit === 'CENT') {
            netSales = metricData[key].value / 100
          } else {
            netSales = metricData[key].value
          }
          continue
        }

        const kpiAndDayPart = key.split('day_part_')[1]
        let kpi = ''
        let dayPart = ''
        if (kpiAndDayPart.indexOf('sales_per_hour') === 0) {
          kpi = 'salesPerHour'
          dayPart = kpiAndDayPart.split('sales_per_hour_')[1]
        } else if (kpiAndDayPart.indexOf('sales') === 0) {
          kpi = 'sales'
          dayPart = kpiAndDayPart.split('sales_')[1]
        } else if (kpiAndDayPart.indexOf('check_count') === 0) {
          kpi = 'checkCount'
          dayPart = kpiAndDayPart.split('check_count_')[1]
        } else if (kpiAndDayPart.indexOf('check_average') === 0) {
          kpi = 'checkAverage'
          dayPart = kpiAndDayPart.split('check_average_')[1]
        }

        if (!mappedData[dayPart]) {
          mappedData[dayPart] = {
            name: metricData[key].name.split(' - ')[1],
            [kpi]: metricData[key].value,
          }
        } else {
          mappedData[dayPart][kpi] = metricData[key].value
        }
      }

      const rows = Object.values(mappedData).map((n) => {
        return {
          ...n,
          percentSales: (100 * (n.sales as number)) / netSales,
          id: n.name as string,
          parentId: 'root',
        }
      })

      return rows
    }, [groupFilter, data]),
    loading,
  }
}

export default useLocationDayPart
