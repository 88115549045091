import { useMemo } from 'react'

import { IApiDataType } from '../../../types'
import buildLocationEmployeeMetricValuesHook from '../../utils/buildLocationEmployeeMetricValuesHook'

const managerOnDutyDataConfigs = {
  laborShiftCount: 'number',
  grossSales: 'price',
  position: 'string',
  laborTotalCostPercent: 'percent',
} as const

export const burgerworksListManagerOnDutyDataConfigs = {
  ...managerOnDutyDataConfigs,
  employeeName: 'string',
} as const

const useManagerOnDutyData = buildLocationEmployeeMetricValuesHook({
  codes: [
    'gross_sales',
    'labor_shift_count',
    'labor_shift_position_tier',
    'labor_total_cost_percent',
  ],
})

const positionTierNameMap: { [positionTier: number]: string } = {
  1000: 'Team Leader - Driver',
  1100: 'Team Leader - NonDrive',
  1200: 'Team Leader',
  2000: 'Manager in Training',
  3000: 'Assistant Manager',
  4000: 'Manager',
  4100: 'Manager Job Step 1',
  4200: 'Manager Job Step 2',
  4300: 'Manager Job Step 3',
  5000: 'General Manager',
}

const useListManagerOnDutyDataData = () => {
  const { data: commonData, loading } = useManagerOnDutyData()

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return null

      const cleanedCommonData =
        commonData.filter((cd) => {
          if (cd?.grossSales) {
            return true
          }
          return false
        }) || []

      const keys = cleanedCommonData.reduce((sum, cd) => {
        const rowKeys = Object.keys(cd || {}) || []
        const newKeys = rowKeys?.filter((rk) => !sum.includes(rk))
        return [...sum, ...newKeys]
      }, [] as string[])

      const keyInfo = keys
        .filter((k) => Object.keys(managerOnDutyDataConfigs).includes(k))
        .reduce(
          (r, k) => {
            const kpiData =
              cleanedCommonData?.map((cd) => cd[k] as number) || []
            const sortedData = [...kpiData]?.sort((a, b) => a - b) || []

            return {
              ...r,
              [k]: {
                sortedData,
              },
            }
          },
          {} as {
            [k: string]: {
              sortedData: number[]
            }
          },
        )

      const newData =
        cleanedCommonData?.map((cd: any) => {
          return {
            ...cd,
            position: positionTierNameMap[cd.laborShiftPositionTier],
            ...Object.keys(cd).reduce((r, c) => {
              if (Object.keys(managerOnDutyDataConfigs).includes(c)) {
                const sorted = keyInfo[c].sortedData
                const rank =
                  sorted.findIndex((sortedItem) => sortedItem === cd[c]) + 1
                return {
                  ...r,
                  [`${c}Heatmap`]: ((rank - 1) / (sorted.length - 1)) * 100,
                }
              } else {
                return r
              }
            }, {}),
          }
        }) || []

      return newData
    }, [commonData, loading]),
    loading: loading,
  }
}

export default useListManagerOnDutyDataData
