import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import COLORS from 'pared/constants/colors'

import useConfig from './hooks/useConfig'

interface IProps {
  xAxisData: string[]
  chartOptions: any
  yAxisDataArr: any[]
  isLoading: boolean
  summaryMessage: string | React.ReactElement
  areasOfImprovementMessages: (string | React.ReactElement)[]
}

function Main({
  xAxisData,
  chartOptions,
  yAxisDataArr,
  isLoading,
  summaryMessage,
  areasOfImprovementMessages,
}: IProps) {
  const config = useConfig()

  if (isLoading) {
    return (
      <LoadingContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </LoadingContainer>
    )
  }

  if (xAxisData.length === 0) {
    return (
      <PageStatusDiv>
        There is no data within the date range selected. Please select a
        different date range.
      </PageStatusDiv>
    )
  }

  return (
    <>
      {config?.isSummaryHidden ? null : (
        <SummaryContainer>
          <ConclusionDiv>{summaryMessage}</ConclusionDiv>
          <InsightDiv>
            <InsightTitle>EXPO INSIGHTS</InsightTitle>
            {areasOfImprovementMessages.map((message) => (
              <Text>{message}</Text>
            ))}
          </InsightDiv>
        </SummaryContainer>
      )}
      <VerticalSpacer />
      <MobileWrapper>
        <MixedChart
          xAxisData={xAxisData}
          yAxisDataArr={yAxisDataArr}
          options={chartOptions}
        />
      </MobileWrapper>
    </>
  )
}

const LoadingContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  height: 400px;
  width: 100%;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const Text = styled.div`
  font-family: Lexend-Regular;
  font-size: 15px;
  flex: 5;
`

const VerticalSpacer = styled.div`
  height: 40px;
`

const MobileWrapper = styled.div`
  @media ${MOBILE_WIDTH} {
    overflow: scroll hidden;
    padding: 30px;
    border: 1px solid;
  }
`

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 1000px;

  @media ${MOBILE_WIDTH} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

const ConclusionDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: ${COLORS.Salt};
  padding: 30px;

  border-radius: 10px 10px 0 0;
  background: #0071e3;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightDiv = styled.div`
  padding: 30px;
  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};

  border-radius: 0 0 10px 10px;
  border: 1px solid #81abfd;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightTitle = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 0 0 15px 0;
`

export default Main
