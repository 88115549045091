import _ from 'lodash'
import { useMemo, useState } from 'react'

import {
  LAST_8_WEEKS,
  LAST_13_PERIODS,
  LAST_14_DAYS,
} from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'

interface IDateRangeType {
  timeFrame: 'days' | 'weeks' | 'periods'
  lastDateRangeNumber: number
}
export interface IOptionType {
  optionItems?: IDateRangeType[]
}

export interface IDataType {
  lastTimeRange?: {
    options: {
      id: string
      parentId: string
      displayName: string
    }[]
    value: string[][]
  }
}

const useLastTimeRange = ({ optionItems }: IOptionType): IDataType => {
  const dateRangeOptions = optionItems?.map((o) => ({
    title: `Last ${o.lastDateRangeNumber} ${_.camelCase(o.timeFrame)}`,
    value: `last_${o.lastDateRangeNumber}_${o.timeFrame}`,
  })) || [LAST_14_DAYS, LAST_8_WEEKS, LAST_13_PERIODS]

  const lastTimeRangeHook = useState([[dateRangeOptions?.[0]?.value || '']])

  return {
    lastTimeRange: useMemo(() => {
      const options = dateRangeOptions.map((o) => ({
        id: o.value,
        parentId: 'root',
        displayName: o.title,
      }))

      return {
        value: lastTimeRangeHook[0],
        options: options,
        onChange: lastTimeRangeHook[1],
      }
    }, [lastTimeRangeHook]),
  }
}

export default useLastTimeRange
