import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter, useYoyDates } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'

const query = gql`
  query lfrBusinessReviewKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        grossSales
        grossSalesBudgetVariance
        grossSalesSssPercent
        checkCount
        checkAverage
        hourlyLaborCostPercent
        hourlyLaborBudgetVariance
        cogs
        cogsPercent
        cogsBudgetVariance
        rcpPercent
        rcpBudgetVariance
      }
    }

    listLocationGroupBusinessReview(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        revPsf
        covers
        ppa
        primeCostsPercent
        primeCostsVariance
      }
    }
  }
`

const yoyQuery = gql`
  query yoyLfrBusinessReviewKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupBusinessReview(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        primeCostsPercent
      }
    }
  }
`

export const lfrBusinessReviewKpisConfigs = {
  grossSales: 'price',
  grossSalesBudgetVariance: 'percent',
  grossSalesSssPercent: 'percent',
  checkCount: 'number',
  checkAverage: 'price',
  hourlyLaborCostPercent: 'percent',
  hourlyLaborBudgetVariance: 'percent',
  cogs: 'price',
  cogsPercent: 'percent',
  cogsBudgetVariance: 'percent',
  rcpPercent: 'percent',
  rcpBudgetVariance: 'percent',
  revPsf: 'price',
  covers: 'number',
  ppa: 'price',
  primeCostsPercent: 'percent',
  primeCostsVariance: 'percent',
  primeCostsPercentSss: 'percent',
} as const

const getSss = (a?: unknown, b?: unknown) => {
  if (typeof a === 'number' && typeof b === 'number') return a - b

  if (typeof a === 'string' && typeof b === 'string')
    return parseFloat(a) - parseFloat(b)

  return null
}

const useLfrBusinessReviewKpis = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.ids },
    },
    skip: !startDate || !endDate || !groupFilter,
  })
  const { yoyStartDate, yoyEndDate } = useYoyDates(startDate, endDate)
  const { data: yoyData, loading: yoyLoading } = useQuery(yoyQuery, {
    variables: {
      iStartDate: yoyStartDate,
      iEndDate: yoyEndDate,
      iFilter: { location_group_ids: groupFilter?.ids },
    },
    skip: !yoyStartDate || !yoyEndDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const listLocationGroupFinancialKpis =
        data?.listLocationGroupFinancialKpis.nodes[0] || {}
      const listLocationGroupBusinessReview =
        data?.listLocationGroupBusinessReview.nodes[0] || {}
      const kpis = {
        ...listLocationGroupFinancialKpis,
        ...listLocationGroupBusinessReview,
      }
      const yoyKpis = yoyData?.listLocationGroupBusinessReview.nodes[0] || {}

      if (Object.keys(kpis).length === 0) return null

      return {
        ...kpis,
        primeCostsPercentSss: getSss(
          kpis?.primeCostsPercent,
          yoyKpis?.primeCostsPercent,
        ),
      }
    }, [data, yoyData]),
    loading: loading || yoyLoading,
  }
}

export default useLfrBusinessReviewKpis
