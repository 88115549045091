import { ChartType } from 'chart.js'
import _ from 'lodash'

import base from './base'

export default _.merge({}, base, {
  type: 'pie' as ChartType,
  options: {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: true,
    },
  },
})
