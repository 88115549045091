import { useMemo, useState } from 'react'

interface IInfoType {
  displayName: string
  type: string
  defaultSorting?: {
    id?: string
    desc?: boolean
  }
  fields: {
    key: string
    title: string
    metricCode: string
  }[]
}

export interface IOptionType {
  defaultValue: string[]
  options: {
    id: string
    parentId: string
    displayName: string
    info?: Omit<IInfoType, 'displayName'>
  }[]
}

export interface IDataType {
  rankings?: {
    value: string[][]
    onChange: (value: string[][]) => void
    options: {
      id: string
      parentId: string
      displayName: string
    }[]
    info?: IInfoType
  }
}

const useRankings = ({ defaultValue, options }: IOptionType) => {
  const rankingsHook = useState([defaultValue])

  return {
    rankings: useMemo((): IDataType['rankings'] => {
      const value = rankingsHook[0]
      const option = options.find(
        (o) => o.id === rankingsHook[0][0].slice(-1)[0],
      )

      return {
        value,
        onChange: rankingsHook[1],
        options,
        info: option?.info && {
          ...option.info,
          displayName: option.displayName,
        },
      }
    }, [rankingsHook, options]),
  }
}

export default useRankings
