import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const burgerworksLossPreventionConfig: IConfigsType = {
  burgerworks: {
    '/:brand/corporate_loss_prevention': {
      variables: ['corporateGroup', 'discountType', 'lastTimeRange'],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      listLocationGroupLossPreventionKpis: {
        type: 'list',
        api: 'burgerworksListLocationGroupLossPreventionKpis',
        title: 'Loss Prevention',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'discountCompsTotal',
            },
            bottom: 'Total Discounts',
          },
          {
            content: {
              key: 'discountCheckCountTotal',
            },
            bottom: 'Total Discount Counts	',
          },
          {
            content: {
              key: 'lossPreventionCompsDeletes',
            },
            bottom: 'Deletes',
          },
          {
            content: {
              key: 'lossPreventionCompsVoids',
            },
            bottom: 'Voids',
          },
          {
            content: {
              key: 'lossPreventionCompsOverrings',
            },
            bottom: 'Overrings',
          },
          {
            content: {
              key: 'lossPreventionCompsRefunds',
            },
            bottom: 'Refunds',
          },
        ],
      },
      discountTypeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Discounts',
          },
          columns: {
            type: 'select',
            value: 'discountType.value',
            onChange: 'discountType.onChange',
            options: 'discountType.options',
            label: 'Type',
          },
        },
      },
      discountBreakdownByStore: {
        type: 'table-v2',
        api: 'burgerworksLossPrevention',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'coupons',
            title: 'Coupons',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("coupons")) %>',
            children: [
              {
                key: 'discountCheckCountCoupons',
                title: '#',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsCoupons',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentCoupons',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.discountCompsPercentCouponsHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'employeeMeal',
            title: 'Employee Meal',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("employeeMeal")) %>',
            children: [
              {
                key: 'discountCheckCountEmployeeMeal',
                title: '#',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsEmployeeMeal',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentEmployeeMeal',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.discountCompsPercentEmployeeMealHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'promoMeal',
            title: 'Promo Meal',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("promoMeal")) %>',
            children: [
              {
                key: 'discountCheckCountPromoMeal',
                title: '#',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPromoMeal',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentPromoMeal',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.discountCompsPercentPromoMealHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'policeDiscount',
            title: 'Police Discount',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("policeDiscount")) %>',
            children: [
              {
                key: 'discountCheckCountPoliceDiscount',
                title: '#',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPoliceDiscount',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentPoliceDiscount',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.discountCompsPercentPoliceDiscountHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'trainerPromo',
            title: 'Trainer Promo',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("trainerPromo")) %>',
            children: [
              {
                key: 'discountCheckCountTrainerPromo',
                title: '#',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsTrainerPromo',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentTrainerPromo',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.discountCompsPercentTrainerPromoHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'mobileCourtesyMeal',
            title: 'Mobile Courtesy Meal',
            align: 'center',
            hide: '<%- !discountType.value.some(v => v.includes("all") || v.includes("mobileCourtesyMeal")) %>',
            children: [
              {
                key: 'discountCheckCountMobileCourtesyMeal',
                title: '#',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsMobileCourtesyMeal',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentMobileCourtesyMeal',
                title: '%',
                width: '75px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.discountCompsPercentMobileCourtesyMealHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'total',
            title: 'Total Discounts',
            align: 'center',
            children: [
              {
                key: 'discountCheckCountTotal',
                title: '#',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsTotal',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'discountCompsPercentTotal',
                title: '%',
                width: '100px',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.discountCompsPercentTotalHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'grossSales',
            title: 'Gross Sales',
            width: '150px',
            align: 'center',
            sortable: true,
          },
        ],
      },
      discountTrendTimeRangeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Discounts Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
            label: ' ',
          },
        },
      },
      discountCompsTrendChart: {
        type: 'line-chart',
        api: 'trendLocationGroupDiscount',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'discountCompsTotal',
            label: 'Total Discount',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: '$',
              },
            },
          },
        },
      },
      cashBreakdownByStore: {
        type: 'table-v2',
        title: 'Cash',
        api: 'burgerworksLossPrevention',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'deletes',
            title: 'Deletes',
            align: 'center',
            children: [
              {
                key: 'lossPreventionCheckCountDeletes',
                title: '#',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'lossPreventionCompsDeletes',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'voids',
            title: 'Voids',
            align: 'center',
            children: [
              {
                key: 'lossPreventionCheckCountVoids',
                title: '#',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'lossPreventionCompsVoids',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'overrings',
            title: 'Overrings',
            align: 'center',
            children: [
              {
                key: 'lossPreventionCheckCountOverrings',
                title: '#',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'lossPreventionCompsOverrings',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'refunds',
            title: 'Refunds',
            align: 'center',
            children: [
              {
                key: 'lossPreventionCheckCountRefunds',
                title: '#',
                width: '75px',
                align: 'center',
                sortable: true,
              },
              {
                key: 'lossPreventionCompsRefunds',
                title: '$',
                width: '75px',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      lossPreventionsTrendTimeRangeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Cash Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
            label: ' ',
          },
        },
      },
      lossPreventionsTrendChart: {
        type: 'line-chart',
        api: 'trendLocationGroupLossPrevention',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'lossPreventionCompsDeletes',
            label: 'Deletes $',
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
          {
            key: 'lossPreventionCompsVoids',
            label: 'Voids $',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
          {
            key: 'lossPreventionCompsOverrings',
            label: 'Overrings $',
            borderColor: COLORS.DarkOrchid,
            backgroundColor: COLORS.DarkOrchid,
          },
          {
            key: 'lossPreventionCompsRefunds',
            label: 'Refunds $',
            borderColor: COLORS.Mango,
            backgroundColor: COLORS.Mango,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: '$',
              },
            },
          },
        },
      },
    },
    '/:brand/loss_prevention': {
      lossPreventionTitle: {
        type: 'title',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
      },
      groupFilter: {
        api: 'locationFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      expoAi: {
        type: 'ai-summary-box',
        api: 'locationLossPreventionAiSummary',
      },
      listLocationLossPreventionKpis: {
        type: 'list',
        api: 'burgerworksListLocationLossPreventionKpis',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'discountCompsTotal',
            },
            bottom: 'Total Discounts',
          },
          {
            content: {
              key: 'discountCheckCountTotal',
            },
            bottom: 'Total Discount Counts	',
          },
          {
            content: {
              key: 'lossPreventionCompsDeletes',
            },
            bottom: 'Deletes',
          },
          {
            content: {
              key: 'lossPreventionCompsVoids',
            },
            bottom: 'Voids',
          },
          {
            content: {
              key: 'lossPreventionCompsOverrings',
            },
            bottom: 'Overrings',
          },
          {
            content: {
              key: 'lossPreventionCompsRefunds',
            },
            bottom: 'Refunds',
          },
        ],
      },
      opsCompsBreakdown: {
        type: 'table-v2',
        title: 'Ops Comps Breakdown',
        api: 'burgerworksLocationOpsCompsBreakdownData',
        columns: [
          {
            key: 'discountCompsRank',
            title: 'Rank',
            width: '50px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'discountName',
            title: 'Discount',
            width: '175px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'discountCheckCount',
            title: 'Quantity',
            width: '75px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'discountComps',
            title: '$ Discount',
            width: '100px',
            align: 'center',
            sortable: true,
          },
        ],
      },
      discountBreakdownByEmployee: {
        type: 'table-v2',
        title: 'Discounts by Employee',
        api: 'burgerworksListEmployeeDiscount',
        sorting: [
          {
            id: 'discountCompsPercentTotal',
            desc: true,
          },
        ],
        columns: [
          {
            key: 'discountTotalPercentRank',
            title: 'Rank',
            align: 'center',
            width: '100px',
            sortable: true,
          },
          {
            key: 'employeeName',
            title: 'Name',
            align: 'center',
            width: '250px',
            sortable: true,
          },
          {
            key: 'discountTypes',
            title: 'Discount Types',
            align: 'center',
            width: '200px',
            sortable: true,
          },
          {
            key: 'discountCheckCountTotal',
            title: 'Discount #',
            align: 'center',
            width: '125px',
            sortable: true,
          },
          {
            key: 'discountCompsTotal',
            title: 'Discount $',
            align: 'center',
            width: '125px',
            sortable: true,
          },
          {
            key: 'grossSales',
            title: 'Gross Sales',
            align: 'center',
            width: '125px',
            sortable: true,
          },
          {
            key: 'discountCompsPercentTotal',
            title: 'Discount %',
            align: 'center',
            width: '125px',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
              value: '<%- values?.discountTotalPercentHeatmap %>',
            },
          },
        ],
      },
      cashHandlingByEmployee: {
        type: 'table-v2',
        title: 'Cash Handling by Employee',
        api: 'burgerworksListEmployeeLossPrevention',
        sorting: [
          {
            id: 'lossPreventionOverringCheckCountPercent',
            desc: true,
          },
        ],
        columns: [
          {
            key: 'employeeName',
            title: 'Name',
            align: 'center',
            width: '175px',
            sortable: true,
          },
          {
            key: 'checkCount',
            title: 'Checks Total #',
            align: 'center',
            width: '150px',
            sortable: true,
          },
          {
            key: 'overring',
            title: 'Over Ring',
            align: 'center',
            children: [
              {
                key: 'lossPreventionOverringCheckCount',
                title: '#',
                align: 'center',
                width: '75px',
                sortable: true,
              },
              {
                key: 'lossPreventionOverringAmount',
                title: '$',
                align: 'center',
                width: '75px',
                sortable: true,
              },
              {
                key: 'lossPreventionOverringCheckCountPercent',
                title: '%',
                align: 'center',
                width: '75px',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.lossPreventionOverringCheckCountPercentHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'deletes',
            title: 'Deletes',
            align: 'center',
            children: [
              {
                key: 'lossPreventionDeleteCheckCount',
                title: '#',
                align: 'center',
                width: '75px',
                sortable: true,
              },
              {
                key: 'lossPreventionDeleteAmount',
                title: '$',
                align: 'center',
                width: '75px',
                sortable: true,
              },
              {
                key: 'lossPreventionDeleteCheckCountPercent',
                title: '%',
                align: 'center',
                width: '75px',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.lossPreventionDeleteCheckCountPercentHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'voids',
            title: 'Voids',
            align: 'center',
            children: [
              {
                key: 'lossPreventionVoidCheckCount',
                title: '#',
                align: 'center',
                width: '75px',
                sortable: true,
              },
              {
                key: 'lossPreventionVoidAmount',
                title: '$',
                align: 'center',
                width: '75px',
                sortable: true,
              },
              {
                key: 'lossPreventionVoidCheckCountPercent',
                title: '%',
                align: 'center',
                width: '75px',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.lossPreventionVoidCheckCountPercentHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'refunds',
            title: 'Refunds',
            align: 'center',
            children: [
              {
                key: 'lossPreventionRefundCheckCount',
                title: '#',
                align: 'center',
                width: '75px',
                sortable: true,
              },
              {
                key: 'lossPreventionRefundAmount',
                title: '$',
                align: 'center',
                width: '75px',
                sortable: true,
              },
              {
                key: 'lossPreventionRefundCheckCountPercent',
                title: '%',
                align: 'center',
                width: '75px',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.lossPreventionRefundCheckCountPercentHeatmap %>',
                },
              },
            ],
          },
        ],
      },
    },
  },
}

export default burgerworksLossPreventionConfig
