import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'

type IMetricDataType = Record<
  string,
  {
    name?: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'
    value: number
  }
>

type ListItemMetricValue = {
  itemName: string
  displayParentCategoryName: string
  metricData?: IMetricDataType
}

type IDataType = {
  listItemMetricValues: {
    nodes: ListItemMetricValue[]
  }
}

const itemQuery = gql`
  query listItemMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    listItemMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        itemName
        displayParentCategoryName
        metricData
      }
    }
  }
`

const buildItemMetricValuesHook = (
  options: {
    metrics: string[]
    itemCodes?: string[]
    startDate?: string
    endDate?: string
    locationGroupIds?: number[]
  },
  handler: (data: Record<string, unknown>) => Record<string, unknown> = (
    data,
  ) => data,
) => {
  const useItemMetricValues = () => {
    const dateFilter = useDateFilter()
    const { groupFilter } = useGroupFilter()

    const startDate = options.startDate || dateFilter.startDate
    const endDate = options.endDate || dateFilter.endDate
    const locationGroupIds = options.locationGroupIds || groupFilter?.ids || []

    const iFilter = {
      item_codes: options.itemCodes || ['ALL ITEMS'],
      metrics: options.metrics,
      location_group_ids: locationGroupIds,
    }

    const { data, loading } = useQuery<IDataType>(itemQuery, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iQueryType: 'ITEM',
        iFilter,
      },
      skip:
        !startDate || !endDate || !groupFilter || options.metrics.length === 0,
    })

    const processedData = useMemo<IApiDataType>(() => {
      if (!data) return null

      const listItemMetricValues = data.listItemMetricValues.nodes

      return listItemMetricValues.map((n) => {
        const metricData = Object.entries(n.metricData || {}).reduce(
          (acc, [key, value]) => {
            const camelCaseKey = _.camelCase(key)
            acc[camelCaseKey] = value?.value || 0
            return acc
          },
          {} as Record<string, number>,
        )

        const formattedData = handler({
          itemName: n.itemName,
          displayParentCategoryName: n.displayParentCategoryName,
          ...metricData,
        })

        return {
          ...formattedData,
          id: n.itemName,
          parentId: 'root',
        }
      })
    }, [data, handler])

    return {
      data: processedData,
      loading,
    }
  }

  return useItemMetricValues
}

export default buildItemMetricValuesHook
