import buildMetricTrendsHook from '../utils/buildMetricTrendsHook'

export const burgerworksWeeklySalesConfigs = {
  date: 'string',
  netSalesWeeklyAverage: 'price',
} as const

const useCommonMetricValuesHook = buildMetricTrendsHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['net_sales_weekly_average'],
  },
})

const useBurgerworksWeeklySales = () =>
  useCommonMetricValuesHook('business_week')

export default useBurgerworksWeeklySales
