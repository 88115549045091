import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import buildMetricValuesHook from 'pared/Routes/renderer/utils/unifiedEndpoint/buildMetricValuesHook'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import { getBrandLocationGroupId } from 'pared/utils/brand'

import { IApiDataType } from '../../types'

const useLocationSmgKpis = buildMetricValuesHook({
  groupFilterType: 'store',
  metrics: {
    codes: ['smg_wingyou_score'],
  },
})

const useSystemwideGroupSmgKpis = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['smg_wingyou_score'],
  },
})

const useLocationWingyou = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const brandLocationGroupId = getBrandLocationGroupId()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'

  const { data: locationSmgData, loading: isLocationSmgDataLoading } =
    useLocationSmgKpis({ startDate, endDate })
  const { data: systemwideSmgData, loading: isSystemwideSmgDataLoading } =
    useSystemwideGroupSmgKpis({
      startDate,
      endDate,
      customLocationGroupIds: [brandLocationGroupId],
      shouldBypassRowLevelSecurity: true,
    })

  return {
    data: useMemo(() => {
      if (!locationSmgData || !systemwideSmgData) return null

      let wingyouInsight = ''
      if (
        locationSmgData?.smgWingyouScore &&
        systemwideSmgData?.smgWingyouScore
      ) {
        wingyouInsight = `${locationName}'s Wing You completion rate is ${(
          locationSmgData.smgWingyouScore as number
        ).toFixed(2)}% which is `
        if (
          locationSmgData.smgWingyouScore > systemwideSmgData.smgWingyouScore
        ) {
          wingyouInsight += `higher than system average.`
        } else if (
          locationSmgData.smgWingyouScore < systemwideSmgData.smgWingyouScore
        ) {
          wingyouInsight += `lower than system average.`
        } else {
          wingyouInsight += `the same as system average.`
        }
      }

      if (!wingyouInsight) {
        wingyouInsight = `${locationName} doesn't have enough Wing You data.`
      }

      const hasEnoughData = locationName && locationSmgData && systemwideSmgData

      return {
        title: 'WingYou',
        total: null,
        detail: hasEnoughData ? wingyouInsight : 'Loading ...',
      }
    }, [
      locationName,
      locationSmgData,
      systemwideSmgData,
      isLocationSmgDataLoading,
      isSystemwideSmgDataLoading,
    ]),
    loading: isLocationSmgDataLoading || isSystemwideSmgDataLoading,
  }
}

export default useLocationWingyou
