import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IOptionsType } from '../types'

const sssQueries = gql`
  query sssDefs($metricGroup: String!) {
    locationMetricDefs(condition: { metricGroup: $metricGroup }) {
      nodes {
        name
        code
      }
    }
  }
`

const sstQueries = gql`
  query sstDefs($metricGroup: String!) {
    locationMetricDefs(condition: { metricGroup: $metricGroup }) {
      nodes {
        name
        code
      }
    }
  }
`

const sscQueries = gql`
  query sscDefs($metricGroup: String!) {
    locationMetricDefs(condition: { metricGroup: $metricGroup }) {
      nodes {
        name
        code
      }
    }
  }
`

const useFarwestSssChart = () => {
  const { data: sssList, loading: sssLoading } = useQuery(sssQueries, {
    variables: {
      metricGroup: 'Expo Module - Revenue Center Sales Comparable',
    },
  })
  const { data: sstList, loading: sstLoading } = useQuery(sstQueries, {
    variables: {
      metricGroup: 'Expo Module - Revenue Center Check Count Comparable',
    },
  })
  const { data: sscList, loading: sscLoading } = useQuery(sscQueries, {
    variables: {
      metricGroup: 'Expo Module - Revenue Center Check Average Comparable',
    },
  })

  const loading = sssLoading || sstLoading || sscLoading
  return {
    data: useMemo(() => {
      const options: IOptionsType = [
        {
          type: 'group',
          label: 'SSS%',
        },
        {
          value: 'net_sales',
          label: 'SSS% - Total',
        },
      ]

      sssList?.locationMetricDefs.nodes.forEach(
        (d: { name: string; code: string }) => {
          switch (d.code) {
            case 'revenue_center_sales_online_comparable':
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: 'SSS% - Online (Pick Up)',
              })
              break

            case 'revenue_center_sales_takeoutcallincallcenter_comparable':
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: 'SSS% - Take Out - Call in/Call Center',
              })
              break

            case 'revenue_center_sales_dispatch_comparable':
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: 'SSS% - Online (Dispatch)',
              })
              break

            default:
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: `SSS% - ${d.name
                  .replace('Revenue Center Sales - ', '')
                  .replace('Comparable', '')}`,
              })
              break
          }
        },
      )

      options.push({
        type: 'group',
        label: 'SST%',
      })

      options.push({
        value: 'check_count',
        label: 'SST% - Total',
      })

      sstList?.locationMetricDefs.nodes.forEach(
        (d: { name: string; code: string }) => {
          switch (d.code) {
            case 'revenue_center_check_count_online_comparable':
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: 'SST% - Online (Pick Up)',
              })
              break

            case 'revenue_center_check_count_takeoutcallincallcenter_comparable':
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: 'SST% - Take Out - Call in/Call Center',
              })
              break

            case 'revenue_center_check_count_dispatch_comparable':
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: 'SST% - Online (Dispatch)',
              })
              break

            default:
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: `SST% - ${d.name
                  .replace('Revenue Center Check Count - ', '')
                  .replace('Comparable', '')}`,
              })
              break
          }
        },
      )

      options.push({
        type: 'group',
        label: 'SSC%',
      })

      options.push({
        value: 'check_average',
        label: 'SSC% - Total',
      })

      sscList?.locationMetricDefs.nodes.forEach(
        (d: { name: string; code: string }) => {
          switch (d.code) {
            case 'revenue_center_check_average_online_comparable':
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: 'SSC% - Online (Pick Up)',
              })
              break

            case 'revenue_center_check_average_takeoutcallincallcenter_comparable':
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: 'SSC% - Take Out - Call in/Call Center',
              })
              break

            case 'revenue_center_check_average_dispatch_comparable':
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: 'SSC% - Online (Dispatch)',
              })
              break

            default:
              options.push({
                value: d.code.replace(/_comparable$/g, ''),
                label: `SSC% - ${d.name
                  .replace('Revenue Center Check Average - ', '')
                  .replace('Comparable', '')}`,
              })
              break
          }
        },
      )

      return options
    }, [sssList, sstList, sscList]),
    loading,
  }
}

export default useFarwestSssChart
