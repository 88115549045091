import { useMemo } from 'react'

import { useGroupFilter } from '../../groupFilter'
import {
  api as tableApi,
  configs as tableConfigs,
} from '../../table/hooks/useApi'
import useBurgerworksRankings, {
  burgerworksRankingsConfigs,
} from '../../tableV2/hooks/burgerworks/useRankings'
import { IApiDataType } from '../../tableV2/types'
import useBbbFlashV2, { bbbFlashV2Configs } from './useBbbFlash'
import useBbbOpsDashboard, {
  bbbOpsDashboardConfigs,
} from './useBbbOpsDashboard'
import useBbbPnlRankingsV2, {
  bbbPnlRankingsV2Configs,
} from './useBbbPnLRankings'
import useBurgerworksFlashV2, {
  burgerworksFlashV2Configs,
} from './useBurgerworksFlash'
import useFwWingstopSalesbyOrderMode, {
  fwWingstopFlashSalesByOrderModeConfigs,
} from './useFwWingstopSalesbyOrderMode'
import useFwWingstopTimeClock, {
  fwWingstopTimeClockConfigs,
} from './useFwWingstopTimeClock'
import useItem, { itemV2Configs } from './useItem'
import useLfrBusinessReviewAndGuest, {
  lfrBusinessReviewAndGuestConfigs,
} from './useLfrBusinessReviewAndGuest'
import useMadeFromScratchCsv, {
  madeFromScratchConfigs,
} from './useMadeFromScratch'

export type IApiKeyType = keyof typeof api

export const configs = {
  ...tableConfigs,
  lfrBusinessReviewAndGuest: lfrBusinessReviewAndGuestConfigs,
  fwWingstopTimeClock: fwWingstopTimeClockConfigs,
  fwWingstopFlashSalesByOrderMode: fwWingstopFlashSalesByOrderModeConfigs,
  bbbFlashV2: bbbFlashV2Configs,
  bbbPnlRankingsV2: bbbPnlRankingsV2Configs,
  madeFromScratch: madeFromScratchConfigs,
  burgerworksFlashV2: burgerworksFlashV2Configs,
  item: itemV2Configs,
  bbbOpsDashboard: bbbOpsDashboardConfigs,
  burgerworksRankings: burgerworksRankingsConfigs,
}

// WARNING: it just a simple wrapper, maybe not match all case
const buildTableV2Wrapper = (
  useApi: () => { data: IApiDataType; loading: boolean },
  withoutGroupBy?: boolean,
) => {
  const useTableV2Wrapper = () => {
    const { data, loading } = useApi()
    const { groupFilter } = useGroupFilter()

    return {
      data: useMemo((): {
        source: Record<string, unknown>[]
        summary: Record<string, unknown> | null
      } | null => {
        if (!data) return null

        if (data.some((d) => d.id !== 'summary'))
          return {
            source: data.map((d, index) => {
              const groupInfo = groupFilter?.list?.find(
                (l) => l.id.toString() === d.id,
              )
              const copiedGroupInfo = !groupInfo ? undefined : { ...groupInfo }

              if (withoutGroupBy) delete copiedGroupInfo?.groupBy

              return {
                ...d,
                rowIndex: index + 1,
                groupInfo: copiedGroupInfo,
              }
            }),
            summary: null,
          }

        return {
          source: data
            .filter((d) => d.parentId === 'summary')
            .map((d, index) => {
              const groupInfo = groupFilter?.list?.find(
                (l) => l.id.toString() === d.id,
              )

              return {
                ...d,
                rowIndex: index + 1,
                groupInfo,
              }
            }),
          summary: data.filter((d) => d.parentId === 'root')?.[0] || null,
        }
      }, [data, groupFilter]),
      loading,
    }
  }

  return useTableV2Wrapper
}

const api = {
  ...tableApi,
  lfrBusinessReviewAndGuest: useLfrBusinessReviewAndGuest,
  fwWingstopTimeClock: useFwWingstopTimeClock,
  fwWingstopFlashSalesByOrderMode: useFwWingstopSalesbyOrderMode,
  bbbFlashV2: useBbbFlashV2,
  bbbPnlRankingsV2: useBbbPnlRankingsV2,
  madeFromScratch: useMadeFromScratchCsv,
  burgerworksFlashV2: useBurgerworksFlashV2,
  item: useItem,
  bbbOpsDashboard: useBbbOpsDashboard,
  burgerworksRankings: buildTableV2Wrapper(useBurgerworksRankings, true),
}

const useApi = (apiName: IApiKeyType) => api[apiName]()

export default useApi
