import calc from '../../../utils/calc'
import { corporateGroupTableConfigs } from '../../../variables'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const burgerworksFlashConfigs = {
  ...corporateGroupTableConfigs,
  netSales: 'price',
  checkCount: 'number',
  checkAverage: 'price',
  laborOvertimeHours: 'number',
  laborOvertimeCost: 'price',
  netSalesSssPercent: 'percent',
} as const

const useBurgerworksFlash = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'net_sales',
      { key: 'net_sales', type: 'yoy' },
      'check_count',
      'check_average',
      'labor_overtime_hours',
      'labor_overtime_cost',
    ],
  },
  handler: (data) => ({
    ...data,
    netSalesSssPercent:
      calc(
        calc(calc(data.netSales, '-', data.yoyNetSales), '/', data.yoyNetSales),
        '*',
        100,
      ) || null,
  }),
  hasSummary: true,
})

export default useBurgerworksFlash
