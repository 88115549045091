import { useMemo, useState } from 'react'

export interface IDataType {
  deliveryDayParts?: {
    options: {
      id: string
      parentId: string
      displayName: string
    }[]
    value: string[][]
  }
}

const useDeliveryDayParts = (): IDataType => {
  const deliveryDayPartsHook = useState([['all']])

  return {
    deliveryDayParts: useMemo(() => {
      const options = [
        {
          id: 'all',
          parentId: 'root',
          displayName: 'All',
        },
        {
          id: 'lunch',
          parentId: 'root',
          displayName: 'Lunch Only',
        },
        {
          id: 'dinner',
          parentId: 'root',
          displayName: 'Dinner Only',
        },
      ]

      return {
        value: deliveryDayPartsHook[0],
        options: options,
        onChange: deliveryDayPartsHook[1],
      }
    }, [deliveryDayPartsHook]),
  }
}

export default useDeliveryDayParts
