import { useMemo } from 'react'

import useItem, { itemConfigs } from '../../tableV2/hooks/common/useItem'

export const itemV2Configs = {
  ...itemConfigs,
}

const useItemV2 = ({
  startDate,
  endDate,
  locationGroupIds,
}: {
  startDate?: string
  endDate?: string
  locationGroupIds?: number[]
} = {}) => {
  const { data, loading } = useItem({ startDate, endDate, locationGroupIds })

  return {
    data: useMemo(() => {
      if (!data) return null

      const summary = [...data]

      return {
        source: data,
        summary,
      }
    }, [data]),
    loading,
  }
}

export default useItemV2
