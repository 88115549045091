import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'

export interface IDataType {
  revenueCenterSales?: {
    options: {
      key: string
      label: string
    }[]
  }
}

interface IMetricDefsDataType {
  uListRequestedLocationMetrics: {
    nodes: { metricCode: string; metricName: string }[]
  }
}

const query = gql`
  query listMetricDefs($iFilter: JSON!) {
    uListRequestedLocationMetrics(iFilter: $iFilter) {
      nodes {
        metricCode
        metricName
      }
    }
  }
`

export const revenueCenterSalesConfigs = {
  '<%- JSON(revenueCenterSales?.options) %>': 'price',
} as const

const useRevenueCenterSales = (): IDataType => {
  const { data, loading } = useQuery<IMetricDefsDataType>(query, {
    variables: {
      iFilter: {
        metric_groups: ['Expo Module - Revenue Center Sales'],
      },
    },
  })

  return {
    revenueCenterSales: useMemo(() => {
      const nodes = data?.uListRequestedLocationMetrics?.nodes || []
      const newData = nodes
        ?.map((n) => ({
          key: _.camelCase(n.metricCode),
          label: n.metricName.replace(/^Revenue Center Sales - \s*/, ''),
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

      return {
        options: [
          ...newData?.map((n, index) => ({
            borderColor: COLORS.STACKED_BAR_COLOR_HUE[index],
            backgroundColor: COLORS.STACKED_BAR_COLOR_HUE[index],
            ...n,
          })),
        ],
      }
    }, [data, loading]),
  }
}

export default useRevenueCenterSales
