import { useMemo } from 'react'

export interface IDataType {
  bbbConstants?: typeof CONSTANTS & {
    isCompany: (locationGroupIds: number[]) => boolean
  }
}

export const CONSTANTS = {
  COMPANY_STORE_LOCATION_GROUP_ID: 38,
  COMPANY_STORE_PERIOD_GROUP_ID: 175,
  COMPANY_CORPORATE_DEPARTMENTS_GROUP_ID: 37,
  COMPANY_NOT_SHOW_GROUP_ID: 177,
  COMP_GROUP_ID: 30,
  ALL_LOCATION_GROUP_ID: 176,
} as const

const useBbbConstants = () => ({
  bbbConstants: useMemo(
    (): IDataType['bbbConstants'] => ({
      ...CONSTANTS,
      isCompany: (locationGroupIds: number[]) =>
        [
          CONSTANTS.COMPANY_STORE_LOCATION_GROUP_ID,
          CONSTANTS.COMPANY_CORPORATE_DEPARTMENTS_GROUP_ID,
          CONSTANTS.COMPANY_NOT_SHOW_GROUP_ID,
        ].every((id) => locationGroupIds.includes(id)),
    }),
    [],
  ),
})

export default useBbbConstants
