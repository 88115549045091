import useLfrBusinessReviewKpis, {
  lfrBusinessReviewKpisConfigs,
} from './useLfrBusinessReviewKpis'

export const configs = {
  lfrBusinessReviewKpis: lfrBusinessReviewKpisConfigs,
}

export const api = {
  lfrBusinessReviewKpis: useLfrBusinessReviewKpis,
}
