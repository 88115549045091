import { useMemo } from 'react'

import { getBusinessLabel } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useGroupFilter } from '../../../groupFilter'
import {
  IGroupFilterType,
  buildGroupFilterConfigs,
} from '../utils/buildGroupFilter'

export interface IDataType {
  corporateGroup?:
    | IGroupFilterType<'listLocationGroup'>
    | IGroupFilterType<'listLocation'>
}

export const tableConfigs = buildGroupFilterConfigs('corporateGroup')

const WIDTHS = {
  bibibop: ['250px', '150px', '250px'],
} as Record<string, [string, string, string]>

const useCorporateGroup = () => {
  const { hasGroupBy, groupFilter } = useGroupFilter()
  const groupByLabel = getBusinessLabel('director')
  const { brand } = useBrands()

  return {
    corporateGroup: useMemo((): IDataType['corporateGroup'] => {
      if (!groupFilter) return

      return {
        // @ts-ignore: FIXME after removing the group filter
        type: hasGroupBy ? 'listLocation' : 'listLocationGroup',
        tableColumns: (hasGroupBy
          ? [
              {
                key: 'location',
                title: 'Stores',
                onClick: 'track.locationClicked',
                width: WIDTHS?.[brand]?.[0] || '200px',
              },
              {
                key: 'groupBy',
                title: groupByLabel,
                onClick: 'track.groupByClicked',
                width: WIDTHS?.[brand]?.[1] || '200px',
              },
            ]
          : [
              {
                key: 'locationGroup',
                title: groupFilter?.list?.[0]?.header || 'Group Name',
                width: WIDTHS?.[brand]?.[2] || '200px',
              },
            ]) as IGroupFilterType<'listLocationGroup'>['tableColumns'],
        locationGroupIds: groupFilter.ids,
        intersectedLocationGroupIds: groupFilter.intersectedIds,
        locations:
          !hasGroupBy || !groupFilter.list
            ? undefined
            : groupFilter.list.reduce(
                (result, item) => ({
                  ...result,
                  [item.id]: {
                    id: item.id,
                    displayName: item.name,
                    link: item.link,
                    groupBy: item.groupBy && {
                      id: item.groupBy.id,
                      displayName: item.groupBy.name,
                      link: item.groupBy.link,
                    },
                    tableRow: {
                      location: {
                        id: item.id,
                        link: item.link,
                        label: item.name,
                      },
                      groupBy: item.groupBy && {
                        id: item.groupBy.id,
                        link: item.groupBy.link,
                        label: item.groupBy.name,
                      },
                    },
                  },
                }),
                {},
              ),
        // @ts-ignore: FIXME after removing the group filter
        locationGroups:
          hasGroupBy || !groupFilter.list
            ? undefined
            : groupFilter.list.reduce(
                (result, item) => ({
                  ...result,
                  [item.id]: {
                    id: item.id,
                    displayName: item.name,
                    link: item.link,
                    tableRow: {
                      locationGroup: {
                        id: item.id,
                        link: item.link,
                        label: item.name,
                      },
                    },
                  },
                }),
                {} as IGroupFilterType<'listLocationGroup'>['locationGroups'],
              ),
      }
    }, [hasGroupBy, groupFilter, groupByLabel, brand]),
  }
}

export default useCorporateGroup
