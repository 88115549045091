import { IConfigsType } from '../../types'

const burgerworksLocationSalesmanshipConfig: IConfigsType = {
  burgerworks: {
    '/:brand/salesmanship': {
      variables: ['lastTimeRange'],
      salesmanshipTitle: {
        type: 'title',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
      },
      groupFilter: {
        api: 'locationFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      // expoAi: {
      //   type: 'ai-summary-box',
      //   api: 'locationLossPreventionAiSummary',
      // },
      salesPerLaborHour: {
        type: 'location-sales-per-labor-hour',
        title: 'Sales Per Labor Hour',
        api: 'locationSalesPerLaborHour',
      },
      managerOnDutyTable: {
        type: 'table-v2',
        title: 'Manager on Duty',
        api: 'burgerworksListManagerOnDutyData',
        sorting: [
          {
            id: 'grossSales',
            desc: true,
          },
        ],
        columns: [
          {
            key: 'employeeName',
            title: 'Name',
            align: 'center',
            width: '250px',
            sortable: true,
          },
          {
            key: 'position',
            title: 'Position',
            width: '200px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'laborShiftCount',
            title: 'Shifts',
            width: '100px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'grossSales',
            title: 'Total Sales',
            width: '150px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.grossSalesHeatmap %>',
            },
          },
          {
            key: 'laborTotalCostPercent',
            title: 'Labor %',
            width: '150px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
              value: '<%- values.laborTotalCostPercentHeatmap %>',
            },
          },
        ],
      },
      leaderboard: {
        type: 'table-v2',
        title: 'Leaderboard',
        api: 'burgerworksListEmployeeSalesmanshipData',
        sorting: [
          {
            id: 'grossSales',
            desc: true,
          },
        ],
        columns: [
          {
            key: 'employeeName',
            title: 'Name',
            align: 'center',
            width: '250px',
            sortable: true,
          },
          {
            key: 'laborShiftCount',
            title: 'Shifts',
            width: '100px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'grossSales',
            title: 'Total Sales',
            width: '150px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.grossSalesHeatmap %>',
            },
          },
          {
            key: 'checkCount',
            title: 'Check Count',
            width: '150px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.checkCountHeatmap %>',
            },
          },
          {
            key: 'checkAverage',
            title: 'Check Average',
            width: '150px',
            align: 'center',
            decimal: 2,
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.checkAverageHeatmap %>',
            },
          },
          {
            key: 'upsizedCountPercentage',
            title: '% Upsized',
            width: '150px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.upsizedCountPercentageHeatmap %>',
            },
          },
          {
            key: 'salesPerLaborHour',
            title: 'Sales per Labor Hour',
            width: '200px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.salesPerLaborHourHeatmap %>',
            },
          },
        ],
      },
      attachmentRateTable: {
        type: 'table-v2',
        title: 'Attachment Rate',
        api: 'burgerworksListEmployeeSalesmanshipData',
        sorting: [
          {
            id: 'grossSales',
            desc: true,
          },
        ],
        columns: [
          {
            key: 'employeeName',
            title: 'Name',
            align: 'center',
            width: '250px',
            sortable: true,
          },
          {
            key: 'laborShiftCount',
            title: 'Shifts',
            width: '100px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'grossSales',
            title: 'Total Sales',
            width: '150px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.grossSalesHeatmap %>',
            },
          },
          {
            key: 'itemAttachmentRateDesserts',
            title: '% Desserts',
            width: '125px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.itemAttachmentRateDessertsHeatmap %>',
            },
          },
          {
            key: 'itemAttachmentRateBeverages',
            title: '% Beverages',
            width: '150px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.itemAttachmentRateBeveragesHeatmap %>',
            },
          },
          {
            key: 'itemAttachmentRateBacon',
            title: '% Bacon',
            width: '125px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.itemAttachmentRateBaconHeatmap %>',
            },
          },
          {
            key: 'itemAttachmentRateCheese',
            title: '% Cheese',
            width: '125px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.itemAttachmentRateCheeseHeatmap %>',
            },
          },
          {
            key: 'itemAttachmentRateJalapeno',
            title: '% Jalapeno',
            width: '125px',
            align: 'center',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(0, 80%, 80%)', 'hsl(120, 80%, 80%)'],
              value: '<%- values.itemAttachmentRateJalapenoHeatmap %>',
            },
          },
        ],
      },

      checkAverageTrendTimeRangeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Check Average Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
            label: ' ',
          },
        },
      },
      checkAverageTrendChart: {
        type: 'line-chart',
        api: 'burgerworksTrendLocationSalesmanshipKpis',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'checkAverage',
            label: 'Store Check Average',
            decimal: 2,
          },
          {
            key: 'systemwideCheckAverage',
            label: 'Systemwide Check Average',
            decimal: 2,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: '$',
              },
            },
          },
        },
      },
      attachmentRateTrendTimeRangeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Attachment Rate Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
            label: ' ',
          },
        },
      },
      attachmentRateTrendChart: {
        type: 'line-chart',
        api: 'burgerworksTrendLocationSalesmanshipKpis',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'itemAttachmentRateDesserts',
            label: 'Desserts Attachment Rate',
          },
          {
            key: 'itemAttachmentRateBeverages',
            label: 'Beverages Attachment Rate',
          },
          {
            key: 'itemAttachmentRateBacon',
            label: 'Bacon Attachment Rate',
          },
          {
            key: 'itemAttachmentRateCheese',
            label: 'Cheese Attachment Rate',
          },
          {
            key: 'itemAttachmentRateJalapeno',
            label: 'Jalapeno Attachment Rate',
          },
        ],
        scaleTypes: {
          y: {
            type: 'percent',
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: '%',
              },
            },
          },
        },
      },
      upsizingTrendTimeRangeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Upsizing Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
            label: ' ',
          },
        },
      },
      upsizingTrendChart: {
        type: 'line-chart',
        api: 'burgerworksTrendLocationSalesmanshipKpis',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'upsizedCountPercentage',
            label: 'Store Upsized',
          },
          {
            key: 'systemwideUpsizedCountPercentage',
            label: 'Systemwide Upsized',
          },
        ],
        scaleTypes: {
          y: {
            type: 'percent',
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: '% Upsized',
              },
            },
          },
        },
      },
    },
  },
}

export default burgerworksLocationSalesmanshipConfig
