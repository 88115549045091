import buildLocationEmployeeMetricValuesHook from '../../utils/buildLocationEmployeeMetricValuesHook'

export const burgerworksListEmployeeDeliveryConfigs = {
  employeeName: 'string',
  checkCount: 'number',
  deliveryTotalOrderCountDoordash: 'number',
  deliveryOrderWithAnyIssuePercentDoordash: 'percent',
  deliveryCancelledOrderPercentDoordash: 'percent',
  deliveryInaccurateOrderPercentDoordash: 'percent',
  deliveryDelayedOrderPercentDoordash: 'percent',
  deliveryAvgCustomerReviewScoreDoordash: 'number',
} as const

const useListEmployeeDeliveryData = buildLocationEmployeeMetricValuesHook({
  groups: ['Expo Module - Delivery Data'],
})

export default useListEmployeeDeliveryData
