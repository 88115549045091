import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const burgerworksFlash: IConfigsType = {
  burgerworks: {
    '/:brand/flash': {
      variables: ['corporateGroup'],
      flashTitle: {
        type: 'title',
        title: 'Systemwide Flash Report',
      },
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
        defaultType: 'yesterday',
      },
      downloadFlashTable: {
        type: 'download-csv',
        title: 'Daily Flash',
        api: 'burgerworksFlashV2',
        fileName: 'getexpo-flash',
        message: 'Download Daily Flash as CSV',
        fields: [
          'groupInfo',
          {
            key: 'netSales',
            title: 'Net Sales',
          },
          {
            key: 'netSalesSssPercent',
            title: '% vs LY',
          },
          {
            key: 'checkCount',
            title: 'Customer #',
          },
          {
            key: 'checkAverage',
            title: 'Average Check',
          },
          {
            key: 'laborOvertimeHours',
            title: 'Overtime Hours',
          },
          {
            key: 'laborOvertimeCost',
            title: 'Overtime Cost',
          },
        ],
      },
      dailyFlashTable: {
        type: 'table-v2',
        api: 'burgerworksFlash',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'netSales',
            title: 'Net Sales',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'netSalesSssPercent',
            title: '% vs LY',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'checkCount',
            title: 'Customer #',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'checkAverage',
            title: 'Average Check',
            width: '150px',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'laborOvertimeHours',
            title: 'Overtime Hours',
            width: '150px',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'laborOvertimeCost',
            title: 'Overtime Cost',
            width: '150px',
            align: 'center',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default burgerworksFlash
