import { IConfigsType } from '../../types'

const lfrEfConfig: IConfigsType = {
  lfr_ef: {
    '/:brand/systemwide_overview': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'trailing_364_days',
          'last_week',
          'this_week',
          'custom',
        ],
      },
      systemwideOverview: {
        type: 'list',
        api: 'lfrBusinessReviewKpis',
        title: 'Systemwide Overview',
        fieldWidth: '120px',
        fields: [
          {
            content: {
              key: 'grossSales',
            },
            bottom: 'Total Sales',
          },
          {
            content: {
              key: 'grossSalesSssPercent',
              decimal: 1,
            },
            bottom: 'YOY%',
          },
          {
            content: {
              key: 'grossSalesBudgetVariance',
              decimal: 1,
            },
            bottom: 'Gross Sales Target Variance',
          },
          {
            content: {
              key: 'checkCount',
            },
            bottom: 'Check Count',
          },
          {
            content: {
              key: 'checkAverage',
              decimal: 2,
            },
            bottom: 'Check Average',
          },
          {
            content: {
              key: 'hourlyLaborCostPercent',
              decimal: 1,
            },
            bottom: 'Hourly Labor Percent',
          },
          {
            content: {
              key: 'hourlyLaborBudgetVariance',
              decimal: 1,
              color: '<%- value > 0 ? colors.Pomodoro : "" %>',
            },
            bottom: 'Hourly Labor Target Variance',
          },
          {
            content: {
              key: 'cogsPercent',
              decimal: 1,
            },
            bottom: 'COGS',
          },
          {
            content: {
              key: 'cogsBudgetVariance',
              decimal: 1,
              color: '<%- value > 0 ? colors.Pomodoro : "" %>',
            },
            bottom: 'COGS Target Variance',
          },
        ],
      },
      breakdownByStore: {
        type: 'table',
        api: 'lfrBusinessReview',
        title: 'Breakdown by Store',
        pageSize: 20,
        defaultSortedField: 'grossSales',
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '200px',
          },
        },
        columns: [
          {
            key: 'Gross Sales',
            title: 'Gross Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'grossSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'revPsf',
                title: 'Rev PSF',
                align: 'center',
                sortable: true,
              },
              {
                key: 'grossSalesBudgetVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'grossSalesSssPercent',
                title: 'YOY%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
          {
            key: 'Comps',
            title: 'Comps',
            align: 'center',
            children: [
              {
                key: 'compsPercent',
                title: 'Comps',
                align: 'center',
                sortable: true,
              },
              {
                key: 'compsVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Covers',
            title: 'Covers',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'covers',
                title: 'Covers',
                align: 'center',
                sortable: true,
              },
              {
                key: 'coversVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
              },
              {
                key: 'coversSss',
                title: 'YOY',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Spends',
            title: 'Spends',
            align: 'center',
            children: [
              {
                key: 'ppa',
                title: 'PPA$',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'ppaVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'ppaSss',
                title: 'YOY',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
            ],
          },
          {
            key: 'COGS',
            title: 'COGS',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'cogsPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'cogsBudgetVariance',
                title: 'Variance',
                align: 'center',
                reverse: true,
                sortable: true,
                decimal: 1,
              },
            ],
          },
          {
            key: 'Hourly Labor',
            title: 'Hourly Labor',
            align: 'center',
            children: [
              {
                key: 'hourlyLaborCostPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'hourlyLaborBudgetVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
                decimal: 1,
                reverse: true,
              },
            ],
          },
          {
            key: 'Prime Costs',
            title: 'Prime Costs',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'primeCostsPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'primeCostsVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
                decimal: 1,
                reverse: true,
              },
              {
                key: 'primeCostsPercentSss',
                title: 'YOY',
                align: 'center',
                sortable: true,
                decimal: 1,
                reverse: true,
              },
            ],
          },
          {
            key: 'EBITDA',
            title: 'EBITDA',
            align: 'center',
            children: [
              {
                key: 'rcp',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'rcpPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
              {
                key: 'rcpBudgetVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      map: {
        type: 'map',
        api: 'listGeoInfo',
      },
    },
  },
}

export default lfrEfConfig
