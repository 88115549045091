import calc from '../../../utils/calc'
import buildRankingsHook, { rankingsConfigs } from '../utils/buildRankingsHook'

export const burgerworksRankingsConfigs = { ...rankingsConfigs }

const useBurgerworksRankings = buildRankingsHook({
  metriceCodeMapping: {
    netSalesSssVariance: ['net_sales', { key: 'net_sales', type: 'yoy' }],
    netSalesSssPercent: ['net_sales', { key: 'net_sales', type: 'yoy' }],
  },
  handler: (data) => ({
    ...data,
    netSalesSssVariance: calc(data.netSales, '-', data.yoyNetSales),
    netSalesSssPercent: calc(
      data.netSales,
      'variancePercentageOf',
      data.yoyNetSales,
    ),
  }),
})

export default useBurgerworksRankings
