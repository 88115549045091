import _ from 'lodash'
import styled from 'styled-components'

import StarIcon from 'pared/images/Pared/star.svg'

export interface IPropsType {
  numberOfStars: number
}

const Stars = ({ numberOfStars, ...props }: IPropsType) => {
  let stars = []

  for (let i = 0; i < numberOfStars; i++) {
    stars.push(
      <StarIconImgContainer key={i} {...props}>
        <img src={StarIcon} alt="Info" />
      </StarIconImgContainer>,
    )
  }

  return <>{stars}</>
}

const StarIconImgContainer = styled.div`
  margin-right: 5px;
  float: left;
`

export default Stars
