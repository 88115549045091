import { useMemo, useState } from 'react'

export interface IDataType {
  bbbPnl?: {
    metricCode: {
      value: string[][]
      onChange: (value: string[][]) => void
      options: typeof P_AND_L_FIELDS
    }
    rankBy: {
      value: string[][]
      onChange: (value: string[][]) => void
      options: {
        id: string
        parentId: string
        displayName: string
      }[]
    }
    pnlTypes: {
      value: string[][]
      onChange: (value: string[][]) => void
      options: typeof P_AND_L_TYPES_OPTIONS
    }
    pnlColumns: {
      value: string[][]
      onChange: (value: string[][]) => void
      options: typeof P_AND_L_COLUMNS_OPTIONS
    }
    getBudgetCode: (id: string) => string
    getRequiredCodes: (id: string) => Record<string, string[]>
    getExternalFields: (id: string) => { type: 'add' | 'minus'; id: string }[]
  }
}

export interface IOptionType {
  withRankOptions?: true
}

const P_AND_L_FIELDS = [
  {
    id: 'total_sales',
    parentId: 'root',
    displayName: 'Total Sales',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'total_food_sales',
    parentId: 'total_sales',
    displayName: 'Total Food Sales',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'food_sales',
    parentId: 'total_food_sales',
    displayName: 'Food Sales',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_doordash',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Doordash',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_ez_cater',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Ez Cater',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_grubhub',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Grubhub',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_foodsby',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Foodsby',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_allset',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Allset',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_fooda',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Fooda',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_sharebite',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Sharebite',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_flyer',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Flyer',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_buckeye_id',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Buckeye ID',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_yay_lunch',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Yay Lunch',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_bearcat',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Bearcat',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_flyer_express',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Flyer Express',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_aramark',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Aramark',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_denison_aramark',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Denison Aramark',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_catering',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Catering',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_dine_in',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Dine In',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_dispatch_delivery',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Dispatch Delivery',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_forkable',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Forkable',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_stadium',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Stadium',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_online_sales',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Online Sales',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_local_schools',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Local Schools',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_online_togo',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Online To-Go',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_togo',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - To-Go',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_avi_catering',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - AVI Catering',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_try_hungry',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Try Hungry',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_sales_uber_eats',
    parentId: 'total_food_sales',
    displayName: 'Food Sales - Uber Eats',
    types: ['long', 'consolidated'],
  },
  {
    id: 'royalty_income',
    parentId: 'total_food_sales',
    displayName: 'Royalty Income',
    types: ['long'],
  },
  {
    id: 'marketing_fund_income',
    parentId: 'total_food_sales',
    displayName: 'Marketing Fund Income',
    types: ['long'],
  },
  {
    id: 'coke_rebate_income',
    parentId: 'total_food_sales',
    displayName: 'Coke Rebate Income',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_coupons_and_discounts',
    parentId: 'total_sales',
    displayName: 'Total Coupons & Discounts',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'coupons_and_discounts',
    parentId: 'total_coupons_and_discounts',
    displayName: 'Coupons & Discounts',
    types: ['long', 'consolidated'],
  },
  {
    id: 'employee_discount',
    parentId: 'total_coupons_and_discounts',
    displayName: 'Employee Discount',
    types: ['long', 'consolidated'],
  },
  {
    id: 'coupons',
    parentId: 'total_coupons_and_discounts',
    displayName: 'Coupons',
    types: ['long', 'consolidated'],
  },
  {
    id: 'ppa_revenue',
    parentId: 'total_sales',
    displayName: 'PPA Revenue',
    types: ['long', 'consolidated'],
  },
  {
    id: 'srec_revenue',
    parentId: 'total_sales',
    displayName: 'SREC Revenue',
    types: ['long', 'consolidated'],
  },
  {
    id: 'contribution_revenue',
    parentId: 'total_sales',
    displayName: 'Contribution Revenue',
    types: ['long', 'consolidated'],
  },
  {
    id: 'rental_income',
    parentId: 'total_sales',
    displayName: 'Rental Income',
    types: ['long', 'consolidated'],
  },
  {
    id: 'state_grant_income',
    parentId: 'total_sales',
    displayName: 'State Grant Income',
    types: ['long', 'consolidated'],
  },
  {
    id: 'ckids_coin_revenue',
    parentId: 'total_sales',
    displayName: 'CKids Coin Revenue',
    types: ['long', 'consolidated'],
  },
  {
    id: 'ckids_combo_revenue',
    parentId: 'total_sales',
    displayName: 'CKids Combo Revenue',
    types: ['long', 'consolidated'],
  },
  {
    id: 'ckids_bibibop_contribution',
    parentId: 'total_sales',
    displayName: 'CKids Bibibop Contribution',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_cogs',
    parentId: 'root',
    displayName: 'Total COGS',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'total_food_and_paper',
    parentId: 'total_cogs',
    displayName: 'Total Food & Paper',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'food_and_paper',
    parentId: 'total_food_and_paper',
    displayName: 'Food & Paper',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_meat',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Meat',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_dairy',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Dairy',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_produce',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Produce',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_grocery',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Grocery',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_beverages',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Beverages',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_chocolate',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Chocolate',
    types: ['long', 'consolidated'],
  },
  {
    id: 'paper',
    parentId: 'total_food_and_paper',
    displayName: 'Paper',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_waste',
    parentId: 'total_food_and_paper',
    displayName: 'Food - Waste',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_labor',
    parentId: 'total_cogs',
    displayName: 'Total Labor',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'labor',
    parentId: 'total_labor',
    displayName: 'Labor',
    types: ['long', 'consolidated'],
  },
  {
    id: 'manager_salary_exempt',
    parentId: 'total_labor',
    displayName: 'Manager Salary-Exempt',
    types: ['long', 'consolidated'],
  },
  {
    id: 'dm_salary_store',
    parentId: 'total_labor',
    displayName: 'DM Salary-Store',
    types: ['long', 'consolidated'],
  },
  {
    id: 'crew_labor',
    parentId: 'total_labor',
    displayName: 'Crew Labor',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_payroll_taxes',
    parentId: 'total_cogs',
    displayName: 'Total Payroll Taxes',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'payroll_taxes',
    parentId: 'total_payroll_taxes',
    displayName: 'Payroll Taxes',
    types: ['long', 'consolidated'],
  },
  {
    id: 'fica',
    parentId: 'total_payroll_taxes',
    displayName: 'F.I.C.A.',
    types: ['long', 'consolidated'],
  },
  {
    id: 'futa',
    parentId: 'total_payroll_taxes',
    displayName: 'F.U.T.A.',
    types: ['long', 'consolidated'],
  },
  {
    id: 'suta',
    parentId: 'total_payroll_taxes',
    displayName: 'S.U.T.A.',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_payroll_benefits',
    parentId: 'total_cogs',
    displayName: 'Total Payroll Benefits',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'payroll_benefits',
    parentId: 'total_payroll_benefits',
    displayName: 'Payroll Benefits',
    types: ['long', 'consolidated'],
  },
  {
    id: 'bonus',
    parentId: 'total_payroll_benefits',
    displayName: 'Bonus',
    types: ['long', 'consolidated'],
  },
  {
    id: 'four_o_one_k_match_contri',
    parentId: 'total_payroll_benefits',
    displayName: '401K Match Contri',
    types: ['long', 'consolidated'],
  },
  {
    id: 'workers_comp',
    parentId: 'total_payroll_benefits',
    displayName: 'Workers Comp',
    types: ['long', 'consolidated'],
  },
  {
    id: 'employee_ins',
    parentId: 'total_payroll_benefits',
    displayName: 'Employee Ins.',
    types: ['long', 'consolidated'],
  },
  {
    id: 'employee_ins_pmt',
    parentId: 'total_payroll_benefits',
    displayName: 'Employee Ins Pmt',
    types: ['long', 'consolidated'],
  },
  {
    id: 'employee_incentive',
    parentId: 'total_payroll_benefits',
    displayName: 'Employee Incentive',
    types: ['long', 'consolidated'],
  },
  {
    id: 'referral_bonus',
    parentId: 'total_payroll_benefits',
    displayName: 'Referral Bonus',
    types: ['long', 'consolidated'],
  },
  {
    id: 'gross_profit',
    parentId: 'root',
    displayName: 'Gross Profit',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    parentId: 'root',
    displayName:
      'Total Controllable Expenses With Bank Charges and Third Party Fees',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'total_direct_operating',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Direct Operating',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'direct_operating',
    parentId: 'total_direct_operating',
    displayName: 'Direct Operating',
    types: ['long', 'consolidated'],
  },
  {
    id: 'store_smallwares',
    parentId: 'total_direct_operating',
    displayName: 'Store Smallwares',
    types: ['long', 'consolidated'],
  },
  {
    id: 'cleaning_supplies',
    parentId: 'total_direct_operating',
    displayName: 'Cleaning Supplies',
    types: ['long', 'consolidated'],
  },
  {
    id: 'uniform_supplies',
    parentId: 'total_direct_operating',
    displayName: 'Uniform Supplies',
    types: ['long', 'consolidated'],
  },
  {
    id: 'linen_services',
    parentId: 'total_direct_operating',
    displayName: 'Linen Services',
    types: ['long', 'consolidated'],
  },
  {
    id: 'pest_control',
    parentId: 'total_direct_operating',
    displayName: 'Pest Control',
    types: ['long', 'consolidated'],
  },
  {
    id: 'grease_trap',
    parentId: 'total_direct_operating',
    displayName: 'Grease Trap',
    types: ['long', 'consolidated'],
  },
  {
    id: 'knife_services',
    parentId: 'total_direct_operating',
    displayName: 'Knife Services',
    types: ['long', 'consolidated'],
  },
  {
    id: 'cleaning_services',
    parentId: 'total_direct_operating',
    displayName: 'Cleaning Services',
    types: ['long', 'consolidated'],
  },
  {
    id: 'security_services',
    parentId: 'total_direct_operating',
    displayName: 'Security Services',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_administrative_and_general',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Administrative & General',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'administrative_and_general',
    parentId: 'total_administrative_and_general',
    displayName: 'Administrative & General',
    types: ['long', 'consolidated'],
  },
  {
    id: 'charitable_contributions',
    parentId: 'total_administrative_and_general',
    displayName: 'Charitable Contributions',
    types: ['long', 'consolidated'],
  },
  {
    id: 'postage_and_mailing',
    parentId: 'total_administrative_and_general',
    displayName: 'Postage & Mailing',
    types: ['long', 'consolidated'],
  },
  {
    id: 'printing_miscellaneous',
    parentId: 'total_administrative_and_general',
    displayName: 'Printing Miscellaneous',
    types: ['long', 'consolidated'],
  },
  {
    id: 'printing_expenses_menu',
    parentId: 'total_administrative_and_general',
    displayName: 'Printing Expenses-Menu',
    types: ['long', 'consolidated'],
  },
  {
    id: 'music_services',
    parentId: 'total_administrative_and_general',
    displayName: 'Music Services',
    types: ['long', 'consolidated'],
  },
  {
    id: 'digital_advertising_expenses',
    parentId: 'total_administrative_and_general',
    displayName: 'Digital Advertising Expenses',
    types: ['long', 'consolidated'],
  },
  {
    id: 'nondigital_advertising_expenses',
    parentId: 'total_administrative_and_general',
    displayName: 'Non-Digital Advertising Expens',
    types: ['long', 'consolidated'],
  },
  {
    id: 'landscape_maint',
    parentId: 'total_administrative_and_general',
    displayName: 'Landscape Maint.',
    types: ['long', 'consolidated'],
  },
  {
    id: 'swag',
    parentId: 'total_administrative_and_general',
    displayName: 'SWAG',
    types: ['long', 'consolidated'],
  },
  {
    id: 'other_services',
    parentId: 'total_administrative_and_general',
    displayName: 'Other Services',
    types: ['long', 'consolidated'],
  },
  {
    id: 'wall_improvements',
    parentId: 'total_administrative_and_general',
    displayName: 'Wall Improvements',
    types: ['long', 'consolidated'],
  },
  {
    id: 'bibishop',
    parentId: 'total_administrative_and_general',
    displayName: 'BibiShop',
    types: ['long', 'consolidated'],
  },
  {
    id: 'store_supplies',
    parentId: 'total_administrative_and_general',
    displayName: 'Store Supplies',
    types: ['long', 'consolidated'],
  },
  {
    id: 'office_supplies',
    parentId: 'total_administrative_and_general',
    displayName: 'Office Supplies',
    types: ['long', 'consolidated'],
  },
  {
    id: 'printing_and_advertising',
    parentId: 'total_administrative_and_general',
    displayName: 'Printing & Advertising',
    types: ['long', 'consolidated'],
  },
  {
    id: 'postage_and_delivery',
    parentId: 'total_administrative_and_general',
    displayName: 'Postage & Delivery',
    types: ['long', 'consolidated'],
  },
  {
    id: 'delivery_servicesegift',
    parentId: 'total_administrative_and_general',
    displayName: 'Delivery Services-eGift',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_bank_charges_and_fees',
    parentId: 'total_administrative_and_general',
    displayName: 'Total Bank Charges & Fees',
    types: ['long', 'consolidated'],
  },
  {
    id: 'bank_charges_and_fees',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Bank Charges & Fees',
    types: ['long', 'consolidated'],
  },
  {
    id: 'bank_charges',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Bank Charges',
    types: ['long', 'consolidated'],
  },
  {
    id: 'refunds_and_chargebacks',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Refunds & Chargebacks',
    types: ['long', 'consolidated'],
  },
  {
    id: 'credit_card_fees',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Credit Card Fees',
    types: ['long', 'consolidated'],
  },
  {
    id: 'gift_card_fee',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Gift Card Fee',
    types: ['long', 'consolidated'],
  },
  {
    id: 'restaurant_service_fee',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Restaurant Service Fee',
    types: ['long', 'consolidated'],
  },
  {
    id: 'third_party_fee',
    parentId: 'total_bank_charges_and_fees',
    displayName: 'Third Party Fee',
    types: ['long', 'consolidated'],
  },
  {
    id: 'cash_over_or_short',
    parentId: 'total_administrative_and_general',
    displayName: 'Cash Over/Short',
    types: ['long', 'consolidated'],
  },
  {
    id: 'cr_card_over_or_short',
    parentId: 'total_administrative_and_general',
    displayName: 'Cr Card Over/Short',
    types: ['long', 'consolidated'],
  },
  {
    id: 'gc_over_or_short',
    parentId: 'total_administrative_and_general',
    displayName: 'GC Over/Short',
    types: ['long', 'consolidated'],
  },
  {
    id: 'licenses_permits',
    parentId: 'total_administrative_and_general',
    displayName: 'Licenses & Permits',
    types: ['long', 'consolidated'],
  },
  {
    id: 'payroll_processing_fee',
    parentId: 'total_administrative_and_general',
    displayName: 'Payroll Processing Fee',
    types: ['long', 'consolidated'],
  },
  {
    id: 'business_liability_ins',
    parentId: 'total_administrative_and_general',
    displayName: 'Business Liability Ins',
    types: ['long', 'consolidated'],
  },
  {
    id: 'training_and_seminars',
    parentId: 'total_administrative_and_general',
    displayName: 'Training & Seminars',
    types: ['long', 'consolidated'],
  },
  {
    id: 'subscriptions_and_dues',
    parentId: 'total_administrative_and_general',
    displayName: 'Subscriptions & Dues',
    types: ['long', 'consolidated'],
  },
  {
    id: 'meals',
    parentId: 'total_administrative_and_general',
    displayName: 'Meals',
    types: ['long', 'consolidated'],
  },
  {
    id: 'land_travel',
    parentId: 'total_administrative_and_general',
    displayName: 'Land Travel',
    types: ['long', 'consolidated'],
  },
  {
    id: 'air_travel',
    parentId: 'total_administrative_and_general',
    displayName: 'Air Travel',
    types: ['long', 'consolidated'],
  },
  {
    id: 'lodging',
    parentId: 'total_administrative_and_general',
    displayName: 'Lodging',
    types: ['long', 'consolidated'],
  },
  {
    id: 'acct_and_tax_services',
    parentId: 'total_administrative_and_general',
    displayName: 'Acct. & Tax Services',
    types: ['long', 'consolidated'],
  },
  {
    id: 'legal_services',
    parentId: 'total_administrative_and_general',
    displayName: 'Legal Services',
    types: ['long', 'consolidated'],
  },
  {
    id: 'consulting_services',
    parentId: 'total_administrative_and_general',
    displayName: 'Consulting Services',
    types: ['long', 'consolidated'],
  },
  {
    id: 'management_fees',
    parentId: 'total_administrative_and_general',
    displayName: 'Management Fees',
    types: ['long', 'consolidated'],
  },
  {
    id: 'management_fee_troupsburg_ny',
    parentId: 'total_administrative_and_general',
    displayName: 'Management Fee-Troupsburg NY',
    types: ['long', 'consolidated'],
  },
  {
    id: 'management_fee_rochester_fourty_one',
    parentId: 'total_administrative_and_general',
    displayName: 'Management Fee-Rochester-41',
    types: ['long', 'consolidated'],
  },
  {
    id: 'srec_fees',
    parentId: 'total_administrative_and_general',
    displayName: 'SREC Fees',
    types: ['long', 'consolidated'],
  },
  {
    id: 'miscellaneous',
    parentId: 'total_administrative_and_general',
    displayName: 'Miscellaneous',
    types: ['long', 'consolidated'],
  },
  {
    id: 'leasing',
    parentId: 'total_administrative_and_general',
    displayName: 'Leasing',
    types: ['long', 'consolidated'],
  },
  {
    id: 'recruiting_fees',
    parentId: 'total_administrative_and_general',
    displayName: 'Recruiting Fees',
    types: ['long', 'consolidated'],
  },
  {
    id: 'donations',
    parentId: 'total_administrative_and_general',
    displayName: 'Donations',
    types: ['long', 'consolidated'],
  },
  {
    id: 'contribution_materials',
    parentId: 'total_administrative_and_general',
    displayName: 'Contribution Materials',
    types: ['long', 'consolidated'],
  },
  {
    id: 'relocation_expense',
    parentId: 'total_administrative_and_general',
    displayName: 'Relocation Expense',
    types: ['long', 'consolidated'],
  },
  {
    id: 'research_and_development',
    parentId: 'total_administrative_and_general',
    displayName: 'Research & Development',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_utilities',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Utilities',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'utilities',
    parentId: 'total_utilities',
    displayName: 'Utilities',
    types: ['long', 'consolidated'],
  },
  {
    id: 'internet_service',
    parentId: 'total_utilities',
    displayName: 'Internet Service',
    types: ['long', 'consolidated'],
  },
  {
    id: 'telephone',
    parentId: 'total_utilities',
    displayName: 'Telephone',
    types: ['long', 'consolidated'],
  },
  {
    id: 'electric',
    parentId: 'total_utilities',
    displayName: 'Electric',
    types: ['long', 'consolidated'],
  },
  {
    id: 'gas',
    parentId: 'total_utilities',
    displayName: 'Gas',
    types: ['long', 'consolidated'],
  },
  {
    id: 'water_and_sewer',
    parentId: 'total_utilities',
    displayName: 'Water & Sewer',
    types: ['long', 'consolidated'],
  },
  {
    id: 'trash_removal',
    parentId: 'total_utilities',
    displayName: 'Trash Removal',
    types: ['long', 'consolidated'],
  },
  {
    id: 'spinkler',
    parentId: 'total_utilities',
    displayName: 'Spinkler',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_repairs_and_maintenance',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Repairs & Maintenance',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'repairs_and_maintenance',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Repairs & Maintenance',
    types: ['long', 'consolidated'],
  },
  {
    id: 'hvac_exhaust',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'HVAC & Exhaust',
    types: ['long', 'consolidated'],
  },
  {
    id: 'oven',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Oven',
    types: ['long', 'consolidated'],
  },
  {
    id: 'kitchen_appliances',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Kitchen Appliances',
    types: ['long', 'consolidated'],
  },
  {
    id: 'plumbing',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Plumbing',
    types: ['long', 'consolidated'],
  },
  {
    id: 'ice_machine',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Ice Machine',
    types: ['long', 'consolidated'],
  },
  {
    id: 'refrigeration',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Refrigeration',
    types: ['long', 'consolidated'],
  },
  {
    id: 'stove',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Stove',
    types: ['long', 'consolidated'],
  },
  {
    id: 'grill',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Grill',
    types: ['long', 'consolidated'],
  },
  {
    id: 'register',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Register',
    types: ['long', 'consolidated'],
  },
  {
    id: 'menu_board',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Menu Board',
    types: ['long', 'consolidated'],
  },
  {
    id: 'other_repairs',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Other Repairs',
    types: ['long', 'consolidated'],
  },
  {
    id: 'tools_and_equip',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Tools & Equip',
    types: ['long', 'consolidated'],
  },
  {
    id: 'maintenance_project',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Maintenance - Project',
    types: ['long', 'consolidated'],
  },
  {
    id: 'maintenance_property',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Maintenance - Property',
    types: ['long', 'consolidated'],
  },
  {
    id: 'maintenance_equipment',
    parentId: 'total_repairs_and_maintenance',
    displayName: 'Maintenance - Equipment',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_store_marketing',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Store Marketing',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'store_marketing',
    parentId: 'total_store_marketing',
    displayName: 'Store Marketing',
    types: ['long', 'consolidated'],
  },
  {
    id: 'marketing_relations',
    parentId: 'total_store_marketing',
    displayName: 'Marketing Relations',
    types: ['long', 'consolidated'],
  },
  {
    id: 'customer_relations',
    parentId: 'total_store_marketing',
    displayName: 'Customer Relations',
    types: ['long', 'consolidated'],
  },
  {
    id: 'local_store_marketing',
    parentId: 'total_store_marketing',
    displayName: 'Local Store Marketing',
    types: ['long', 'consolidated'],
  },
  {
    id: 'catering_development',
    parentId: 'total_store_marketing',
    displayName: 'Catering Development',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_preopening_expense',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Total Preopening Expense',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'preopening_expense',
    parentId: 'total_preopening_expense',
    displayName: 'Preopening Expense',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_food_bev',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Food & Bev',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_paper',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Paper',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_cleaning',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Cleaning',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_mgr_salary',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Mgr. Salary',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_crew',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Crew',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_fica',
    parentId: 'total_preopening_expense',
    displayName: 'Opening F.I.C.A',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_futa',
    parentId: 'total_preopening_expense',
    displayName: 'Opening F.U.T.A',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_suta',
    parentId: 'total_preopening_expense',
    displayName: 'Opening S.U.T.A',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_bonus',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Bonus',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_emp_ins',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Emp Ins.',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_travel',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Travel',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_lodging',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Lodging',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_store_mktg',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Store Mktg',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_misc',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Misc',
    types: ['long', 'consolidated'],
  },
  {
    id: 'opening_rent',
    parentId: 'total_preopening_expense',
    displayName: 'Opening Rent',
    types: ['long', 'consolidated'],
  },
  {
    id: 'training_material',
    parentId:
      'total_controllable_expenses_with_bank_charges_and_third_party_fees',
    displayName: 'Training Material',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_controllable_expenses_without_bank_charges_and_third_party_fees',
    parentId: 'root',
    displayName:
      'Total Controllable Expenses Without Bank Charges and Third Party Fees',
    types: ['long', 'consolidated'],
  },
  {
    id: 'profit_after_controllables_before_donation',
    parentId: 'root',
    displayName: 'Profit After Controllables before Donation',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'profit_after_controllables_after_donation',
    parentId: 'root',
    displayName: 'Profit After Controllables After Donation',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'total_noncontrollable_expenses',
    parentId: 'root',
    displayName: 'Total Non-Controllable Expenses',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'total_occupancy_costs',
    parentId: 'total_noncontrollable_expenses',
    displayName: 'Total Occupancy Costs',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'occupancy_costs',
    parentId: 'total_occupancy_costs',
    displayName: 'Occupancy Costs',
    types: ['long', 'consolidated'],
  },
  {
    id: 'base_rent',
    parentId: 'total_occupancy_costs',
    displayName: 'Base Rent',
    types: ['long', 'consolidated'],
  },
  {
    id: 'storage_rent',
    parentId: 'total_occupancy_costs',
    displayName: 'Storage Rent',
    types: ['long', 'consolidated'],
  },
  {
    id: 'percentage_rent',
    parentId: 'total_occupancy_costs',
    displayName: 'Percentage Rent',
    types: ['long', 'consolidated'],
  },
  {
    id: 'food_court_rent',
    parentId: 'total_occupancy_costs',
    displayName: 'Food Court Rent',
    types: ['long', 'consolidated'],
  },
  {
    id: 'common_area_maint',
    parentId: 'total_occupancy_costs',
    displayName: 'Common Area Maint.',
    types: ['long', 'consolidated'],
  },
  {
    id: 'promo_media_fund',
    parentId: 'total_occupancy_costs',
    displayName: 'Promo & Media Fund',
    types: ['long', 'consolidated'],
  },
  {
    id: 'real_estate_taxes',
    parentId: 'total_occupancy_costs',
    displayName: 'Real Estate Taxes',
    types: ['long', 'consolidated'],
  },
  {
    id: 'building_insurance',
    parentId: 'total_occupancy_costs',
    displayName: 'Building Insurance',
    types: ['long', 'consolidated'],
  },
  {
    id: 'other_rent',
    parentId: 'total_occupancy_costs',
    displayName: 'Other Rent',
    types: ['long', 'consolidated'],
  },
  {
    id: 'deferred_rent_expense',
    parentId: 'total_occupancy_costs',
    displayName: 'Deferred Rent Expense',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_royalty_and_cafi',
    parentId: 'total_noncontrollable_expenses',
    displayName: 'Total Royalty & CAFI',
    types: ['long', 'short'],
  },
  {
    id: 'royalty_and_cafi',
    parentId: 'total_royalty_and_cafi',
    displayName: 'Royalty & CAFI',
    types: ['long'],
  },
  {
    id: 'royalty',
    parentId: 'total_royalty_and_cafi',
    displayName: 'Royalty',
    types: ['long'],
  },
  {
    id: 'marketing_fund',
    parentId: 'total_royalty_and_cafi',
    displayName: 'Marketing Fund',
    types: ['long'],
  },
  {
    id: 'total_depreciation_and_amortization',
    parentId: 'total_noncontrollable_expenses',
    displayName: 'Total Depreciation and Amortization',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'depreciation',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation',
    types: ['long', 'consolidated'],
  },
  {
    id: 'depreciation_lhi',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-LHI',
    types: ['long', 'consolidated'],
  },
  {
    id: 'depreciation_re',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-R/E',
    types: ['long', 'consolidated'],
  },
  {
    id: 'depreciation_f_and_f',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-F&F',
    types: ['long', 'consolidated'],
  },
  {
    id: 'depreciation_oe',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-O/E',
    types: ['long', 'consolidated'],
  },
  {
    id: 'depreciation_vehicle',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-Vehicle',
    types: ['long', 'consolidated'],
  },
  {
    id: 'depreciation_franchise_fee',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation Franchise Fee',
    types: ['long', 'consolidated'],
  },
  {
    id: 'depr_exp_solar_projects',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depr Exp - Solar Projects',
    types: ['long', 'consolidated'],
  },
  {
    id: 'depr_exp_solar_additions',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depr Exp -Solar Additions',
    types: ['long', 'consolidated'],
  },
  {
    id: 'depreciation_building',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-Building',
    types: ['long', 'consolidated'],
  },
  {
    id: 'depreciation_property_improvement',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Depreciation-Property Improvement',
    types: ['long', 'consolidated'],
  },
  {
    id: 'tg_income_amortization',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'TG Income Amortization',
    types: ['long', 'consolidated'],
  },
  {
    id: 'startup_costs_amortization',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Startup Costs Amortization',
    types: ['long', 'consolidated'],
  },
  {
    id: 'debt_issuance_costs_amortization',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Debt Issuance Costs Amortization',
    types: ['long', 'consolidated'],
  },
  {
    id: 'finance_lease_amortization_expense',
    parentId: 'total_depreciation_and_amortization',
    displayName: 'Finance Lease Amortization Expense',
    types: ['long', 'consolidated'],
  },
  {
    id: 'dead_project_expenses',
    parentId: 'total_noncontrollable_expenses',
    displayName: 'Dead Project Expenses',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'total_other',
    parentId: 'root',
    displayName: 'Total Other',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'inkind_revenue',
    parentId: 'total_other',
    displayName: 'In-Kind Revenue',
    types: ['long', 'consolidated'],
  },
  {
    id: 'unrealized_gainsloss',
    parentId: 'total_other',
    displayName: 'Unrealized Gains/Loss',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_other_expense_or_income',
    parentId: 'total_other',
    displayName: 'Total Other Expense/Income',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'other_expense_or_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Other Expense/Income',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_interest_expense_or_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Total Interest Expense/Income',
    types: ['long', 'consolidated'],
  },
  {
    id: 'interest_expense_or_income',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Interest Expense/Income',
    types: ['long', 'consolidated'],
  },
  {
    id: 'interest_auxilior_loan',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Interest Auxilior Loan',
    types: ['long', 'consolidated'],
  },
  {
    id: 'interest_hb_loan_12m',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Interest HB loan 12M (2of $6M)',
    types: ['long', 'consolidated'],
  },
  {
    id: 'interest_hb_loan_12m_to_10m',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Interest HB loan 12M to 10M',
    types: ['long', 'consolidated'],
  },
  {
    id: 'interest_hb_loan_65m_manlius',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Interest HB loan 6.5M (Manlius)',
    types: ['long', 'consolidated'],
  },
  {
    id: 'finance_interest_expense',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Finance Interest Expense',
    types: ['long', 'consolidated'],
  },
  {
    id: 'operating_lease_expense',
    parentId: 'total_interest_expense_or_income',
    displayName: 'Operating Lease Expense',
    types: ['long', 'consolidated'],
  },
  {
    id: 'dividend_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Dividend Income',
    types: ['long', 'consolidated'],
  },
  {
    id: 'miscellaneous_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Miscellaneous Income',
    types: ['long', 'consolidated'],
  },
  {
    id: 'accounting_service_revenue',
    parentId: 'total_other_expense_or_income',
    displayName: 'Accounting Service Revenue',
    types: ['long', 'consolidated'],
  },
  {
    id: 'other_investment_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Other Investment Income',
    types: ['long', 'consolidated'],
  },
  {
    id: 'partnership_investment_income',
    parentId: 'total_other_expense_or_income',
    displayName: 'Partnership Investment Income',
    types: ['long', 'consolidated'],
  },
  {
    id: 'gainloss_on_sale_of_assets',
    parentId: 'total_other_expense_or_income',
    displayName: 'Gain/Loss on Sale of Assets',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'realized_gainloss',
    parentId: 'total_other_expense_or_income',
    displayName: 'Realized Gain/Loss',
    types: ['long', 'consolidated'],
  },
  {
    id: 'gainloss_on_fv_of_interest_rate_swap',
    parentId: 'total_other_expense_or_income',
    displayName: 'Gain/Loss on FV of Interest Rate Swap',
    types: ['long', 'consolidated'],
  },
  {
    id: 'gainloss_on_investments',
    parentId: 'total_other_expense_or_income',
    displayName: 'Gain/Loss on Investments',
    types: ['long', 'consolidated'],
  },
  {
    id: 'gainloss_on_finance_lease',
    parentId: 'total_other_expense_or_income',
    displayName: 'Gain/Loss on Finance Lease',
    types: ['long', 'consolidated'],
  },
  {
    id: 'gainloss_on_operating_lease',
    parentId: 'total_other_expense_or_income',
    displayName: 'Gain/Loss on Operating Lease',
    types: ['long', 'consolidated'],
  },
  {
    id: 'penalty_and_fees',
    parentId: 'total_other_expense_or_income',
    displayName: 'Penalty & Fees',
    types: ['long', 'consolidated'],
  },
  {
    id: 'bad_debt_expenses',
    parentId: 'total_other_expense_or_income',
    displayName: 'Bad Debt Expenses',
    types: ['long', 'consolidated'],
  },
  {
    id: 'area_support',
    parentId: 'total_other_expense_or_income',
    displayName: 'Area Support',
    types: ['long', 'consolidated'],
  },
  {
    id: 'extraordinary_items',
    parentId: 'total_other_expense_or_income',
    displayName: 'Extraordinary Items',
    types: ['long', 'consolidated'],
  },
  {
    id: 'dss_research',
    parentId: 'total_other_expense_or_income',
    displayName: 'DSS RESEARCH',
    types: ['long', 'consolidated'],
  },
  {
    id: 'total_taxes',
    parentId: 'root',
    displayName: 'Total Taxes',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'taxes',
    parentId: 'total_taxes',
    displayName: 'Taxes',
    types: ['long', 'consolidated'],
  },
  {
    id: 'federal_income_taxes',
    parentId: 'total_taxes',
    displayName: 'Federal Income Taxes',
    types: ['long', 'consolidated'],
  },
  {
    id: 'state_income_tax',
    parentId: 'total_taxes',
    displayName: 'State Income Tax',
    types: ['long', 'consolidated'],
  },
  {
    id: 'city_income_taxes',
    parentId: 'total_taxes',
    displayName: 'City Income Taxes',
    types: ['long', 'consolidated'],
  },
  {
    id: 'business_property_tax',
    parentId: 'total_taxes',
    displayName: 'Business Property Tax',
    types: ['long', 'consolidated'],
  },
  {
    id: 'suspense_account',
    parentId: 'total_taxes',
    displayName: 'Suspense Account',
    types: ['long', 'consolidated'],
  },
  {
    id: 'net_profit',
    parentId: 'root',
    displayName: 'Net Profit',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'ebidta_without_bonus',
    parentId: 'root',
    displayName: 'EBIDTA Without Bonus',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'ebidta_with_bonus',
    parentId: 'root',
    displayName: 'EBIDTA With Bonus',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'cash_flow',
    parentId: 'root',
    displayName: 'Cash Flow',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'total_restaurant_operating_expenses',
    parentId: 'root',
    displayName: 'Total Restaurant Operating Expenses',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'restaurant_operating_expenses_food_and_paper',
    parentId: 'total_restaurant_operating_expenses',
    dataId: 'total_food_and_paper',
    displayName: 'Food & Paper',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'restaurant_operating_expenses_labor',
    parentId: 'total_restaurant_operating_expenses',
    dataId: 'total_labor',
    displayName: 'Labor',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'occupancy',
    parentId: 'total_restaurant_operating_expenses',
    displayName: 'Occupancy',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'other_operating_expenses',
    parentId: 'total_restaurant_operating_expenses',
    displayName: 'Other Operating Expenses',
    types: ['long', 'short', 'consolidated'],
  },
  {
    id: 'restaurant_level_profit',
    parentId: 'root',
    displayName: 'Restaurant-Level Profit',
    types: ['long', 'short', 'consolidated'],
  },
]

const getBudgetCode = (id: string) => {
  if (/^food_sales/.test(id))
    return id.replace(/food_sales/, 'food_sales_budget')

  if (/^food/.test(id)) {
    if (['food_and_paper', 'food_waste', 'food_court_rent'].includes(id))
      return `${id}_budget`

    return id.replace(/food/, 'food_budget')
  }

  return `${id}_budget`
}

const P_NAD_L_MAPPING = P_AND_L_FIELDS.reduce((result, field) => {
  const dataId: string = field.dataId || field.id
  const budgetId = getBudgetCode(dataId)
  const totalSalesId = 'total_sales'
  const totalSalesBudgetId = getBudgetCode('total_sales')

  return {
    ...result,
    [field.id]: {
      pAndLSales: [dataId],
      pAndLSalesPercent: [dataId, totalSalesId],
      pAndLBudget: [budgetId],
      pAndLBudgetPercent: [budgetId, totalSalesBudgetId],
      pAndLCompare: [dataId, budgetId],
      pAndLComparePercent: [dataId, budgetId, totalSalesId, totalSalesBudgetId],
      pAndLYoySales: [`yoy_${dataId}`],
      pAndLYoySalesPercent: [`yoy_${dataId}`, `yoy_${totalSalesId}`],
      pAndLYoyCompare: [dataId, `yoy_${dataId}`],
      pAndLYoyComparePercent: [
        dataId,
        `yoy_${dataId}`,
        totalSalesId,
        `yoy_${totalSalesId}`,
      ],
      pAndLYtdSales: [`ytd_${dataId}`],
      pAndLYtdSalesPercent: [`ytd_${dataId}`, `ytd_${totalSalesId}`],
      pAndLYtdBudget: [`ytd_${budgetId}`],
      pAndLYtdBudgetPercent: [`ytd_${budgetId}`, `ytd_${totalSalesBudgetId}`],
      pAndLYtdCompare: [`ytd_${dataId}`, `ytd_${budgetId}`],
      pAndLYtdComparePercent: [
        `ytd_${dataId}`,
        `ytd_${budgetId}`,
        `ytd_${totalSalesId}`,
        `ytd_${totalSalesBudgetId}`,
      ],
      pAndLYytdSales: [`yytd_${dataId}`],
      pAndLYytdSalesPercent: [`yytd_${dataId}`, `yytd_${totalSalesId}`],
      pAndLYytdCompare: [`ytd_${dataId}`, `yytd_${dataId}`],
      pAndLYytdComparePercent: [
        `ytd_${dataId}`,
        `yytd_${dataId}`,
        `ytd_${totalSalesId}`,
        `yytd_${totalSalesId}`,
      ],
    },
  }
}, {} as Record<string, Record<string, string[]>>)

const P_AND_L_TYPES_OPTIONS = [
  {
    id: 'long',
    parentId: 'root',
    displayName: 'P&L Long Form',
  },
  { id: 'short', parentId: 'root', displayName: 'P&L Short Form' },
  { id: 'consolidated', parentId: 'root', displayName: 'P&L Consolidated' },
]

const P_AND_L_COLUMNS_OPTIONS = [
  { id: 'pAndLSales', parentId: 'root', displayName: 'Current Sales' },
  { id: 'pAndLBudget', parentId: 'root', displayName: 'Current Budget' },
  {
    id: 'pAndLCompare',
    parentId: 'root',
    displayName: 'Current Sales vs Budget',
  },
  { id: 'pAndLYoySales', parentId: 'root', displayName: 'Prior Year' },
  { id: 'pAndLYoyCompare', parentId: 'root', displayName: 'Current vs. PY' },
  { id: 'pAndLYtdSales', parentId: 'root', displayName: 'Current YTD' },
  { id: 'pAndLYtdBudget', parentId: 'root', displayName: 'Budget YTD' },
  {
    id: 'pAndLYtdCompare',
    parentId: 'root',
    displayName: 'Current YTD vs Budget YTD	',
  },
  { id: 'pAndLYytdSales', parentId: 'root', displayName: 'Prior YTD' },
  {
    id: 'pAndLYytdCompare',
    parentId: 'root',
    displayName: 'Current YTD vs. Prior YTD',
  },
]

const useBbbPnl = ({ withRankOptions }: IOptionType) => {
  const metricCodeHook = useState([['total_sales', 'total_sales.total']])
  const rankByHook = useState([['pAndLSales']])
  const pnlTypesHook = useState([['long']])
  const pnlColumnsHook = useState([
    ['pAndLSales'],
    ['pAndLBudget'],
    ['pAndLCompare'],
  ])
  const metricCodeOptions = useMemo(() => {
    if (!withRankOptions)
      return P_AND_L_FIELDS.filter((field) =>
        field.types.includes(pnlTypesHook[0][0][0]),
      )

    const hasSubOptions = P_AND_L_FIELDS.reduce(
      (result, field) =>
        field.parentId === 'root'
          ? result
          : {
              ...result,
              [field.parentId]: (result[field.parentId] || 0) + 1,
            },
      {} as Record<string, number>,
    )

    return [
      ...Object.keys(hasSubOptions).map((key) => ({
        id: `${key}.total`,
        parentId: key,
        displayName: 'Total',
        types: ['long'],
      })),
      ...P_AND_L_FIELDS,
    ]
  }, [withRankOptions, pnlTypesHook])

  return {
    bbbPnl: useMemo(
      (): IDataType['bbbPnl'] => ({
        metricCode: {
          value: metricCodeHook[0],
          onChange: metricCodeHook[1],
          options: metricCodeOptions,
        },
        rankBy: {
          value: rankByHook[0],
          onChange: rankByHook[1],
          options: [
            'pAndLSales',
            'pAndLCompare',
            'pAndLYtdCompare',
            'pAndLYoyCompare',
            'pAndLYytdCompare',
          ].map((column) => ({
            id: column,
            parentId: 'root',
            displayName: {
              pAndLSales: 'Actual',
              pAndLCompare: 'Actual vs Budget',
              pAndLYtdCompare: 'Actual YTD vs Budget YTD',
              pAndLYoyCompare: 'Actual vs. PY',
              pAndLYytdCompare: 'Actual YTD vs. Prior YTD',
            }[column] as string,
          })),
        },
        pnlTypes: {
          value: pnlTypesHook[0],
          onChange: pnlTypesHook[1],
          options: P_AND_L_TYPES_OPTIONS,
        },
        pnlColumns: {
          value: pnlColumnsHook[0],
          onChange: pnlColumnsHook[1],
          options: P_AND_L_COLUMNS_OPTIONS,
        },
        getBudgetCode,
        getRequiredCodes: (id) => {
          switch (pnlTypesHook[0][0][0]) {
            case 'consolidated': {
              const codes = P_NAD_L_MAPPING[id]
              const royaltyIncome = P_NAD_L_MAPPING['royalty_income']
              const marketingFundIncome =
                P_NAD_L_MAPPING['marketing_fund_income']

              return Object.keys(codes).reduce(
                (result, key) => ({
                  ...result,
                  [key]: [
                    ...codes[key],
                    ...royaltyIncome[key],
                    ...marketingFundIncome[key],
                  ],
                }),
                {},
              )
            }
            default:
              return P_NAD_L_MAPPING[id]
          }
        },
        getExternalFields: (id) => {
          switch (pnlTypesHook[0][0][0]) {
            case 'consolidated': {
              switch (id) {
                case 'total_sales':
                case 'total_food_sales':
                case 'gross_profit':
                case 'profit_after_controllables_before_donation':
                case 'profit_after_controllables_after_donation':
                case 'restaurant_level_profit':
                  return [
                    { type: 'minus', id: 'royalty_income' },
                    { type: 'minus', id: 'marketing_fund_income' },
                  ]
                case 'total_noncontrollable_expenses':
                  return [{ type: 'minus', id: 'total_royalty_and_cafi' }]
                case 'net_profit':
                case 'cash_flow':
                case 'ebidta_with_bonus':
                case 'ebidta_without_bonus':
                  return [
                    { type: 'minus', id: 'royalty_income' },
                    { type: 'minus', id: 'marketing_fund_income' },
                    { type: 'add', id: 'total_royalty_and_cafi' },
                  ]
                default:
                  return []
              }
            }
            default:
              return []
          }
        },
      }),
      [
        metricCodeHook,
        rankByHook,
        metricCodeOptions,
        pnlTypesHook,
        pnlColumnsHook,
      ],
    ),
  }
}

export default useBbbPnl
