import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'

import { IApiDataType } from '../../types'

const useCorporateGuest = (): IApiDataType => {
  const { groupFilter } = useGroupFilter()
  return {
    data: useMemo(() => {
      return {
        title: 'Ops Excellence',
        total: null,
        detail:
          'For the trailing 30 days, the following stores have worse Dissatisfaction Scores than Far West averages. Click the "+" sign to see.',
        hasDetails: true,
      }
    }, [groupFilter]),
    loading: !groupFilter,
  }
}

export default useCorporateGuest
