import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const burgerworksGuestConfig: IConfigsType = {
  burgerworks: {
    '/:brand/corporate_guest': {
      variables: ['corporateGroup', 'discountType', 'lastTimeRange'],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      listLocationGroupGuestKpis: {
        type: 'list',
        api: 'burgerworksListLocationGroupGuestKpis',
        title: 'Systemwide Guest',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'googleRating',
              decimal: 1,
            },
            bottom: 'Google Rating',
          },
          {
            content: {
              key: 'yelpRating',
              decimal: 1,
            },
            bottom: 'Yelp Rating',
          },
          {
            content: {
              key: 'deliveryAvgCustomerReviewScoreDoordash',
              decimal: 1,
            },
            bottom: 'Delivery Rating',
          },
        ],
      },
      listLocationGroupSmgKpis: {
        type: 'list',
        api: 'burgerworksListLocationGroupGuestKpis',
        title: 'SMG',
        fieldWidth: '210px',
        fields: [
          {
            top: 'Overall Satisfaction Rating',
            content: {
              key: 'smgOverallSatisfactionRating',
              decimal: 1,
            },
            bottom: {
              key: 'smgTotalSurveyCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            content: {
              key: 'smgAccuracyOfOrderRating',
              decimal: 1,
            },
            top: 'Accuracy Of Order Rating',
            bottom: {
              key: 'smgOverallSatisfactionCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            content: {
              key: 'smgOrderNotOnTimeRating',
              decimal: 1,
            },
            top: 'Order Not On Time Rating',
            bottom: {
              key: 'smgOverallSatisfactionCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            width: '235px',
            content: {
              key: 'smgFriendlinessOfEmployeesRating',
              decimal: 1,
            },
            top: 'Friendliness Of Employees Rating',
            bottom: {
              key: 'smgOverallSatisfactionCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            content: {
              key: 'smgTasteOfFoodRating',
              decimal: 1,
            },
            top: 'Taste Of Food Rating',
            bottom: {
              key: 'smgOverallSatisfactionCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            content: {
              key: 'smgOverallCleanlinessRating',
              decimal: 1,
            },
            top: 'Overall Cleanliness Rating',
            bottom: {
              key: 'smgOverallSatisfactionCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
        ],
      },
      ratingTrendFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Ratings Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
            label: ' ',
          },
        },
      },
      ratingsTrendChart: {
        type: 'line-chart',
        api: 'burgerworksTrendLocationGroupGuest',
        width: 1100,
        height: 500,
        label: 'date',
        datasets: [
          {
            key: 'yelpRating',
            label: 'Yelp',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
            chartType: 'line',
            decimal: 1,
          },
          {
            key: 'googleRating',
            label: 'Google',
            borderColor: COLORS.Mango,
            backgroundColor: COLORS.Mango,
            chartType: 'line',
            decimal: 1,
          },
          {
            key: 'deliveryAvgCustomerReviewScoreDoordash',
            label: 'Delivery',
            borderColor: COLORS.Chalkboard,
            backgroundColor: COLORS.Chalkboard,
            chartType: 'line',
            decimal: 1,
          },
        ],
        scaleTypes: {
          y: {
            type: 'number',
            decimal: 1,
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {},
          },
        },
      },
      breakdownByStore: {
        type: 'table-v2',
        title: 'Social Breakdown By Store',
        titleMargin: '30px 0',
        api: 'burgerworkListLocationGroupGuest',
        sorting: [
          {
            id: 'smgOverallSatisfactionRating',
            desc: true,
          },
        ],
        rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'smgOverallSatisfactionRating',
            title: 'SMG Overall Satisfaction Rating',
            width: '175px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'smgAccuracyOfOrderRating',
            title: 'SMG Accuracy Of Order Rating',
            width: '175px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'smgOrderNotOnTimeRating',
            title: 'SMG Order Not On Time Rating',
            width: '175px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'smgFriendlinessOfEmployeesRating',
            title: 'SMG Friendliness Of Employees Rating',
            width: '175px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'smgTasteOfFoodRating',
            title: 'SMG Taste Of Food Rating',
            width: '175px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'smgOverallCleanlinessRating',
            title: 'SMG Overall Cleanliness Rating',
            width: '175px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'yelpRating',
            title: 'Yelp',
            width: '75px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'googleRating',
            title: 'Google',
            width: '100px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'deliveryAvgCustomerReviewScoreDoordash',
            title: 'Delivery',
            width: '100px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
        ],
      },
    },
    '/:brand/guest': {
      variables: ['lastTimeRange'],
      guestTitle: {
        type: 'title',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
      },
      groupFilter: {
        api: 'locationFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      expoAi: {
        type: 'ai-summary-box',
        api: 'locationGuestAiSummary',
      },
      listLocationGuestKpis: {
        type: 'list',
        api: 'burgerworksListLocationGuestKpis',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'googleRating',
              decimal: 1,
            },
            bottom: 'Google Rating',
          },
          {
            content: {
              key: 'yelpRating',
              decimal: 1,
            },
            bottom: 'Yelp Rating',
          },
          {
            content: {
              key: 'deliveryAvgCustomerReviewScoreDoordash',
              decimal: 1,
            },
            bottom: 'Delivery Rating',
          },
        ],
      },
      listLocationSmgKpis: {
        type: 'list',
        api: 'burgerworksListLocationGuestKpis',
        title: 'SMG',
        fieldWidth: '210px',
        fields: [
          {
            content: {
              key: 'smgOverallSatisfactionRating',
              decimal: 1,
            },
            top: 'Overall Satisfaction Rating',
            bottom: {
              key: 'smgTotalSurveyCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            content: {
              key: 'smgAccuracyOfOrderRating',
              decimal: 1,
            },
            top: 'Accuracy Of Order Rating',
            bottom: {
              key: 'smgOverallSatisfactionCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            content: {
              key: 'smgOrderNotOnTimeRating',
              decimal: 1,
            },
            top: 'Order Not On Time Rating',
            bottom: {
              key: 'smgOverallSatisfactionCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            width: '235px',
            content: {
              key: 'smgFriendlinessOfEmployeesRating',
              decimal: 1,
            },
            top: 'Friendliness Of Employees Rating',
            bottom: {
              key: 'smgOverallSatisfactionCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            content: {
              key: 'smgTasteOfFoodRating',
              decimal: 1,
            },
            top: 'Taste Of Food Rating',
            bottom: {
              key: 'smgOverallSatisfactionCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            content: {
              key: 'smgOverallCleanlinessRating',
              decimal: 1,
            },
            top: 'Overall Cleanliness Rating',
            bottom: {
              key: 'smgOverallSatisfactionCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
        ],
      },
      guestCommentTable: {
        type: 'table-v2',
        title: 'Guest Reviews',
        titleMargin: '30px 0',
        api: 'burgerworksListLocationGuestCommentData',
        sorting: [
          {
            id: 'ratingScore',
            desc: true,
          },
        ],
        rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
        columns: [
          {
            key: 'reviewSource',
            title: 'Source',
            width: '100px',
            align: 'center',
          },
          {
            key: 'reviewContent',
            title: 'Review',
            width: '500px',
          },
          {
            key: 'ratingScore',
            title: 'Rating',
            width: '250px',
            sortable: true,
          },
          {
            key: 'date',
            title: 'Date',
            width: '150px',
            align: 'center',
          },
        ],
      },
      guestRatingTrendTimeRangeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Guest Ratings',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
          },
        },
      },
      guestRatingTrendChart: {
        type: 'line-chart',
        api: 'burgerworksTrendLocationGuestKpis',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'deliveryAvgCustomerReviewScoreDoordash',
            label: 'Store Average Rating Stars',
            decimal: 2,
          },
          {
            key: 'systemwideDeliveryAvgCustomerReviewScoreDoordash',
            label: 'Systemwide Average Rating Stars',
            decimal: 2,
          },
        ],
        scaleTypes: {
          y: {
            type: 'number',
            decimal: 1,
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: 'Rating Starst',
              },
            },
          },
        },
      },
    },
  },
}

export default burgerworksGuestConfig
