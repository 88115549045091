import useMadeFromScratchSummary, {
  madeFromScratchSummaryConfigs,
} from './useMadeFromScratchSummary'
import useSystemwideOverviewKpis, {
  systemwideOverviewKpisConfigs,
} from './useSystemwideOverviewKpis'

export const configs = {
  systemwideOverviewKpis: systemwideOverviewKpisConfigs,
  madeFromScratchSummary: madeFromScratchSummaryConfigs,
}

export const api = {
  systemwideOverviewKpis: useSystemwideOverviewKpis,
  madeFromScratchSummary: useMadeFromScratchSummary,
}
