import { useMemo, useState } from 'react'

export interface IDataType {
  deliveryKpiType?: {
    options: {
      id: string
      parentId: string
      displayName: string
    }[]
    value: string[][]
  }
}

const useDeliveryKpiType = (): IDataType => {
  const deliveryKpiTypeHook = useState([['orderWithAnyIssuePercent']])

  return {
    deliveryKpiType: useMemo(() => {
      const options = [
        {
          id: 'orderWithAnyIssuePercent',
          parentId: 'root',
          displayName: '% Orders w/ Any Issues',
        },
        {
          id: 'orderWithAccuracyIssuePercent',
          parentId: 'root',
          displayName: '% Orders w/ Accuracy Issues',
        },
        {
          id: 'orderWithCancellationsIssuePercent',
          parentId: 'root',
          displayName: '% Orders w/ Cancellations Issues',
        },
        {
          id: 'orderWithLatenessIssuePercent',
          parentId: 'root',
          displayName: '% Orders w/ Lateness Issues',
        },
        {
          id: 'combinedStarRating',
          parentId: 'root',
          displayName: 'Combined Star Rating',
        },
      ]

      return {
        value: deliveryKpiTypeHook[0],
        options: options,
        onChange: deliveryKpiTypeHook[1],
      }
    }, [deliveryKpiTypeHook]),
  }
}

export default useDeliveryKpiType
