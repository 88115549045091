import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'

type IDataType = Record<
  | 'listLocationMetricValues'
  | 'priorListLocationMetricValues'
  | 'yoyListLocationMetricValues',
  {
    nodes: {
      metricSummaryData: Record<
        string,
        {
          name: string
          unit: 'CENT' | 'PERCENTAGE'
          value: number
        }
      >
    }[]
  }
>

const query = gql`
  query listLocationMetricValues(
    $iCurrentStartDate: Date!
    $iCurrentEndDate: Date!
    $iPriorStartDate: Date!
    $iPriorEndDate: Date!
    $iLocationIds: [Int!]
    $iLocationGroupIds: [Int!]
    $iMetricCodes: [String!]!
  ) {
    listLocationMetricValues(
      iStartDate: $iCurrentStartDate
      iEndDate: $iCurrentEndDate
      iFilter: {
        location_ids: $iLocationIds
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        metricSummaryData
      }
    }

    priorListLocationMetricValues: listLocationMetricValues(
      iStartDate: $iPriorStartDate
      iEndDate: $iPriorEndDate
      iFilter: {
        location_ids: $iLocationIds
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        metricSummaryData
      }
    }

    yoyListLocationMetricValues: listLocationMetricValues(
      iStartDate: $iCurrentStartDate
      iEndDate: $iCurrentEndDate
      iFilter: {
        location_ids: $iLocationIds
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
        use_yoy: true
      }
    ) {
      nodes {
        metricSummaryData
      }
    }
  }
`

const useFetchMetricValue = (metrics: string[]) => {
  const { variables } = useVariables()

  const currentPeriod = variables.date?.getInfo(0)
  const priorPeriod = variables.date?.getInfo(-1)
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      ...(hasGroupBy
        ? {
            iLocationGroupIds: groupFilter?.ids,
          }
        : {
            iLocationIds: groupFilter?.ids,
          }),
      iCurrentStartDate: currentPeriod?.dateRange.startDateStr,
      iCurrentEndDate: currentPeriod?.dateRange.endDateStr,
      iPriorStartDate: priorPeriod?.dateRange.startDateStr,
      iPriorEndDate: priorPeriod?.dateRange.endDateStr,
      iMetricCodes: metrics,
    },
    skip: !currentPeriod || !priorPeriod || !groupFilter?.ids,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      return [
        ...metrics,
        ...metrics.map((m) => `prior_${m}`),
        ...metrics.map((m) => `yoy_${m}`),
      ].reduce((result, metricCode) => {
        const type = (() => {
          if (/^yoy_/.test(metricCode)) return 'yoy' as const
          if (/^prior_/.test(metricCode)) return 'prior' as const
          return 'current' as const
        })()
        const dataKey =
          type === 'current'
            ? ('listLocationMetricValues' as const)
            : (`${type}ListLocationMetricValues` as const)
        const key = metricCode.replace(
          /^(yoy|prior)_/,
          '',
        ) as 'listLocationMetricValues'
        const metricSummaryData = data?.[dataKey]?.nodes.find(
          (m) => m.metricSummaryData,
        )?.metricSummaryData?.[key]

        const value = (() => {
          switch (metricSummaryData?.unit) {
            case 'CENT':
              return metricSummaryData.value * 0.01
            case 'PERCENTAGE':
              return metricSummaryData.value * 100
            default:
              return metricSummaryData?.value
          }
        })()

        return {
          ...result,
          [metricCode]: value,
        }
      }, {} as Record<string, number | undefined>)
    }, [data, metrics]),
    loading,
  }
}

export { useFetchMetricValue }
export default useFetchMetricValue
