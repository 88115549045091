import styled from 'styled-components'

import { useDateFilter } from 'pared/Routes/renderer/dateFilter'
import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import SalesByHourByDayChart from './SalesByHourByDayChart'
import SalesPerLaborHourChart from './SalesPerLaborHour'
import { IApiKeyType } from './hooks/useApi'

export interface IPropsType<K extends IApiKeyType = IApiKeyType> {
  type: 'location-sales-per-labor-hour'
  api: K
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const SalesPerLaborHourChartContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    overflow: hidden;
  }
`

const VerticalSpacer40px = styled.div`
  padding: 40px 50px 40px 50px;
`

const LocationSalesPerLaborHour = ({ api }: IPropsType) => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = groupFilter?.label.replace(/\d+ -/, '') || ''

  if (!locationId || !startDate || !endDate) {
    return null
  }

  return (
    <>
      <SalesPerLaborHourChartContainer>
        <SalesPerLaborHourChart
          locationName={locationName}
          locationId={locationId}
          startDate={startDate}
          endDate={endDate}
        />
      </SalesPerLaborHourChartContainer>
      <VerticalSpacer40px />
      <SalesPerLaborHourChartContainer>
        <SalesByHourByDayChart
          locationId={locationId}
          startDate={startDate}
          endDate={endDate}
        />
      </SalesPerLaborHourChartContainer>
      <VerticalSpacer40px />
    </>
  )
}

export default LocationSalesPerLaborHour
