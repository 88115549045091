import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiType } from '../../types'

const QUERY_NAME = 'listLocationMetricValues'
const query = gql`
  query (
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    ${QUERY_NAME}(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        metricData
      }
    }
  }
`

export const locationDiscountCompConfigs = {
  name: 'string',
  amount: 'price',
  percentSales: 'percent',
} as const

const useLocationDiscountComp = (): IApiType => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()

  const iFilter = {
    location_ids: groupFilter?.ids,
    metric_groups: [
      'Expo Module - Discount Comps',
      'Expo Module - Fundamental Sales',
    ],
  }

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const nodes = data?.[QUERY_NAME].nodes as
        | {
            locationId: string
            metricData: {
              [key: string]: {
                name: string
                unit: string
                value: number
              }
            }
          }[]
        | undefined

      if (!nodes) return null
      const mappedData: {
        [discountComp: string]: {
          [kpi: string]: number | string
        }
      } = {}
      const metricData = nodes[0]?.metricData
      let netSales = 0

      for (const key in metricData) {
        if (key === 'net_sales') {
          if (metricData[key].unit === 'CENT') {
            netSales = metricData[key].value / 100
          } else {
            netSales = metricData[key].value
          }
          continue
        }

        const discountComp = key.split('discount_comps_')[1]
        const kpi = 'amount'

        if (!mappedData[discountComp]) {
          mappedData[discountComp] = {
            name: metricData[key].name.split(' - ')[1],
            [kpi]: metricData[key].value,
          }
        } else {
          mappedData[discountComp][kpi] = metricData[key].value
        }
      }

      const rows = Object.values(mappedData).map((n) => {
        return {
          ...n,
          percentSales: (100 * (n.amount as number)) / netSales,
          id: n.name as string,
          parentId: 'root',
        }
      })

      return rows
    }, [groupFilter, data]),
    loading,
  }
}

export default useLocationDiscountComp
