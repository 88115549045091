import useUDelivery from './unifyEndPoints/useUDelivery'
import useULossPrevention from './unifyEndPoints/useULossPrevention'
import useUSales from './unifyEndPoints/useUSales'
import useCalls from './useCalls'
import useDelivery from './useDelivery'
import useFoodCost from './useFoodCost'
import useGuest from './useGuest'
import useLabor from './useLabor'
import useLossPrevention from './useLossPrevention'
import useSales from './useSales'

export type IAiSummaryType =
  | 'delivery'
  | 'guest'
  | 'labor'
  | 'lossPrevention'
  | 'sales'
  | 'foodCost'
  | 'calls'
  | 'uLossPrevention'
  | 'uDelivery'
  | 'uSales'

export const hooks = [
  ['sales', useSales],
  ['labor', useLabor],
  ['lossPrevention', useLossPrevention],
  ['guest', useGuest],
  ['delivery', useDelivery],
  ['foodCost', useFoodCost],
  ['calls', useCalls],
  ['uLossPrevention', useULossPrevention],
  ['uDelivery', useUDelivery],
  ['uSales', useUSales],
] as const
