import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useAdvancedFilter } from 'pared/Routes/renderer/advancedFilter'
import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { getBrandLocationGroupId } from 'pared/utils/brand'

import { useDateFilter } from '../../dateFilter'
import { useVariables } from '../../variables'
import { IApiDataType } from '../types'

interface IDataType {
  trendLocationGroupMetricValues: {
    nodes: {
      businessMonth: number
      businessWeek: number
      businessWeekOfMonth: number
      metricData: Record<
        string,
        {
          name: string
          unit: string
          value: number
        }
      >
    }[]
  }
}

interface IPriorDataType {
  trendPriorYearLocationGroupMetricValues: {
    nodes: {
      businessMonth: number
      businessWeek: number
      businessWeekOfMonth: number
      metricData: Record<
        string,
        {
          name: string
          unit: string
          value: number
        }
      >
    }[]
  }
}

interface IReturnType {
  period: string
  value: number | null
}

const query = gql`
  query trendLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) {
      nodes {
        businessMonth
        businessWeek
        businessWeekOfMonth
        metricData
      }
    }
  }
`

const priorYearQuery = gql`
  query trendPriorYearLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendPriorYearLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) {
      nodes {
        businessMonth
        businessWeek
        businessWeekOfMonth
        metricData
      }
    }
  }
`

export const farwestSalesChartsConfigs = {
  period: 'string',
  currentValue: 'price',
  lastYearValue: 'price',
} as const

function getRevenueCenterName(code: string) {
  if (code.includes('revenue_center_')) {
    switch (code) {
      case 'revenue_center_sales_online_this_year':
        return 'Online (Pick Up)'

      case 'revenue_center_sales_takeoutcallincallcenter_this_year':
        return 'Take Out - Call in/Call Center'

      case 'revenue_center_sales_dispatch_this_year':
        return 'Online (Dispatch)'

      default:
        return code
          .replace(/revenue_center_sales_/g, '')
          .replace(/revenue_center_check_average_/g, '')
          .replace(/_this_year/g, '')
          .replace(/_([a-z])/g, (match, letter) => ` ${letter.toUpperCase()}`)
          .replace(/^[a-z]/, (letter) => letter.toUpperCase())
    }
  } else {
    return code
      .replace(/net_/g, '')
      .replace(/_([a-z])/g, (match, letter) => ` ${letter.toUpperCase()}`)
      .replace(/^[a-z]/, (letter) => letter.toUpperCase())
  }
}

const useFarwestSalesCharts = () => {
  const { variables } = useVariables()
  const { startDate, endDate } = useDateFilter()
  const advancedFilter = useAdvancedFilter('select')
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const brandLocationGroupId = getBrandLocationGroupId()

  const groupBy = advancedFilter
    ? advancedFilter.value.timeInterval
    : 'business_month'

  const dateList =
    advancedFilter?.value.timeInterval === 'business_week'
      ? variables.date?.getCalendar('week', 'year')?.ids || [[]]
      : variables.date?.getCalendar('period', 'year')?.ids || [[]]

  const metrics = advancedFilter?.value.channel

  const { data, loading: currentLoading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iGroupBy: groupBy,
      iFilter: {
        location_group_ids: !hasGroupBy
          ? [brandLocationGroupId]
          : groupFilter?.ids,
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: [metrics],
      },
    },
    skip: !startDate && !endDate && !metrics,
  })

  const { data: priorData, loading: priorLoading } = useQuery<IPriorDataType>(
    priorYearQuery,
    {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iGroupBy: groupBy,
        iFilter: {
          location_group_ids: !hasGroupBy
            ? [brandLocationGroupId]
            : groupFilter?.ids,
          intersected_location_group_ids: groupFilter?.intersectedIds,
          metrics: [metrics],
        },
        intersected_location_group_ids: groupFilter?.intersectedIds,
      },
      skip: !startDate && !endDate && !metrics,
    },
  )

  const loading = currentLoading && priorLoading

  return {
    data: useMemo((): IApiDataType => {
      if (
        !data?.trendLocationGroupMetricValues.nodes ||
        !priorData?.trendPriorYearLocationGroupMetricValues.nodes ||
        !metrics
      )
        return null

      const metricData = data?.trendLocationGroupMetricValues.nodes.map(
        (item): IReturnType => {
          return {
            period:
              groupBy === 'business_month'
                ? `P${item.businessMonth}`
                : `P${item.businessMonth}W${item.businessWeekOfMonth}`,
            value: item.metricData?.[metrics].value
              ? item.metricData?.[metrics].value * 100
              : null,
          }
        },
      )

      const priorMetricData =
        priorData?.trendPriorYearLocationGroupMetricValues.nodes.map(
          (item): IReturnType => {
            return {
              period:
                groupBy === 'business_month'
                  ? `P${item.businessMonth}`
                  : `P${item.businessMonth}W${item.businessWeekOfMonth}`,
              value: item.metricData?.[metrics].value
                ? item.metricData?.[metrics].value * 100
                : null,
            }
          },
        )

      const displayMetric = getRevenueCenterName(metrics)

      return {
        source: dateList
          .map((date) => {
            const currentData = metricData.find((d) => d.period === date)
            const sameDaylyData = priorMetricData.find((d) => d.period === date)

            return {
              period: `${date} - ${displayMetric}`,
              currentValue: currentData?.value,
              lastYearValue: sameDaylyData?.value,
            }
          })
          .filter((d) => d.period),
      }
    }, [data, priorData, startDate, endDate]),
    loading,
  }
}

export default useFarwestSalesCharts
