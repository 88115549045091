type IOperatorType =
  | '*'
  | '/'
  | '+'
  | '-'
  | 'percentageOf'
  | 'variancePercentageOf'

export const LOADING = Symbol('loading')

const isNumber = (d: unknown): d is number => typeof d === 'number'

const calc = (
  a: unknown,
  operator: IOperatorType,
  b: unknown,
): null | number | typeof LOADING => {
  if (a === LOADING || b === LOADING) return LOADING

  if (!isNumber(a) || !isNumber(b)) return null

  switch (operator) {
    case '*':
      return a * b
    case '/':
      return b === 0 ? null : a / b
    case '-':
      return a - b
    case '+':
      return a + b
    case 'percentageOf':
      return calc(calc(a, '/', b), '*', 100)
    case 'variancePercentageOf':
      return calc(calc(a, '-', b), 'percentageOf', b)
  }
}

export const calcAll = (
  data: unknown[],
  operator: Exclude<IOperatorType, 'percentageOf' | 'variancePercentageOf'>,
) => data.reduce((result, d) => calc(result, operator, d), 0)

export default calc
