import { useVariables } from '../../../variables'
import buildMetricTrendsHook from '../utils/buildMetricTrendsHook'

export const trendLocationGroupGuestConfigs = {
  date: 'string',
  googleRating: 'number',
  yelpRating: 'number',
  deliveryAvgCustomerReviewScoreDoordash: 'number',
} as const

const useCommonMetricValuesHook = buildMetricTrendsHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'google_rating',
      'yelp_rating',
      'delivery_avg_customer_review_score_doordash',
    ],
  },
})

const useTrendLocationGroupGuest = () => {
  const { variables } = useVariables()
  const dateRange = variables.lastTimeRange?.value[0][0] || ''
  return useCommonMetricValuesHook(dateRange)
}

export default useTrendLocationGroupGuest
