import * as d3Interpolate from 'd3-interpolate'
import * as d3 from 'd3-scale'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../variables'

interface IBasePropsType {
  value: unknown
}

type IColorType =
  | string
  | {
      range: number[]
      colors: string[]
      value?: `<%- ${string} %>`
    }

export type IPropsType =
  | (IBasePropsType & {
      type: 'string'
    })
  | (IBasePropsType & {
      type: 'price'
      decimal?: number
    })
  | (IBasePropsType & {
      type: 'percent'
      decimal?: number
      color?: IColorType
    })
  | (IBasePropsType & {
      type: 'number'
      decimal?: number
      format?: `<%- ${string} %>`
    })

const getColor = (
  color: IColorType,
  template: ReturnType<typeof useVariables>['template'],
  external: Record<string, unknown>,
) => {
  if (!color) return

  if (typeof color === 'string') return template(color, { external })

  const value = parseFloat(
    template(color.value || '<%- value %>', { external }),
  )

  if (isNaN(value)) return

  return d3
    .scaleLinear(color.range, color.colors)
    .interpolate(d3Interpolate.interpolateHsl)(value)
}

const Td = styled.td<{ color?: string }>`
  ${({ color }) => (!color ? '' : `color: ${color};`)}
`

const Format = ({ value, ...props }: IPropsType) => {
  const { template } = useVariables()

  if (value === '-' || value === undefined || value === null) return <Td>-</Td>

  switch (props.type) {
    case 'string':
      return <Td>{value}</Td>

    case 'price':
      return (
        <Td>{toUsdString(parseFloat(value as string) / 100, props.decimal)}</Td>
      )

    case 'percent':
      return (
        <Td
          color={getColor(
            props.color || '<%- value < 0 ? colors.Pomodoro : "" %>',
            template,
            { value, colors: COLORS },
          )}
        >
          {toPercentString(value as string, props.decimal)}
        </Td>
      )

    case 'number': {
      const format = props.format || '<%- valueStr %>'

      if (typeof value === 'string') {
        const numberValue = parseFloat(value)
        const valueStr = numberValue.toLocaleString('en-US', {
          minimumFractionDigits: props.decimal || 0,
          maximumFractionDigits: props.decimal || 0,
        })

        return (
          <Td>
            {template(format, {
              external: {
                value: numberValue,
                valueStr,
              },
            })}
          </Td>
        )
      }

      const valueStr = (value as number).toLocaleString('en-US', {
        minimumFractionDigits: props.decimal || 0,
        maximumFractionDigits: props.decimal || 0,
      })

      return (
        <Td>
          {template(format, {
            external: {
              value,
              valueStr,
            },
          })}
        </Td>
      )
    }

    default:
      return null
  }
}

export default Format
