import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const fwWingstopGuestConfig: IConfigsType = {
  fw_wingstop: {
    '/:brand/guest': {
      groupFilter: {
        api: 'locationFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'custom',
        ],
      },
      title: {
        type: 'title',
        title: 'Ops Excellence',
      },
      expoAi: {
        type: 'ai-summary-box',
        api: 'fwWingstopLocationGuestAiSummary',
      },
      listOsatScores: {
        type: 'list',
        api: 'fwWingstopListLocationGuestKpis',
        fieldWidth: '160px',
        fields: [
          {
            top: 'Digital OSAT Score',
            content: {
              key: 'smgDigitalOsatFiveStarPercentage',
              decimal: 1,
            },
            bottom: {
              key: 'smgDigitalOsatTotalCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            top: 'Delivery OSAT Score',
            content: {
              key: 'smgDeliveryOsatFiveStarPercentage',
              decimal: 1,
            },
            bottom: {
              key: 'smgDeliveryOsatTotalCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            top: 'Non-Digital OSAT Score',
            content: {
              key: 'smgNonDigitalOsatFiveStarPercentage',
              decimal: 1,
            },
            bottom: {
              key: 'smgNonDigitalOsatTotalCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            top: 'QSC Score',
            content: {
              key: 'smgQscScore',
              decimal: 2,
            },
            bottom: {
              key: 'smgQscCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            top: 'WingYou Completion Score',
            content: {
              key: 'smgWingyouScore',
              decimal: 1,
            },
            bottom: {
              key: 'smgWingyouCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            top: 'Dissatisfaction Score',
            content: {
              key: 'smgDissatisfactionPercentage',
              decimal: 1,
            },
            bottom: {
              key: 'smgDissatisfactionCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
          {
            top: 'What Went Wrong - Accurate Score',
            content: {
              key: 'smgInaccuratePercentage',
              decimal: 1,
            },
            bottom: {
              key: 'smgInaccurateCount',
              format: '<%- valueStr + " surveys" %>',
            },
          },
        ],
      },
      guestCommentTable: {
        type: 'table-v2',
        title: 'Guest Reviews',
        titleMargin: '30px 0',
        api: 'fwWingstopListLocationGuestCommentData',
        sorting: [
          {
            id: 'ratingScore',
            desc: true,
          },
        ],
        rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
        columns: [
          {
            key: 'reviewSource',
            title: 'Source',
            width: '100px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'reviewContent',
            title: 'Review',
            width: '500px',
          },
          {
            key: 'ratingScore',
            title: 'Rating',
            width: '250px',
            sortable: true,
          },
          {
            key: 'date',
            title: 'Date',
            width: '150px',
            align: 'center',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default fwWingstopGuestConfig
