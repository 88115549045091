import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { IApiType, INestedDataType } from '../../types'
import buildItemMetricTrendsHook, {
  TrendNode,
} from '../utils/buildItemMetricTrendsHook'

const NUM_OF_TOP_BOTTOM_QTY = 5
const NUM_OF_TOP_BOTTOM_SALES = 10
const TRENDING_DAYS = 28

export const itemWowGrowthByQuantityConfigs = {
  itemName: 'string',
  categoryName: 'string',
  lastWeek1: 'number',
  lastWeek2: 'number',
  lastWeek3: 'number',
  lastWeek4: 'number',
  wowGrowth: 'percent',
} as const

export const itemWowGrowthBySalesConfigs = {
  itemName: 'string',
  categoryName: 'string',
  lastWeek1: 'string',
  lastWeek2: 'string',
  lastWeek3: 'string',
  lastWeek4: 'string',
  wowGrowth: 'percent',
} as const

const useItemWowGrowth = (
  type: 'top' | 'bottom',
  by: 'quantity' | 'sales',
): IApiType => {
  const { startDate } = useDateFilter()

  const queryStartDate = startDate
    ? moment(startDate).subtract(TRENDING_DAYS, 'days').format('YYYY-MM-DD')
    : moment().subtract(TRENDING_DAYS, 'days').format('YYYY-MM-DD')
  const queryEndDate = startDate
    ? moment(startDate).subtract(1, 'days').format('YYYY-MM-DD')
    : moment().subtract(1, 'days').format('YYYY-MM-DD')

  const useTrendData = buildItemMetricTrendsHook((nodes) => nodes)
  const { data, loading } = useTrendData({
    metrics: ['quantity_sold'],
    startDate: queryStartDate,
    endDate: queryEndDate,
  })

  const numOfTopBottom =
    by === 'quantity' ? NUM_OF_TOP_BOTTOM_QTY : NUM_OF_TOP_BOTTOM_SALES
  const valueExtractor = (node: TrendNode) =>
    by === 'quantity' ? node.quantitySold ?? 0 : node.percentOfSales ?? 0
  const valueFormatter = (value: number) =>
    by === 'quantity'
      ? Math.round(value * 100) / 100
      : Math.round(value * 10000) / 100

  const processedData = useMemo<INestedDataType[] | null>(() => {
    if (!data) return null

    const allWeeks = Array.from(new Set(data.map((node) => node.startDate)))
      .map((dateStr) => new Date(dateStr))
      .sort((a, b) => a.getTime() - b.getTime())

    const lastFourWeeksDates = allWeeks.slice(-4)
    const lastFourWeeksStrings = lastFourWeeksDates.map(
      (date) => date.toISOString().split('T')[0],
    )

    const itemMap = new Map<
      string,
      {
        earliest: TrendNode
        latest: TrendNode
        valuesPerWeek: Map<string, number>
      }
    >()

    data.forEach((node) => {
      const key = node.itemName
      const dateStr = node.startDate
      const current = itemMap.get(key)

      const value = valueExtractor(node)

      if (!current) {
        const valuesPerWeek = new Map<string, number>()
        valuesPerWeek.set(dateStr, value)
        itemMap.set(key, { earliest: node, latest: node, valuesPerWeek })
      } else {
        const earliestDate = new Date(current.earliest.startDate)
        const latestDate = new Date(current.latest.startDate)
        const nodeDate = new Date(node.startDate)
        if (nodeDate < earliestDate) current.earliest = node
        if (nodeDate > latestDate) current.latest = node
        const existingValue = current.valuesPerWeek.get(dateStr) || 0
        current.valuesPerWeek.set(dateStr, existingValue + value)
      }
    })

    const result = Array.from(itemMap.values()).map(
      ({ earliest, latest, valuesPerWeek }) => {
        let validWeeks = lastFourWeeksStrings.map((week, index) => ({
          week,
          value: valuesPerWeek.get(week) ?? 0,
          index,
        }))
        validWeeks = validWeeks.slice(
          validWeeks.findIndex((week) => week.value !== 0),
        )

        let wowGrowth: number | null = null

        if (
          validWeeks.length >= 2 &&
          validWeeks[validWeeks.length - 1].week === lastFourWeeksStrings[3]
        ) {
          const earliestValid = validWeeks[0]
          const latestValid = validWeeks[validWeeks.length - 1]
          const diffWeeks = latestValid.index - earliestValid.index

          if (diffWeeks === 0) {
            wowGrowth = null
          } else if (earliestValid.value === 0 && latestValid.value === 0) {
            wowGrowth = 0
          } else if (earliestValid.value === 0) {
            wowGrowth = latestValid.value === 0 ? 0 : Infinity
          } else if (latestValid.value === 0) {
            wowGrowth = -100
          } else {
            const growthFactor =
              (latestValid.value / earliestValid.value) ** (1 / diffWeeks)
            wowGrowth = (growthFactor - 1) * 100
          }
        }

        const lastWeek1 = valuesPerWeek.get(lastFourWeeksStrings[3]) ?? 0
        const lastWeek2 = valuesPerWeek.get(lastFourWeeksStrings[2]) ?? 0
        const lastWeek3 = valuesPerWeek.get(lastFourWeeksStrings[1]) ?? 0
        const lastWeek4 = valuesPerWeek.get(lastFourWeeksStrings[0]) ?? 0

        return {
          itemName: earliest.itemName,
          categoryName: earliest.categoryName,
          lastWeek1: valueFormatter(lastWeek1),
          lastWeek2: valueFormatter(lastWeek2),
          lastWeek3: valueFormatter(lastWeek3),
          lastWeek4: valueFormatter(lastWeek4),
          wowGrowth,
          id: earliest.itemName,
          parentId: 'root',
        }
      },
    )

    const filteredResult = result.filter(
      (item) =>
        item.wowGrowth !== null &&
        isFinite(item.wowGrowth) &&
        !isNaN(item.wowGrowth),
    )

    if (type === 'top') {
      const sortedResult = filteredResult.sort(
        (a, b) => (b.wowGrowth || 0) - (a.wowGrowth || 0),
      )
      const topWowGrowth = sortedResult[numOfTopBottom - 1]?.wowGrowth
      return sortedResult.filter(
        (item, index) =>
          index < numOfTopBottom || item.wowGrowth === topWowGrowth,
      )
    } else {
      const sortedResult = filteredResult.sort(
        (a, b) => (a.wowGrowth || 0) - (b.wowGrowth || 0),
      )
      const neg100Items = sortedResult.filter((item) => item.wowGrowth === -100)
      const nonNeg100Items = sortedResult.filter(
        (item) => item.wowGrowth !== -100,
      )

      const selected = nonNeg100Items.slice(0, numOfTopBottom)

      const combined = [...neg100Items, ...selected]

      return combined
    }
  }, [data, type, numOfTopBottom, valueExtractor, valueFormatter])

  return {
    data: processedData,
    loading,
  }
}

export const useTopItemWowGrowthByQuantity = (): IApiType => {
  return useItemWowGrowth('top', 'quantity')
}

export const useBottomItemWowGrowthByQuantity = (): IApiType => {
  return useItemWowGrowth('bottom', 'quantity')
}

export const useTopItemWowGrowthBySales = (): IApiType => {
  return useItemWowGrowth('top', 'sales')
}

export const useBottomItemWowGrowthBySales = (): IApiType => {
  return useItemWowGrowth('bottom', 'sales')
}

export const configs = {
  topItemWowGrowthByQuantity: itemWowGrowthByQuantityConfigs,
  bottomItemWowGrowthByQuantity: itemWowGrowthByQuantityConfigs,
  topItemWowGrowthBySales: itemWowGrowthBySalesConfigs,
  bottomItemWowGrowthBySales: itemWowGrowthBySalesConfigs,
}

export const api = {
  topItemWowGrowthByQuantity: useTopItemWowGrowthByQuantity,
  bottomItemWowGrowthByQuantity: useBottomItemWowGrowthByQuantity,
  topItemWowGrowthBySales: useTopItemWowGrowthBySales,
  bottomItemWowGrowthBySales: useBottomItemWowGrowthBySales,
}
