import { useMemo } from 'react'

import { IApiType, INestedDataType } from '../../types'
import buildItemMetricValuesHook from '../utils/buildItemMetricValuesHook'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const itemConfigs = {
  itemName: 'string',
  displayParentCategoryName: 'string',
  quantitySold: 'number',
  totalSales: 'price',
  checkCount: 'number',
  checkTotalSales: 'price',
  checkAverage: 'price',
  percentOfChecks: 'percent',
  checkItemCount: 'number',
  avgCheckItemCount: 'number',
  avgCheckAdditionalItemCount: 'number',
} as const

type ItemData = INestedDataType & {
  itemName: string
  displayParentCategoryName: string
  quantitySold: number
  totalSales: number
  checkCount: number
  checkTotalSales: number
  checkAverage: number
  percentOfChecks?: number
  checkItemCount: number
  avgCheckItemCount?: number
  avgCheckAdditionalItemCount?: number
}

type LocationData = INestedDataType & {
  checkCount: number
}

const useItem = ({
  startDate,
  endDate,
  locationGroupIds,
}: {
  startDate?: string
  endDate?: string
  locationGroupIds?: number[]
} = {}): IApiType => {
  const metricCodes = [
    'quantity_sold',
    'total_sales',
    'check_count',
    'check_total_sales',
    'check_average',
    'check_item_count',
  ]

  const useItemMetrics = buildItemMetricValuesHook({
    metrics: metricCodes,
    startDate,
    endDate,
    locationGroupIds,
  })

  const { data: itemData, loading: itemLoading } = useItemMetrics()

  const useLocationMetrics = buildMetricValuesHook({
    groupFilterType: 'corporate',
    metrics: { codes: ['check_count'] },
  })

  const { data: locationData, loading: locationLoading } = useLocationMetrics()

  const loading = itemLoading || locationLoading

  return {
    data: useMemo(() => {
      if (!itemData || !locationData) return null

      let totalCheckCount = 0
      ;(locationData as LocationData[]).forEach((n) => {
        totalCheckCount += typeof n.checkCount === 'number' ? n.checkCount : 0
      })

      return (itemData as ItemData[])
        .map((n) => {
          const percentOfChecks =
            totalCheckCount > 0 ? (n.checkCount / totalCheckCount) * 100 : 0

          const avgCheckItemCount =
            n.checkCount > 0 ? n.checkItemCount / n.checkCount : 0

          const avgCheckAdditionalItemCount =
            n.checkCount > 0 && n.checkItemCount > 1
              ? n.checkItemCount / n.checkCount - 1
              : 0

          return {
            ...n,
            percentOfChecks,
            avgCheckItemCount,
            avgCheckAdditionalItemCount,
          }
        })
        .sort((a, b) => b.checkCount - a.checkCount)
    }, [itemData, locationData]),
    loading,
  }
}

export default useItem
