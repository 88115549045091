import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useGroupFilter } from '../../groupFilter'
import { useVariables } from '../../variables'
import { IApiDataType } from '../types'

interface IDirectorInfo {
  id: number
  given_name: string
  family_name: string
  preferred_name: string
}

interface ILocationInfo {
  id: number
  code: string
  name: string
  director: IDirectorInfo
}
interface ICorporateLossPreventionLocations extends ILocationInfo {
  discount: number
  expected: number
  opportunityCost: number
  annualOpportunityCost: number
}

interface ICorporateLossPreventionSummaryDataType {
  type: string
  locations: ICorporateLossPreventionLocations[]
  annualOpportunityCost: number
  opportunityCost: number
  totalNetSales: number
  annualTotalNetSales: number
}

interface ILossPreventionSummaryType {
  corporateULossPreventionSummary: {
    nodes: ICorporateLossPreventionSummaryDataType[]
  }
}

const query = gql`
  query getCorporateLossPreventionSummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateULossPreventionSummary(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        type
        locations
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`

export const corporateExpoAiLossPreventionSummaryConfigs = {
  storeCount: 'string',
  discountingType: 'string',
  type: 'string',
  annualOpportunityCost: 'price',
} as const

const createCorporateExpoAiLossPreventionSummaryHook = (
  isLocationDetails: boolean,
) => {
  const useCorporateExpoAiLossPreventionSummaryData = () => {
    const { variables } = useVariables()
    const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
    const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
    const { groupFilter } = useGroupFilter()
    const locationGroupId = groupFilter?.ids[0] || 0
    const { brand } = useBrands()
    const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
    const { data, loading } = useQuery<ILossPreventionSummaryType>(query, {
      variables: {
        iLocationGroupId: locationGroupId,
        iBrandLocationGroupId: brandLocationGroupId,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !groupFilter,
    })

    return {
      data: useMemo((): IApiDataType => {
        if (isLocationDetails) {
          const summary = data?.corporateULossPreventionSummary?.nodes
          if (!summary) return null

          const dicountingType =
            variables?.aiSummary['uLossPrevention']?.clickedId
          if (!dicountingType) return null

          const locationData = summary.find((s) => s.type === dicountingType)

          return {
            source:
              locationData?.locations?.map((l) => {
                const groupInfo = groupFilter?.list?.find((g) => g.id == l.id)

                return {
                  groupInfo,
                  annualOpportunityCost: l.annualOpportunityCost,
                }
              }) || [],
          }
        } else {
          const summary = data?.corporateULossPreventionSummary?.nodes
          if (!summary) return null

          return {
            source: summary.map((n) => {
              return {
                ...n,
                storeCount: `${n.locations.length} stores`,
                discountingType: `${n.type} more than expected`,
              }
            }),
          }
        }
      }, [data, variables, groupFilter]),
      loading,
    }
  }

  return useCorporateExpoAiLossPreventionSummaryData
}

export const useCorporateExpoAiLossPreventionSummary =
  createCorporateExpoAiLossPreventionSummaryHook(false)
export const useCorporateExpoAiLossPreventionLocationDetails =
  createCorporateExpoAiLossPreventionSummaryHook(true)
