import { useMemo } from 'react'

import calc, { LOADING, calcAll } from '../../../utils/calc'
import { useVariables } from '../../../variables'
import { IApiType } from '../../types'
import usePAndLLoadMore from './usePAndLLoadMore'

export const bbbPAndLConfigs = {
  pAndLName: 'string',
  pAndLSales: 'price',
  pAndLSalesPercent: 'percent',
  pAndLBudget: 'price',
  pAndLBudgetPercent: 'percent',
  pAndLCompare: 'price',
  pAndLComparePercent: 'percent',
  pAndLYoySales: 'price',
  pAndLYoySalesPercent: 'percent',
  pAndLYoyCompare: 'price',
  pAndLYoyComparePercent: 'percent',
  pAndLYtdSales: 'price',
  pAndLYtdSalesPercent: 'percent',
  pAndLYtdBudget: 'price',
  pAndLYtdBudgetPercent: 'percent',
  pAndLYtdCompare: 'price',
  pAndLYtdComparePercent: 'percent',
  pAndLYytdSales: 'price',
  pAndLYytdSalesPercent: 'percent',
  pAndLYytdCompare: 'price',
  pAndLYytdComparePercent: 'percent',
} as const

const useBbbPAndL = (): IApiType => {
  const { variables } = useVariables()
  const { data, loadMore } = usePAndLLoadMore()

  return {
    data: useMemo(() => {
      const getBudgetCode = variables.bbbPnl?.getBudgetCode
      const getExternalFields = variables.bbbPnl?.getExternalFields
      const options = variables.bbbPnl?.metricCode.options

      if (!getBudgetCode || !getExternalFields || !options) return null

      const getValue = (id: string) =>
        data[id]?.isLoaded ? data[id]?.value : LOADING
      const getData = (dataId: string) => {
        const budgetId = getBudgetCode(dataId)

        const removedIds = getExternalFields(dataId)
          .filter((f) => f.type === 'minus')
          .map((f) => f.id)
        const removedBudgetIds = removedIds.map(getBudgetCode)
        const addedIds = getExternalFields(dataId)
          .filter((f) => f.type === 'add')
          .map((f) => f.id)
        const addedBudgetIds = addedIds.map(getBudgetCode)

        const pAndLSales = calc(
          getValue(dataId),
          '-',
          calc(
            calcAll(removedIds.map(getValue), '+'),
            '-',
            calcAll(addedIds.map(getValue), '+'),
          ),
        )
        const pAndLBudget = calc(
          getValue(budgetId),
          '-',
          calc(
            calcAll(removedBudgetIds.map(getValue), '+'),
            '-',
            calcAll(addedBudgetIds.map(getValue), '+'),
          ),
        )
        const pAndLCompare = calc(pAndLSales, '-', pAndLBudget)

        const pAndLYoySales = calc(
          getValue(`yoy_${dataId}`),
          '-',
          calc(
            calcAll(
              removedIds.map((removedId) => getValue(`yoy_${removedId}`)),
              '+',
            ),
            '-',
            calcAll(
              addedIds.map((addedId) => getValue(`yoy_${addedId}`)),
              '+',
            ),
          ),
        )
        const pAndLYoyCompare = calc(pAndLSales, '-', pAndLYoySales)

        const pAndLYtdSales = calc(
          getValue(`ytd_${dataId}`),
          '-',
          calc(
            calcAll(
              removedIds.map((removedId) => getValue(`ytd_${removedId}`)),
              '+',
            ),
            '-',
            calcAll(
              addedIds.map((addedId) => getValue(`ytd_${addedId}`)),
              '+',
            ),
          ),
        )
        const pAndLYtdBudget = calc(
          getValue(`ytd_${budgetId}`),
          '-',
          calc(
            calcAll(
              removedBudgetIds.map((removedBudgetId) =>
                getValue(`ytd_${removedBudgetId}`),
              ),
              '+',
            ),
            '-',
            calcAll(
              addedBudgetIds.map((addedBudgetId) =>
                getValue(`ytd_${addedBudgetId}`),
              ),
              '+',
            ),
          ),
        )
        const pAndLYtdCompare = calc(pAndLYtdSales, '-', pAndLYtdBudget)

        const pAndLYytdSales = calc(
          getValue(`yytd_${dataId}`),
          '-',
          calc(
            calcAll(
              removedIds.map((removedId) => getValue(`yytd_${removedId}`)),
              '+',
            ),
            '-',
            calcAll(
              addedIds.map((addedId) => getValue(`yytd_${addedId}`)),
              '+',
            ),
          ),
        )
        const pAndLYytdCompare = calc(pAndLYtdSales, '-', pAndLYytdSales)

        return {
          pAndLSales,
          pAndLBudget,
          pAndLCompare,
          pAndLYoySales,
          pAndLYoyCompare,
          pAndLYtdSales,
          pAndLYtdBudget,
          pAndLYtdCompare,
          pAndLYytdSales,
          pAndLYytdCompare,
        }
      }

      const totalData = getData('total_sales')

      return options.map((field) => {
        const {
          pAndLSales,
          pAndLBudget,
          pAndLCompare,
          pAndLYoySales,
          pAndLYoyCompare,
          pAndLYtdSales,
          pAndLYtdBudget,
          pAndLYtdCompare,
          pAndLYytdSales,
          pAndLYytdCompare,
        } = getData(field.dataId || field.id)

        return {
          ...field,
          pAndLName: field.displayName,
          pAndLSales,
          pAndLSalesPercent: calc(
            pAndLSales,
            'percentageOf',
            totalData.pAndLSales,
          ),
          pAndLBudget,
          pAndLBudgetPercent: calc(
            pAndLBudget,
            'percentageOf',
            totalData.pAndLBudget,
          ),
          pAndLCompare,
          pAndLComparePercent: calc(
            pAndLCompare,
            'percentageOf',
            totalData.pAndLCompare,
          ),
          pAndLYoySales,
          pAndLYoySalesPercent: calc(
            pAndLYoySales,
            'percentageOf',
            totalData.pAndLYoySales,
          ),
          pAndLYoyCompare,
          pAndLYoyComparePercent: calc(
            pAndLYoyCompare,
            'percentageOf',
            totalData.pAndLYoyCompare,
          ),
          pAndLYtdSales,
          pAndLYtdSalesPercent: calc(
            pAndLYtdSales,
            'percentageOf',
            totalData.pAndLYtdSales,
          ),
          pAndLYtdBudget,
          pAndLYtdBudgetPercent: calc(
            pAndLYtdBudget,
            'percentageOf',
            totalData.pAndLYtdBudget,
          ),
          pAndLYtdCompare,
          pAndLYtdComparePercent: calc(
            pAndLYtdCompare,
            'percentageOf',
            totalData.pAndLYtdCompare,
          ),
          pAndLYytdSales,
          pAndLYytdSalesPercent: calc(
            pAndLYytdSales,
            'percentageOf',
            totalData.pAndLYytdSales,
          ),
          pAndLYytdCompare,
          pAndLYytdComparePercent: calc(
            pAndLYytdCompare,
            'percentageOf',
            totalData.pAndLYytdCompare,
          ),
        }
      })
    }, [data, variables.bbbPnl]),
    loading: false,
    loadMore,
  }
}

export default useBbbPAndL
