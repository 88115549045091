import { IApiDataType } from '../types'
import { api as commonApi } from './common'
import { api as fwWingstopApi } from './fw_wingstop'

export type IApiKeyType = keyof typeof api

export const api = {
  ...commonApi,
  ...fwWingstopApi,
}

const useApi = (apiName: IApiKeyType): IApiDataType => api[apiName]()

export default useApi
