import { gql, useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'
import { getUser } from 'pared/utils/user'

import { useDateFilter } from '../../dateFilter'
import { CONSTANTS } from '../../variables/hooks/bbb/useBbbConstants'
import { useGroupFilter } from '../GroupFilterProvider'
import { IApiDataType } from '../types'

interface IQueryDataType {
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    location_group_ids: [number]
  }
  iStartDate?: string
  iEndDate?: string
}

const query = gql`
  query FetchLocations($iFilter: JSON!, $iStartDate: Date!, $iEndDate: Date!) {
    listLocationDetails: listLocationDetailsV2(
      iFilter: $iFilter
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        id
        code
        name
      }
    }
  }
`

const useLocationFilterQuery = (locationGroupId: number) => {
  const { startDate, endDate } = useDateFilter()
  const location = useLocation()
  const history = useHistory()
  const userInfo = getUser()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const store = useMemo(
    () => (hasGroupBy ? null : groupFilter?.ids[0]),
    [groupFilter, hasGroupBy],
  )
  const prevStore = usePrevious(store)
  const {
    data: newData,
    loading,
    previousData,
  } = useQuery<IQueryDataType, IQueryVariablesType>(query, {
    variables: {
      iFilter: {
        location_group_ids: [locationGroupId],
      },
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !startDate || !endDate,
  })
  const data = newData || previousData

  useEffect(() => {
    if (store && store !== prevStore) {
      const queryObject = new URLSearchParams(location.search)

      queryObject.set('store', store.toString())
      history.push(`${location.pathname}?${queryObject.toString()}`)
    }
  }, [location, history, store, prevStore])

  return {
    data: useMemo((): IApiDataType => {
      const queryObject = new URLSearchParams(location.search)
      const store =
        queryObject.get('store') || userInfo.defaultLocationId?.toString()

      if (!data) {
        if (!store) return null

        return {
          defaultValue: [store],
          values: [
            {
              id: store,
              parentId: 'root',
              ids: [parseInt(store)],
              label: '',
            },
          ],
        }
      }

      const values = data.listLocationDetails.nodes.map((l) => {
        return {
          id: l.id.toString(),
          parentId: 'root',
          ids: [l.id],
          label: `${l.code} - ${l.name}`,
        }
      })
      const defaultValue = values.find((v) => (store ? v.id === store : true))

      return {
        values,
        defaultValue: [defaultValue?.id || values[0]?.id],
      }
    }, [location, data, userInfo]),
    loading,
  }
}

export const useLocationFilter = () => {
  const { brand } = useBrands()

  return useLocationFilterQuery(BRAND_LOCATION_GROUP_ID[brand])
}

export const useBbbCompanyLocationFilter = () =>
  // FIXME: should get the constants from the variables
  useLocationFilterQuery(CONSTANTS.COMPANY_STORE_LOCATION_GROUP_ID)
