import useCorporateGuest from './useCorporateGuest'
import useLocationGuest from './useLocationGuest'
import useLocationLossPrevention from './useLocationLossPrevention'
import useLocationWingyou from './useLocationWingyou'

export type IAiSummaryType =
  | 'fwWingstopLossPrevention'
  | 'fwWingstopLocationGuest'
  | 'fwWingstopLocationWingyou'
  | 'fwWingstopCorporateGuest'

export const hooks = [
  ['fwWingstopLossPrevention', useLocationLossPrevention],
  ['fwWingstopLocationGuest', useLocationGuest],
  ['fwWingstopLocationWingyou', useLocationWingyou],
  ['fwWingstopCorporateGuest', useCorporateGuest],
] as const
