import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { toPercentString } from 'pared/utils/number'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'
    value: number
  }
>

type IDataType<T extends string = 'listLocationMetricValues'> = Record<
  T,
  {
    nodes: Record<'metricSummaryData', IMetricDataType>[]
  }
>

const query = gql`
  query ($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        metricSummaryData
      }
    }
  }
`

export const locationDayPartPieConfigs = {
  name: 'string',
  netSales: 'price',
} as const

const useLocationDayPartPie = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()

  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_ids: groupFilter?.ids,
        metric_groups: ['Expo Module - Day Part Sales'],
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const metricData = data?.listLocationMetricValues.nodes.find(
        (n) => n.metricSummaryData,
      )

      if (!metricData) return null

      const totalSales = Object.values(metricData.metricSummaryData).reduce(
        (acc: number, value) =>
          acc + (value.unit === 'CENT' ? value.value : value.value * 100),
        0,
      ) as number

      return Object.entries(metricData.metricSummaryData).map(
        ([key, value]) => {
          const name = !key.includes('day_part_sales')
            ? value.name
            : value.name.replace('Day Part Sales - ', '')

          const netSales =
            value.unit === 'CENT' ? value.value : value.value * 100

          return {
            name,
            nameWithPercentage: `${toPercentString(
              (netSales / totalSales) * 100,
            )} - ${name}`,
            netSales,
          }
        },
      )
    }, [data]),
    loading,
  }
}

export default useLocationDayPartPie
