import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useGroupFilter } from '../../groupFilter'
import { useVariables } from '../../variables'
import { IApiDataType } from '../types'

interface IDirectorInfo {
  id: number
  given_name: string
  family_name: string
  preferred_name: string
}

interface ILocationInfo {
  id: number
  code: string
  name: string
  director: IDirectorInfo
}
interface ICorporateLaborLocations extends ILocationInfo {
  costHours: number
  expectHours: number
  sales: number
  expectSales: number
  avgHourlyPayRate: number
  opportunityCost: number
  annualOpportunityCost: number
}

interface ICorporateLaborSummaryNodeType {
  hour: number
  locations: ICorporateLaborLocations[]
  locationNum: number
  annualOpportunityCost: number
  opportunityCost: number
  totalNetSales: number
  annualTotalNetSales: number
}

interface ILaborSummaryType {
  corporateULaborSummary: {
    nodes: ICorporateLaborSummaryNodeType[]
  }
}

const query = gql`
  query getCorporateLaborSummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateULaborSummary(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        hour
        locations
        locationNum
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`

export const corporateExpoAiLaborSummaryConfigs = {
  storeCount: 'string',
  hour: 'number',
  timeOfDay: 'string',
  annualOpportunityCost: 'price',
} as const

const convertHourToString = (hour: number) => {
  if (hour === 0) {
    return '12 AM'
  }

  if (hour === 12) {
    return '12 PM'
  }

  if (hour < 12) {
    return `${hour} AM`
  }

  if (hour > 12) {
    return `${hour - 12} PM`
  }

  return ''
}

const createCorporateExpoAiLaborSummaryHook = (isLocationDetails: boolean) => {
  const useCorporateExpoAiLaborSummaryData = () => {
    const { variables } = useVariables()
    const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
    const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
    const { groupFilter } = useGroupFilter()
    const locationGroupId = groupFilter?.ids[0] || 0
    const { brand } = useBrands()
    const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
    const { data, loading } = useQuery<ILaborSummaryType>(query, {
      variables: {
        iLocationGroupId: locationGroupId,
        iBrandLocationGroupId: brandLocationGroupId,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !groupFilter,
    })

    return {
      data: useMemo((): IApiDataType => {
        if (isLocationDetails) {
          const summary = data?.corporateULaborSummary?.nodes
          if (!summary) return null

          const timeOfDay = variables?.aiSummary['labor']?.clickedId
          if (!timeOfDay) return null

          const locationData = summary.find(
            (s) => convertHourToString(s.hour) === timeOfDay,
          )

          return {
            source:
              locationData?.locations?.map((l) => {
                const groupInfo = groupFilter?.list?.find((g) => g.id == l.id)

                return {
                  groupInfo,
                  annualOpportunityCost: l.annualOpportunityCost,
                }
              }) || [],
          }
        } else {
          const summary = data?.corporateULaborSummary?.nodes
          if (!summary) return null

          return {
            source: summary.map((n) => {
              return {
                ...n,
                storeCount: `${n.locations.length} stores`,
                timeOfDay: convertHourToString(n.hour),
              }
            }),
          }
        }
      }, [data, variables, groupFilter]),
      loading,
    }
  }

  return useCorporateExpoAiLaborSummaryData
}

export const useCorporateExpoAiLaborSummary =
  createCorporateExpoAiLaborSummaryHook(false)
export const useCorporateExpoAiLaborLocationDetails =
  createCorporateExpoAiLaborSummaryHook(true)
