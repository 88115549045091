import { useVariables } from '../../../variables'
import buildMetricTrendsHook from '../utils/buildMetricTrendsHook'

export const trendLocationGroupDiscountConfigs = {
  date: 'string',
  discountCompsTotal: 'price',
} as const

const useCommonMetricValuesHook = buildMetricTrendsHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['discount_comps_total'],
  },
})

const useTrendLocationGroupDiscount = () => {
  const { variables } = useVariables()
  const dateRange = variables.lastTimeRange?.value[0][0] || ''
  const { data: commonData, loading } = useCommonMetricValuesHook(dateRange)

  return {
    data: commonData,
    loading,
  }
}

export default useTrendLocationGroupDiscount
