import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { corporateGroupTableConfigs } from '../../../variables'
import { IApiDataType } from '../../types'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const burgerworksSystemwideOverviewConfigs = {
  ...corporateGroupTableConfigs,
  netSales: 'price',
  laborTotalCostPercent: 'percent',
  averageWeeklySales: 'price',
} as const

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'
    value: number
  }
>

type IDataType<
  T extends string =
    | 'listLocationGroupAverageWeeklySales'
    | 'listLocationAverageWeeklySales',
> = Record<
  T,
  {
    nodes: ((T extends 'listLocationGroupAverageWeeklySales'
      ? {
          locationGroupId: number
        }
      : {
          locationId: number
        }) &
      Record<'metricData', IMetricDataType>)[]
  }
>

const query = gql`
  query listAverageWeeklySales(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationGroupAverageWeeklySales(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        metricData
      }
    }

    listLocationAverageWeeklySales(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        metricData
      }
    }
  }
`

const useCommonBurgerworksSystemwideOverview = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['net_sales', 'labor_total_cost_percent'],
  },
})

const useBurgerworksSystemwideOverview = () => {
  const { data: commonData, loading: commonLoading } =
    useCommonBurgerworksSystemwideOverview()
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        metrics: ['net_sales'],
      },
      hasGroupBy: hasGroupBy,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return commonData

      const averageWeeklySalesNodes = (
        data?.listLocationGroupAverageWeeklySales ||
        data?.listLocationAverageWeeklySales
      )?.nodes

      const newData = commonData.map((n) => {
        const id = n.id
        const averageWeeklySalesN = averageWeeklySalesNodes?.find((an) =>
          'locationId' in an
            ? an.locationId.toString() === id
            : an.locationGroupId.toString() === id,
        )

        const averageWeeklySales =
          averageWeeklySalesN?.metricData.averageWeeklySales.value

        return {
          ...n,
          averageWeeklySales: averageWeeklySales
            ? averageWeeklySales * 100
            : null,
        }
      })

      return newData
    }, [commonData, data]),
    loading: commonLoading || loading,
  }
}

export default useBurgerworksSystemwideOverview
