// FIXME: should add download-csv-v2
import { useMemo } from 'react'

import { useGroupFilter } from '../../groupFilter'
import useBbbOpsDashboardTable, {
  bbbOpsDashboardConfigs as bbbOpsDashboardTableConfigs,
} from '../../tableV2/hooks/bbb/useOpsDashboard'

export const bbbOpsDashboardConfigs = {
  ...bbbOpsDashboardTableConfigs,
}

const useBbbOpsDashboard = () => {
  const { data, loading } = useBbbOpsDashboardTable()
  const { groupFilter } = useGroupFilter()

  return {
    data: useMemo((): {
      source: Record<string, unknown>[]
      summary: Record<string, unknown> | null
    } | null => {
      if (!data) return null

      return {
        source: data,
        summary: null,
      }
    }, [data, groupFilter]),
    loading,
  }
}

export default useBbbOpsDashboard
