import { corporateGroupTableConfigs } from '../../../../variables'
import buildMetricValuesHook from '../../utils/buildMetricValuesHook'

export const burgerworksListLocationGroupDeliveryDataConfigs = {
  ...corporateGroupTableConfigs,
  deliverySumSubtotalDoordash: 'price',
  deliveryTotalOrderCountDoordash: 'number',
  deliverySumErrorChargesDoordash: 'price',
  deliveryAvgDasherWaitTimeDoordash: 'number',
  deliveryOrderWithAnyIssuePercentDoordash: 'percent',
  deliveryAvgCustomerReviewScoreDoordash: 'number',
} as const

const useBurgerworkListLocationGroupDeliveryData = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'delivery_sum_subtotal_doordash',
      'delivery_total_order_count_doordash',
      'delivery_sum_error_charges_doordash',
      'delivery_avg_dasher_wait_time_doordash',
      'delivery_order_with_any_issue_percent_doordash',
      'delivery_avg_customer_review_score_doordash',
    ],
  },
})

export default useBurgerworkListLocationGroupDeliveryData
