import { useVariables } from '../../../variables'
import buildMetricTrendsHook from '../utils/buildMetricTrendsHook'

export const trendLocationGroupDeliveryConfigs = {
  date: 'string',
  deliveryTotalOrderCountDoordash: 'number',
  deliveryOrderWithAnyIssuePercentDoordash: 'percent',
  deliveryInaccurateOrderPercentDoordash: 'percent',
  deliveryCancelledOrderPercentDoordash: 'percent',
  deliveryDelayedOrderPercentDoordash: 'percent',
} as const

const useCommonMetricValuesHook = buildMetricTrendsHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'delivery_total_order_count_doordash',
      'delivery_order_with_any_issue_percent_doordash',
      'delivery_inaccurate_order_percent_doordash',
      'delivery_cancelled_order_percent_doordash',
      'delivery_delayed_order_percent_doordash',
    ],
  },
})

const useTrendLocationGroupDelivery = () => {
  const { variables } = useVariables()
  const dateRange = variables.lastTimeRange?.value[0][0] || ''
  return useCommonMetricValuesHook(dateRange)
}

export default useTrendLocationGroupDelivery
