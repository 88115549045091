import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'

import { useDateFilter } from '../../../dateFilter'
import { IApiDataType } from '../../types'

type IUnitType = 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'

type IMetricDataType = Record<
  string,
  {
    unit: IUnitType
    value: number
  }
>

interface IDeliveryLocationVsSystemwideNodeType {
  locationCode: string
  locationName: string
  locationCorrectOrderPercent: number
  systemwideCorrectOrderPercent: number
  locationInaccurateOrderPercent: number
  systemwideInaccurateOrderPercent: number
  locationCancelledOrderPercent: number
  systemwideCancelledOrderPercent: number
  locationDelayedOrderPercent: number
  systemwideDelayedOrderPercent: number
}

interface IDeliveryEmployeeMetricValuesNodeType {
  metricData: IMetricDataType
  endDate: string
  metricLocationId: string
  employeeId: string
  employeeNumber: string
  employeeName: string
}

interface IDeliveryDayTrendNodeType {
  locationId: number
  dayOfWeek: number
  dayOfWeekName: string
  allOrderWithAnyIssuePercent: number
  allInaccurateOrderPercent: number
  allCancelledOrderPercent: number
  allDelayedOrderPercent: number
}

interface IDeliverySummaryType {
  getULocationVsSystemwideDeliveryKpi: {
    nodes: IDeliveryLocationVsSystemwideNodeType[]
  }
  listEmployeeMetricValues: {
    nodes: IDeliveryEmployeeMetricValuesNodeType[]
  }
  listLocationUDeliveryDayTrends: {
    nodes: IDeliveryDayTrendNodeType[]
  }
}

const query = gql`
  query GetLocationDeliverySummary(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    getULocationVsSystemwideDeliveryKpi(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locationCode
        locationName
        locationCorrectOrderPercent
        systemwideCorrectOrderPercent
        locationInaccurateOrderPercent
        systemwideInaccurateOrderPercent
        locationCancelledOrderPercent
        systemwideCancelledOrderPercent
        locationDelayedOrderPercent
        systemwideDelayedOrderPercent
      }
    }

    listEmployeeMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        endDate
        metricLocationId
        employeeLocationId
        employeeId
        employeeName
        employeeNumber
        metricData
      }
    }

    listLocationUDeliveryDayTrends(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locationId
        dayOfWeek
        dayOfWeekName
        allOrderWithAnyIssuePercent
        allInaccurateOrderPercent
        allCancelledOrderPercent
        allDelayedOrderPercent
      }
    }
  }
`

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    switch (value.unit) {
      case 'PERCENTAGE':
      case 'DOLLAR':
        return {
          ...result,
          [_.camelCase(key)]: value.value * 100,
        }
      default:
        return {
          ...result,
          [_.camelCase(key)]: value.value,
        }
    }
  }, {})

const useLocationDeliveryAiSummary = (): IApiDataType => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<IDeliverySummaryType>(query, {
    variables: {
      iLocationId: groupFilter?.ids[0] || 0,
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_ids: [groupFilter?.ids[0] || 0],
        metric_groups: ['Expo Module - Delivery Data'],
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const summary = data?.getULocationVsSystemwideDeliveryKpi.nodes[0]
      const summaryMessage = (() => {
        if (!summary) return 'No data available for the selected time period.'

        let accuracy = false
        let cancellations = false
        let lateness = false
        const {
          locationName,
          locationInaccurateOrderPercent,
          systemwideInaccurateOrderPercent,
          locationCancelledOrderPercent,
          systemwideCancelledOrderPercent,
          locationDelayedOrderPercent,
          systemwideDelayedOrderPercent,
        } = summary

        if (locationInaccurateOrderPercent > systemwideInaccurateOrderPercent) {
          accuracy = true
        }

        if (locationCancelledOrderPercent > systemwideCancelledOrderPercent) {
          cancellations = true
        }

        if (locationDelayedOrderPercent > systemwideDelayedOrderPercent) {
          lateness = true
        }

        if (accuracy && cancellations && lateness) {
          return `${locationName} is performing worse than the company on Accuracy, Cancellations, and Lateness.`
        } else if (accuracy && cancellations) {
          return `${locationName} is performing worse than the company on Accuracy and Cancellations.`
        } else if (accuracy && lateness) {
          return `${locationName} is performing worse than the company on Accuracy and Lateness.`
        } else if (cancellations && lateness) {
          return `${locationName} is performing worse than the company on Cancellations and Lateness.`
        } else if (accuracy) {
          return `Not bad! ${locationName} should focus on Accuracy which is below company averages.`
        } else if (cancellations) {
          return `Not bad! ${locationName} should focus on Cancellations which is below company averages.`
        } else if (lateness) {
          return `Not bad! ${locationName} should focus on Lateness which is below company averages.`
        } else {
          return `Great job! ${locationName} is performing better than the company on Accuracy, Cancellation and Lateness issues for the time period selected!`
        }
      })()

      const deliveryEmployeeData =
        data?.listEmployeeMetricValues?.nodes?.map((n) => ({
          ...n,
          ...format(n.metricData),
        })) || []
      const deliveryDayTrendData = data?.listLocationUDeliveryDayTrends.nodes
      const improvementMessage = (() => {
        if (!deliveryEmployeeData && !deliveryDayTrendData) return null

        const worstDays = deliveryDayTrendData?.reduce(
          (max, record) => {
            const accuracy =
              record.allInaccurateOrderPercent > max.accuracy.max
                ? {
                    max: record.allInaccurateOrderPercent,
                    weekDayName: record.dayOfWeekName,
                  }
                : max.accuracy
            const cancellations =
              record.allCancelledOrderPercent > max.cancellations.max
                ? {
                    max: record.allCancelledOrderPercent,
                    weekDayName: record.dayOfWeekName,
                  }
                : max.cancellations
            const lateness =
              record.allDelayedOrderPercent > max.lateness.max
                ? {
                    max: record.allDelayedOrderPercent,
                    weekDayName: record.dayOfWeekName,
                  }
                : max.lateness

            return {
              accuracy,
              cancellations,
              lateness,
            }
          },
          {
            accuracy: {
              max: 0,
              weekDayName: '',
            },
            cancellations: {
              max: 0,
              weekDayName: '',
            },
            lateness: {
              max: 0,
              weekDayName: '',
            },
          },
        )

        const worstAccuracyEmployees = deliveryEmployeeData
          ?.sort(
            (a, b) =>
              b?.deliveryInaccurateOrderPercentDoordash -
              a?.deliveryInaccurateOrderPercentDoordash,
          )
          ?.slice(0, 2)
          ?.map((d) => d.employeeName)
        const worstCancellationsEmployees = deliveryEmployeeData
          ?.sort(
            (a, b) =>
              b?.deliveryCancelledOrderPercentDoordash -
              a?.deliveryCancelledOrderPercentDoordash,
          )
          ?.slice(0, 2)
          ?.map((d) => d.employeeName)
        const worstLatenessEmployees = deliveryEmployeeData
          ?.sort(
            (a, b) =>
              b?.deliveryDelayedOrderPercentDoordash -
              a?.deliveryDelayedOrderPercentDoordash,
          )
          ?.slice(0, 2)
          ?.map((d) => d.employeeName)

        return `
          <div>
            <p>
              <strong>Accuracy</strong>
            </p>
            <div>
              <li><i>${
                worstDays?.accuracy.weekDayName
              }</i> is the worst day.</li>
              ${
                worstAccuracyEmployees.length > 0
                  ? `<li>
                  <strong>${worstAccuracyEmployees[0]}</strong>
                  ${
                    worstAccuracyEmployees.length === 2
                      ? `<span>
                      ${' '}
                      and <strong>${worstAccuracyEmployees[1]}</strong> are
                    </span>`
                      : `<span> is</span>`
                  }
                  <span> on shift when Accuracy issues are highest.</span>
                </li>`
                  : null
              }
            </div>
          </div>
          <div>
            <p>
              <strong>Cancellations</strong>
            </p>
            <div>
              <li><i>${
                worstDays?.cancellations.weekDayName
              }</i> is the worst day.</li>
              ${
                worstCancellationsEmployees.length > 0
                  ? `<li>
                  <strong>${worstCancellationsEmployees[0]}</strong>
                  ${
                    worstCancellationsEmployees.length === 2
                      ? `<span>
                      ${' '}
                      and <strong>${worstCancellationsEmployees[1]}</strong> are
                    </span>`
                      : `<span> is</span>`
                  }
                  <span> on shift when Cancellation issues are highest.</span>
                </li>`
                  : null
              }
            </div>
          </div>
          <div>
            <p>
              <strong>Lateness</strong>
            </p>
            <div>
              <li><i>${
                worstDays?.lateness.weekDayName
              }</i> is the worst day.</li>
              ${
                worstLatenessEmployees.length > 0
                  ? `<li>
                  <strong>${worstLatenessEmployees[0]}</strong>
                  ${
                    worstLatenessEmployees.length === 2
                      ? `<span>
                      ${' '}
                      and <strong>${worstLatenessEmployees[1]}</strong> are
                    </span>`
                      : `<span> is</span>`
                  }
                  <span> on shift when Cancellation issues are highest.</span>
                </li>`
                  : null
              }
            </div>
          </div>
          `
      })()

      return {
        conclusion: summaryMessage,
        insight: improvementMessage,
      }
    }, [data]),
    loading,
  }
}

export default useLocationDeliveryAiSummary
