import { IConfigsType } from '../../types'

const bibibopFlash: IConfigsType = {
  bibibop: {
    '/:brand/flash': {
      variables: ['corporateGroup'],
      groupFilter: {
        api: 'bbbCorporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
        ],
      },
      flash: {
        type: 'title',
        title: 'Systemwide Flash Report',
      },
      downloadFlashTable: {
        title: 'DAILY FLASH',
        type: 'download-csv',
        api: 'bbbFlashV2',
        fileName: 'getexpo-flash',
        message: 'Download Daily Flash as CSV',
        fields: [
          'groupInfo',
          {
            key: 'totalSales',
            title: 'Current Sales',
          },
          {
            key: 'pyNetSales',
            title: 'PY Sales',
          },
          {
            key: 'pyNetSalesPercent',
            title: 'PY %',
          },
          {
            key: 'totalSalesBudget',
            title: 'Budget Sales',
          },
          {
            key: 'totalSalesBudgetVariance',
            title: 'Budget %',
          },
          {
            key: 'onlinePercent',
            title: 'Online %',
          },
          {
            key: 'deliveryPercent',
            title: 'Delivery %',
          },
          {
            key: 'catering',
            title: 'Catering $',
          },
          {
            key: 'checkCount',
            title: 'Orders',
          },
          {
            key: 'yoyCheckCountGrowth',
            title: 'Orders PY %',
          },
          {
            key: 'checkAverage',
            title: 'Check Avg',
          },
          {
            key: 'yoyCheckAverage',
            title: 'Check Avg PY',
          },
          {
            key: 'checkAverageSssPercent',
            title: 'Check Avg %',
          },
          {
            key: 'discount',
            title: 'Discount $',
          },
          {
            key: 'discountPercent',
            title: 'Discount %',
          },
          {
            key: 'employeeDiscount',
            title: 'Employee Discount $',
          },
          {
            key: 'voids',
            title: 'Void $',
          },
          {
            key: 'cashOverOrShort',
            title: 'Cash Over/Short',
          },
          {
            key: 'hourlyLaborHours',
            title: 'Labor Hours',
          },
          {
            key: 'overtimeHours',
            title: 'Overtime Hours',
          },
          {
            key: 'salesPerLaborHour',
            title: 'Productivity',
          },
          {
            key: 'laborCostPercentage',
            title: 'Labor %',
          },
          {
            key: 'cookiesPer100Orders',
            title: 'Cookies Per 100 Orders',
          },
          {
            key: 'avocadoPer100Orders',
            title: 'Avocado Per 100 Orders',
          },
          {
            key: 'customerRating',
            title: 'Customer Rating',
          },
          {
            key: 'numberOfComplaints',
            title: 'Number of Complaints',
          },
          {
            key: 'bonus',
            title: 'Bonus Payouts',
          },
        ],
      },
      flashTable: {
        type: 'table-v2',
        api: 'bbbFlash',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        rowBackground: '<%- index % 2 ? colors.Porcelain : "" %>',
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'totalSales',
            title: 'Current Sales',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'pyNetSales',
            title: 'PY Sales',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'pyNetSalesPercent',
            title: 'PY %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalSalesBudget',
            title: 'Budget Sales',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalSalesBudgetVariance',
            title: 'Budget %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'onlinePercent',
            title: 'Online %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'deliveryPercent',
            title: 'Delivery %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'catering',
            title: 'Catering $',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'checkCount',
            title: 'Orders',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'yoyCheckCountGrowth',
            title: 'Orders PY %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'checkAverage',
            title: 'Check Avg',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'yoyCheckAverage',
            title: 'Check Avg PY',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'checkAverageSssPercent',
            title: 'Check Avg %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'discount',
            title: 'Discount $',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'discountPercent',
            title: 'Discount %',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'employeeDiscount',
            title: 'Employee Discount $',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'voids',
            title: 'Void $',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'cashOverOrShort',
            title: 'Cash Over/Short',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'hourlyLaborHours',
            title: 'Labor Hours',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'overtimeHours',
            title: 'Overtime Hours',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 1,
          },
          {
            key: 'salesPerLaborHour',
            title: 'Productivity',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'laborCostPercentage',
            title: 'Labor %',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'cookiesPer100Orders',
            title: 'Cookies Per 100 Orders',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'avocadoPer100Orders',
            title: 'Avocado Per 100 Orders',
            width: '120px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'customerRating',
            title: 'Customer Rating',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'numberOfComplaints',
            title: 'Number of Complaints',
            width: '120px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'bonus',
            title: 'Bonus Payouts',
            width: '120px',
            align: 'center',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default bibibopFlash
