import Pagination from '@mui/material/Pagination'
import {
  RowPinningState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'
import COLORS from 'pared/constants/colors'

import useApi, { IApiKeyType, configs } from './hooks/useApi'
import useColumns, { IColumnsOptionsType } from './hooks/useColumns'
import useData, { IDataType } from './hooks/useData'
import useTableState, { ITableStateOptionType } from './hooks/useTableState'

export interface IPropsType<K extends IApiKeyType = IApiKeyType>
  extends ITableStateOptionType<K> {
  type: 'table-v2'
  api: K
  columns: IColumnsOptionsType<K>
  pagination?: boolean | { pageSize: number }
  rowPinning?: RowPinningState
  rowBackground?: string
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const StyledTable = styled.table`
  border-collapse: collapse;

  tbody {
    counter-reset: rowNumber;
  }

  tbody tr {
    counter-increment: rowNumber;
  }

  tbody tr td.rowIndex::before {
    content: counter(rowNumber);
  }
`

const StyledPagination = styled(Pagination)`
  button {
    font-family: Lexend-Regular;

    &.Mui-selected,
    &:hover {
      background: initial;
      border: 1px black solid;
    }
  }
`

const StyledTr = styled.tr<{
  background?: string
}>`
  ${({ background }) => (!background ? '' : `background: ${background};`)}
`

const findIndex = (
  data: IDataType[],
  [id, ...subIds]: string[],
  prefix: number[] = [],
): -1 | number[] => {
  const index = data.findIndex((d) => d.id === id)

  if (index === -1) return -1

  if (subIds.length > 0)
    return findIndex(data[index].subRows || [], subIds, [...prefix, index])

  return [...prefix, index]
}

const Table = ({
  api,
  columns: columnsSource,
  sorting,
  expanded,
  pagination,
  rowPinning: rowPinningSource = {
    bottom: ['summary'],
  },
  rowBackground,
}: IPropsType) => {
  const { data: dataSource, loading, loadMore, refetch } = useApi(api)
  const data = useData(dataSource)
  const { initialState, state, ...tableConfig } = useTableState(
    data,
    Boolean(!loading && dataSource),
    {
      sorting,
      expanded,
    },
  )
  const { tableRef, columns } = useColumns(
    configs[api],
    columnsSource,
    loadMore,
    refetch,
  )
  const rowPinning = useMemo(() => {
    const ids = dataSource?.map((d) => d.id)

    return {
      top: rowPinningSource.top?.filter((r) => ids?.includes(r)),
      bottom: rowPinningSource.bottom?.filter((r) => ids?.includes(r)),
    }
  }, [rowPinningSource, dataSource])
  const table = useReactTable({
    ...tableConfig,
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: !pagination ? undefined : getPaginationRowModel(),
    getSubRows: (row) => row.subRows,
    getRowId: (row) => row.id as string,
    initialState: {
      ...initialState,
      pagination: !pagination
        ? undefined
        : {
            pageIndex: 0,
            pageSize: pagination === true ? 20 : pagination.pageSize,
          },
    },
    state: {
      ...state,
      rowPinning,
    },
    autoResetPageIndex: false,
  })
  const { pageSize, pageIndex } = table.getState().pagination
  const paginationCount = Math.ceil(
    table.getFilteredRowModel().rows.length / pageSize,
  )

  return (
    <>
      <Spin spinning={loading}>
        <StyledTable ref={tableRef}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <React.Fragment key={header.id}>
                    {header.isPlaceholder ? (
                      <th colSpan={header.colSpan} />
                    ) : (
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )
                    )}
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getTopRows().map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <React.Fragment key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </React.Fragment>
                ))}
              </tr>
            ))}
            {table.getCenterRows().map((row, index) => (
              <StyledTr
                key={row.id}
                background={_.template(rowBackground)({
                  id: row.id,
                  index,
                  colors: COLORS,
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <React.Fragment key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </React.Fragment>
                ))}
              </StyledTr>
            ))}
            {table.getBottomRows().map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <React.Fragment key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </Spin>

      {!pagination || paginationCount <= 1 ? null : (
        <StyledPagination
          count={paginationCount}
          page={pageIndex + 1}
          onChange={(_, page) => {
            table.setPageIndex(page - 1)
          }}
          variant="text"
          shape="rounded"
        />
      )}
    </>
  )
}

export default Table
