import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from 'pared/Routes/renderer/dateFilter'

import { corporateGroupTableConfigs, useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT' | 'SECONDS' | 'SCORE'
    value: number
  }
>

type IDataType<
  T extends string =
    | 'listLocationGroupMetricValues'
    | 'listLocationMetricValues',
> = Record<
  T,
  {
    nodes: ((T extends 'listLocationGroupMetricValues'
      ? {
          locationGroupId: number
        }
      : {
          locationId: number
        }) &
      Record<'metricData', IMetricDataType>)[]
  }
>

type IMetricType = string | { key: string; type: 'yoy' }

const query = gql`
  query rankings(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        metricData
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        metricData
      }
    }
  }
`

export const rankingsConfigs = {
  '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>':
    corporateGroupTableConfigs['<%- JSON(corporateGroup?.tableColumns) %>'],
  rowIndex: 'string',
  price0: 'price',
  price1: 'price',
  price2: 'price',
  price3: 'price',
  price4: 'price',
  price5: 'price',
  percent0: 'percent',
  number0: 'number',
  number1: 'number',
} as const

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    switch (value.unit) {
      case 'PERCENTAGE':
      case 'DOLLAR':
        return {
          ...result,
          [_.camelCase(key)]: value.value * 100,
        }
      case 'SECONDS':
        return {
          ...result,
          [_.camelCase(key)]: value.value / 60.0,
        }
      default:
        return {
          ...result,
          [_.camelCase(key)]: value.value,
        }
    }
  }, {})

const useMetricQuery = (metrics: string[], useYoy?: boolean) => {
  const { variables } = useVariables()
  const { startDate, endDate } = useDateFilter()

  return useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids:
          variables.corporateGroup &&
          'locationGroupIds' in variables.corporateGroup
            ? variables.corporateGroup.locationGroupIds
            : undefined,
        use_yoy: useYoy,
        metrics,
      },
      hasGroupBy: variables.corporateGroup?.type !== 'listLocationGroup',
    },
    skip:
      metrics.length === 0 ||
      !startDate ||
      !endDate ||
      !variables.corporateGroup ||
      !variables.rankings?.info,
  })
}

const strToNum = (value: string) => {
  try {
    const result = parseFloat(value)

    return isNaN(result) ? null : result
  } catch (e) {
    return null
  }
}

const getNumberFromData = <Data>(value: Data): number => {
  if (typeof value === 'number') return value

  if (typeof value === 'string') return strToNum(value) || 0

  return 0
}

const buildRankingsHook = (options?: {
  metriceCodeMapping?: Record<string, IMetricType[]>
  handler?: (data: Record<string, unknown>) => Record<string, unknown>
}) => {
  const metriceCodeMapping = options?.metriceCodeMapping || {}
  const handler = options?.handler || ((data) => data)

  const useRankings = () => {
    const { variables } = useVariables()
    const metrics = useMemo(
      () =>
        (variables.rankings?.info?.fields || [])
          .map(
            (f) =>
              metriceCodeMapping[f.metricCode] || _.snakeCase(f.metricCode),
          )
          .flat(),
      [variables],
    )
    const { data, loading } = useMetricQuery(
      (
        metrics
          .map((m) => (typeof m === 'string' ? m : null))
          .filter(Boolean) as string[]
      ).reduce(
        (result, m) => (result.includes(m) ? result : [...result, m]),
        [] as string[],
      ),
    )
    const { data: yoyData, loading: yoyLoading } = useMetricQuery(
      (
        metrics
          ?.map((m) =>
            typeof m !== 'string' && m.type === 'yoy' ? m.key : null,
          )
          ?.filter(Boolean) as string[]
      ).reduce(
        (result, m) => (result.includes(m) ? result : [...result, m]),
        [] as string[],
      ),
      true,
    )

    return {
      data: useMemo((): IApiDataType => {
        const corporateDetails = (() => {
          if (!variables.corporateGroup) return

          if (
            'locations' in variables.corporateGroup &&
            variables.corporateGroup.locations
          )
            return variables.corporateGroup.locations
          if (
            'locationGroups' in variables.corporateGroup &&
            variables.corporateGroup.locationGroups
          )
            return variables.corporateGroup.locationGroups
        })()
        const metricData =
          data?.listLocationGroupMetricValues?.nodes ||
          data?.listLocationMetricValues?.nodes
        const yoyNodes =
          yoyData?.listLocationGroupMetricValues?.nodes ||
          yoyData?.listLocationMetricValues?.nodes ||
          []

        if (!corporateDetails || !metricData) return null

        const defaultSortingId =
          variables.rankings?.info?.defaultSorting?.id ||
          variables.rankings?.info?.fields[0].key
        const defaultSortingDesc =
          variables.rankings?.info?.defaultSorting?.desc ?? true

        return Object.keys(corporateDetails)
          .map((key) => {
            const id = parseInt(key, 10)
            const locationData = metricData.find((d) => {
              if ('locationId' in d) return d.locationId === id
              if ('locationGroupId' in d) return d.locationGroupId === id
              return false
            })
            const yoyData = yoyNodes.find((d) => {
              if ('locationId' in d) return d.locationId === id
              if ('locationGroupId' in d) return d.locationGroupId === id
              return false
            })
            const data = handler(
              format({
                ...locationData?.metricData,
                ..._.mapKeys(
                  yoyData?.metricData || {},
                  (_, key) => `yoy_${key}`,
                ),
              }),
            )

            return {
              ...corporateDetails[id]?.tableRow,
              ...(variables.rankings?.info?.fields || []).reduce(
                (result, f) => ({
                  ...result,
                  [f.key]: data[_.camelCase(f.metricCode)],
                }),
                {},
              ),
              id: id.toString(),
              parentId: 'root',
            }
          })
          .sort((a, b) => {
            if (!defaultSortingId) return -1

            const aValue = (a as Record<string, unknown>)[defaultSortingId]
            const bValue = (b as Record<string, unknown>)[defaultSortingId]

            if (
              aValue === null ||
              aValue === undefined ||
              Number.isNaN(aValue)
            ) {
              return 1
            }

            if (
              bValue === null ||
              bValue === undefined ||
              Number.isNaN(bValue)
            ) {
              return -1
            }

            const value = (() => {
              if (typeof aValue === 'string' && typeof bValue === 'string') {
                const aNum = strToNum(aValue)
                const bNum = strToNum(bValue)

                return aNum !== null && bNum !== null
                  ? aNum - bNum
                  : aValue.localeCompare(bValue)
              }

              return getNumberFromData(aValue) - getNumberFromData(bValue)
            })()

            return value * (defaultSortingDesc ? -1 : 1)
          })
      }, [variables, data, yoyData]),
      loading: loading || yoyLoading,
    }
  }

  return useRankings
}

export default buildRankingsHook
