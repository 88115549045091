import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useDateRange from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import { getDateRangeLabelV2 } from 'pared/utils/date'

import { useDateFilter } from '../../../dateFilter'
import { IApiDataType } from '../../types'

type IMetricType = string | { key: string; type: 'yoy' }

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'
    value: number
  }
>

type INodeType = Parameters<typeof getDateRangeLabelV2>[1] & {
  locationGroupId: number
  startDate: string
  endDate: string
  metricSummaryData: IMetricDataType
}

interface IDataType {
  trendLocationGroupMetricValues?: {
    nodes: INodeType[]
  }
  trendLocationMetricValues?: {
    nodes: INodeType[]
  }
}

const query = gql`
  query TrendLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iGroupBy: $iGroupBy
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        groupByStartDate: startDate
        groupByEndDate: endDate
        businessYear
        businessWeek
        businessWeekOfMonth
        businessMonth
        metricSummaryData
      }
    }

    trendLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iGroupBy: $iGroupBy
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        groupByStartDate: startDate
        groupByEndDate: endDate
        businessYear
        businessWeek
        businessWeekOfMonth
        businessMonth
        metricSummaryData
      }
    }
  }
`

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    switch (value.unit) {
      case 'PERCENTAGE':
      case 'DOLLAR':
        return {
          ...result,
          [_.camelCase(key)]: value.value * 100,
        }
      default:
        return {
          ...result,
          [_.camelCase(key)]: value.value,
        }
    }
  }, {})

const buildMetricTrendsHook = (options: {
  groupFilterType: 'corporate' | 'store'
  metrics: { codes?: IMetricType[]; groups?: IMetricType[] }
}) => {
  const useMetricValues = (
    dateRange: string,
    trendHookOptions?: {
      customLocationGroupIds?: number[]
      shouldBypassRowLevelSecurity?: boolean
    },
  ) => {
    const { startDate, endDate } = useDateFilter()
    const { dateRangeNumber, dateRangeGroupBy } = useDateRange(dateRange)

    let correctedEndDate = moment(endDate, 'YYYY-MM-DD').clone()
    const startOfToday = moment().startOf('day')
    if (correctedEndDate.isAfter(startOfToday)) {
      correctedEndDate = startOfToday
    }
    const correctedEndDateStr = correctedEndDate.format('YYYY-MM-DD')

    const correctedStartDateStr = (() => {
      switch (dateRangeGroupBy) {
        case 'last_x_days':
          return correctedEndDate
            .clone()
            .subtract(dateRangeNumber, 'days')
            .format('YYYY-MM-DD')
        case 'last_x_weeks':
          return correctedEndDate
            .clone()
            .subtract(dateRangeNumber, 'weeks')
            .format('YYYY-MM-DD')
        case 'last_x_periods':
          return correctedEndDate
            .clone()
            .subtract(dateRangeNumber, 'months')
            .format('YYYY-MM-DD')
        default:
          return startDate
      }
    })()

    const { groupFilter, hasGroupBy } = useGroupFilter()
    const locationFilter = (() => {
      if (trendHookOptions?.customLocationGroupIds) {
        return {
          location_group_ids: trendHookOptions.customLocationGroupIds,
          bypass_row_level_security:
            !!trendHookOptions.shouldBypassRowLevelSecurity,
        }
      } else if (options.groupFilterType === 'corporate') {
        return {
          location_group_ids: hasGroupBy
            ? groupFilter?.ids
            : groupFilter?.list?.map((g) => g.id),
          intersected_location_group_ids: groupFilter?.intersectedIds,
        }
      } else {
        return {
          location_ids: groupFilter?.ids,
        }
      }
    })()

    const { data, loading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: correctedStartDateStr,
        iEndDate: correctedEndDateStr,
        iGroupBy: dateRangeGroupBy,
        iFilter: {
          ...locationFilter,
          metrics:
            options.metrics?.codes
              ?.map((m) => (typeof m === 'string' ? m : null))
              .filter(Boolean) || [],
          metric_groups:
            options.metrics?.groups
              ?.map((m) => (typeof m === 'string' ? m : null))
              ?.filter(Boolean) || [],
        },
        hasGroupBy: hasGroupBy || options.groupFilterType === 'store',
      },
      skip:
        !correctedStartDateStr ||
        !endDate ||
        !groupFilter ||
        !dateRange ||
        (!options.metrics?.codes?.some((m) => typeof m === 'string') &&
          !options.metrics?.groups?.some((m) => typeof m === 'string')),
    })

    return {
      data: useMemo((): IApiDataType => {
        const trendData =
          data?.trendLocationGroupMetricValues?.nodes ||
          data?.trendLocationMetricValues?.nodes

        if (!trendData || !dateRangeGroupBy) return null

        return trendData
          .filter((d) => d.metricSummaryData)
          .map((d) => ({
            ...format(d.metricSummaryData),
            date: getDateRangeLabelV2(dateRangeGroupBy, d),
          }))
      }, [data, dateRangeGroupBy]),
      loading,
    }
  }
  return useMetricValues
}

export default buildMetricTrendsHook
