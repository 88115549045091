import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

interface IDataType {
  listLocationMetricValues: {
    nodes: {
      locationId: number
      metricData: Record<
        | 'total_food_and_paper'
        | 'total_sales'
        | 'profit_after_controllables_after_donation',
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
}

const query = gql`
  query listLocationMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        locationId
        metricData
      }
    }
  }
`

const buildMetricValuesHook = (
  metricCodes: string[],
  getData: (
    data: IDataType['listLocationMetricValues']['nodes'][number]['metricData'],
  ) => Record<string, unknown> & { sales: number },
  locationGroupIds?: string,
) => {
  const useBbbMetricValues = () => {
    const { startDate, endDate } = useDateFilter()
    const { variables, template } = useVariables()
    const { data, loading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iLocationGroupIds: locationGroupIds
          ? template(locationGroupIds)
          : variables.allStores?.locationGroupIds,
        iMetricCodes: metricCodes,
      },
      skip: !startDate || !endDate || !variables.allStores,
    })

    return {
      data: useMemo((): IApiDataType => {
        const metricData = data?.listLocationMetricValues.nodes

        if (!metricData) return null

        return metricData
          .map((m) => ({
            ...getData(m.metricData),
            location: variables.allStores?.locations[m.locationId].displayName,
          }))
          .sort((a, b) => a.sales - b.sales)
      }, [data, variables.allStores]),
      loading,
    }
  }

  return useBbbMetricValues
}

export const bbbFoodAndPaperConfigs = {
  foodAndPaperPercent: 'percent',
  sales: 'price',
  location: 'string',
} as const
export const useBbbFoodAndPaper = buildMetricValuesHook(
  ['total_food_and_paper', 'total_sales'],
  (data) => ({
    foodAndPaperPercent:
      (data.total_food_and_paper.value * 100) / data.total_sales.value,
    sales: data.total_sales.value,
  }),
)

export const bbbProfitAfterControllablesConfigs = {
  profitAfterControllablesAfterDonation: 'price',
  profitAfterControllablesAfterDonationPercent: 'percent',
  sales: 'price',
  location: 'string',
} as const
export const useBbbProfitAfterControllables = buildMetricValuesHook(
  ['profit_after_controllables_after_donation', 'total_sales'],
  (data) => ({
    profitAfterControllablesAfterDonation:
      data.profit_after_controllables_after_donation.value,
    profitAfterControllablesAfterDonationPercent:
      (data.profit_after_controllables_after_donation.value * 100) /
      data.total_sales.value,
    sales: data.total_sales.value,
  }),
  '<%- JSON([bbbConstants.COMPANY_STORE_PERIOD_GROUP_ID]) %>',
)
