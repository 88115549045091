import { IConfigsType } from '../../types'

const fwOriginalStorePagesConfig: IConfigsType = {
  fw_wingstop: {
    '/:brand/store_detail': {},
    '/:brand/delivery': {},
  },
}

export default fwOriginalStorePagesConfig
