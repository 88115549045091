import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const bibibopConfig: IConfigsType = {
  bibibop: {
    '/:brand/systemwide_overview': {
      variables: ['corporateGroup'],
      groupFilter: {
        api: 'bbbCorporateFilter',
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      systemwideOverview: {
        type: 'table',
        api: 'bbbSystemwideOverviewKpis',
        title: 'Systemwide Overview',
        highlineEvenRows: true,
        rowKey: 'title',
        columns: [
          {
            key: 'title',
            title: ' ',
            width: '130px',
          },
          {
            key: 'sales',
            title: 'Sales',
            align: 'center',
            width: '120px',
          },
          {
            key: 'foodAndPaperPercent',
            title: 'F&P %',
            align: 'center',
            width: '120px',
          },
          {
            key: 'laborPercent',
            title: 'Labor %',
            align: 'center',
            width: '120px',
          },
          {
            key: 'fplPercent',
            title: 'FPL %',
            align: 'center',
            width: '120px',
          },
          {
            key: 'ebitdaBeforeBonusPercent',
            title: 'EBITDA Before Bonus %',
            align: 'center',
            width: '130px',
          },
          {
            key: 'ebitdaAfterBonus',
            title: 'EBITDA After Bonus $',
            align: 'center',
            width: '130px',
          },
          {
            key: 'ebitdaAfterBonusPercent',
            title: 'EBITDA After Bonus %',
            align: 'center',
            width: '130px',
          },
          {
            key: 'grossProfitPercent',
            title: 'Gross Profit %',
            align: 'center',
            width: '130px',
          },
          {
            key: 'restaurantLevelProfitPercent',
            title: 'Restaurant Level Profit %',
            align: 'center',
            width: '150px',
          },
        ],
      },
      breakdownByStore: {
        type: 'table-v2',
        api: 'bbbSystemwideOverview',
        title: 'Breakdown by Store',
        sorting: [
          {
            id: 'totalSales',
            desc: true,
          },
        ],
        pagination: true,
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'sales',
            title: 'Sales',
            align: 'center',
            background: COLORS.Porcelain,
            children: [
              {
                key: 'totalSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'totalSalesBudgetVariance',
                title: 'Variance %',
                align: 'center',
                sortable: true,
                width: '120px',
              },
              {
                key: 'netSalesSssPercent',
                title: 'YOY %',
                align: 'center',
                sortable: true,
              },
              {
                key: 'totalSalesBudget',
                title: 'Budget',
                align: 'center',
                sortable: true,
                width: '120px',
              },
              {
                key: 'totalSalesBudgetDiff',
                title: 'Diff',
                align: 'center',
                sortable: true,
                width: '120px',
              },
            ],
          },
          {
            key: 'Weekly Sales',
            title: 'Average Weekly Sales',
            align: 'center',
            children: [
              {
                key: 'avgWeeklySales',
                title: '$',
                align: 'center',
                sortable: true,
                width: '120px',
              },
            ],
          },
          {
            key: 'Annualized AUV',
            title: 'Annualized AUV',
            align: 'center',
            background: COLORS.Porcelain,
            children: [
              {
                key: 'annualizedAvgWeeklySales',
                title: '$',
                align: 'center',
                sortable: true,
                width: '120px',
              },
            ],
          },
          {
            key: 'Labor',
            title: 'Labor',
            align: 'center',
            children: [
              {
                key: 'totalLaborPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
              {
                key: 'totalLaborVariance',
                title: 'Variance',
                align: 'center',
                color: '<%- value > 0 ? colors.Pomodoro : "" %>',
                sortable: true,
              },
            ],
          },
          {
            key: 'Food & Paper',
            title: 'Food & Paper',
            align: 'center',
            background: COLORS.Porcelain,
            children: [
              {
                key: 'totalFoodAndPaperPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
              {
                key: 'totalFoodAndPaperVariance',
                title: 'Variance',
                align: 'center',
                color: '<%- value > 0 ? colors.Pomodoro : "" %>',
                sortable: true,
              },
            ],
          },
          {
            key: 'EBITDA After Bonus',
            title: 'EBITDA After Bonus',
            align: 'center',
            children: [
              {
                key: 'ebitdaAfterBonus',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'ebitdaAfterBonusBudget',
                title: 'Budget',
                align: 'center',
                sortable: true,
                width: '120px',
              },
              {
                key: 'ebitdaAfterBonusDiff',
                title: 'Diff',
                align: 'center',
                sortable: true,
                width: '120px',
              },
            ],
          },
          {
            key: 'ebitdaBeforeBonusPercent',
            title: 'EBITDA Before Bonus %',
            align: 'center',
            sortable: true,
            background: COLORS.Porcelain,
            width: '150px',
          },
          {
            key: 'ebitdaAfterBonusPercent',
            title: 'EBITDA After Bonus %',
            align: 'center',
            sortable: true,
            width: '150px',
          },
          {
            key: 'grossProfitPercent',
            title: 'Gross Profit %',
            align: 'center',
            sortable: true,
            background: COLORS.Porcelain,
            width: '150px',
          },
          {
            key: 'restaurantLevelProfitPercent',
            title: 'Restaurant Level Profit %',
            align: 'center',
            sortable: true,
            width: '150px',
          },
        ],
      },
      averageWeeklySales: {
        type: 'line-chart',
        title: 'AUV',
        api: 'bbbWeeklySales',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'auv',
            label: 'Weekly AUV',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
          {
            key: 'avgAuv',
            label: 'AUV for Time Period Selected',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          scales: {
            y: {
              title: {
                text: '$',
              },
            },
          },
        },
      },
      map: {
        type: 'map',
        api: 'listGeoInfo',
      },
    },
  },
}

export default bibibopConfig
