import useDate, {
  IDataType as IDateDataType,
  IOptionType as IDateOptionType,
} from './date'
import useAllStores, {
  IDataType as IAllStoresDataType,
  IOptionType as IAllStoresOptionType,
} from './useAllStores'
import useCorporateGroup, {
  IDataType as ICorporateGroupDataType,
} from './useCorporateGroup'
import useDeliveryDayParts, {
  IDataType as IDeliveryDayPartsType,
} from './useDeliveryDayParts'
import useDeliveryKpiType, {
  IDataType as IDeliveryKpiTypeDataType,
} from './useDeliveryKpiType'
import useDiscountType, {
  IDataType as IDiscountTypeDataType,
} from './useDiscountType'
import useInterval, { IDataType as IIntervalDataType } from './useInterval'
import useItemByDaypartColummFilter, {
  IDataType as IItemByDaypartColumnFilterType,
} from './useItemByDaypartColumnFilter'
import useItemOrCategory, {
  IDataType as IItemOrCategoryDataType,
} from './useItemOrCategory'
import useLastTimeRange, {
  IDataType as ILastTimeRangeDataType,
  IOptionType as ILastTimeRangeOptionType,
} from './useLastTimeRange'
import useRankings, {
  IDataType as IRankingsDataType,
  IOptionType as IRankingsOptionType,
} from './useRankings'
import useRevenueCenterSales, {
  IDataType as IRevenueCenterSalesType,
} from './useRevenueCenterSales'
import useTimeBetween, {
  IDataType as ITimeBetweenDataType,
} from './useTimeBetween'
import useTrack, { IDataType as ITrackDataType } from './useTrack'

export type IVariablesType =
  | 'track'
  | 'date'
  | ({ type: 'date' } & IDateOptionType)
  | 'allStores'
  | ({ type: 'allStores' } & IAllStoresOptionType)
  | 'corporateGroup'
  | 'items'
  | 'interval'
  | 'timeBetween'
  | 'itemByDaypartColumnFilter'
  | 'discountType'
  | 'lastTimeRange'
  | ({ type: 'lastTimeRange' } & ILastTimeRangeOptionType)
  | 'deliveryKpiType'
  | 'deliveryDayParts'
  | 'revenueCenterSales'
  | ({ type: 'rankings' } & IRankingsOptionType)

export type IDataType = IDateDataType &
  IAllStoresDataType &
  ITrackDataType &
  ICorporateGroupDataType &
  IItemOrCategoryDataType &
  IIntervalDataType &
  ITimeBetweenDataType &
  IItemByDaypartColumnFilterType &
  IDiscountTypeDataType &
  ILastTimeRangeDataType &
  IDeliveryKpiTypeDataType &
  IDeliveryDayPartsType &
  IRevenueCenterSalesType &
  IRankingsDataType

export const hooks = [
  ['track', useTrack],
  ['date', useDate],
  ['allStores', useAllStores],
  ['corporateGroup', useCorporateGroup],
  ['items', useItemOrCategory],
  ['interval', useInterval],
  ['timeBetween', useTimeBetween],
  ['itemByDaypartColumnFilter', useItemByDaypartColummFilter],
  ['discountType', useDiscountType],
  ['lastTimeRange', useLastTimeRange],
  ['deliveryKpiType', useDeliveryKpiType],
  ['deliveryDayParts', useDeliveryDayParts],
  ['revenueCenterSales', useRevenueCenterSales],
  ['rankings', useRankings],
] as const
