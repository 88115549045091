import useArbListLocationGroupPrimantiTattleKpis, {
  arbListLocationGroupPrimantiTattleKpisConfigs,
} from './useArbListLocationGroupPrimantiTattleKpis'
import useArbListLocationPrimantiTattleKpis, {
  arbListLocationPrimantiTattleKpisConfigs,
} from './useArbListLocationPrimantiTattleKpis'

export const configs = {
  arbListLocationGroupPrimantiTattleKpis:
    arbListLocationGroupPrimantiTattleKpisConfigs,
  arbListLocationPrimantiTattleKpis: arbListLocationPrimantiTattleKpisConfigs,
}

export const api = {
  arbListLocationGroupPrimantiTattleKpis:
    useArbListLocationGroupPrimantiTattleKpis,
  arbListLocationPrimantiTattleKpis: useArbListLocationPrimantiTattleKpis,
}
