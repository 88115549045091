import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { toUsdStr } from 'pared/utils/number'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiType } from '../../types'

const QUERY_NAME = 'listLocationMetricValues'
const query = gql`
  query (
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    ${QUERY_NAME}(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        metricData
      }
    }
  }
`

export const locationLaborOverviewConfigs = {
  name: 'string',
  value: 'string',
} as const

const useLocationLaborOverview = (): IApiType => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()

  const iFilter = {
    location_ids: groupFilter?.ids,
    metrics: ['net_sales', 'labor_total_cost', 'labor_overtime_cost'],
  }

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const nodes = data?.[QUERY_NAME].nodes as
        | {
            locationId: string
            metricData: {
              [key: string]: {
                name: string
                unit: string
                value: number
              }
            }
          }[]
        | undefined

      if (!nodes || nodes.length === 0) return null
      const mappedData: {
        [labor: string]: {
          [kpi: string]: number | string
        }
      } = {}
      const metricData = nodes[0]?.metricData
      const netSales =
        metricData['net_sales'].unit === 'CENT'
          ? metricData['net_sales'].value / 100
          : metricData['net_sales'].value
      const hourlyLaborPercent =
        metricData['labor_total_cost'].unit === 'CENT'
          ? metricData['labor_total_cost'].value / netSales
          : (metricData['labor_total_cost'].value / netSales) * 100
      const overtimeCost =
        metricData['labor_overtime_cost'].unit === 'CENT'
          ? metricData['labor_overtime_cost'].value / 100
          : metricData['labor_overtime_cost'].value

      mappedData['hourlyLaborPercent'] = {
        name: 'Hourly Labor %',
        value: `${hourlyLaborPercent.toFixed(2)}%`,
      }

      mappedData['overtimeCost'] = {
        name: 'Overtime',
        value: toUsdStr(overtimeCost),
      }

      const rows = Object.values(mappedData).map((n) => {
        return {
          ...n,
          id: n.name as string,
          parentId: 'root',
        }
      })

      return rows
    }, [groupFilter, data]),
    loading,
  }
}

export default useLocationLaborOverview
