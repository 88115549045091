import _ from 'lodash'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'
import buildMetricTrendsHook from '../utils/buildMetricTrendsHook'

export const burgerworksTrendLocationGuestConfigs = {
  date: 'string',
  deliveryAvgCustomerReviewScoreDoordash: 'percent',
  systemwideDeliveryAvgCustomerReviewScoreDoordash: 'percent',
} as const

const useLocationMetricTrendsHook = buildMetricTrendsHook({
  groupFilterType: 'store',
  metrics: {
    codes: ['delivery_avg_customer_review_score_doordash'],
  },
})

const useSystemwideMetricTrendsHook = buildMetricTrendsHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['delivery_avg_customer_review_score_doordash'],
  },
})

const useTrendLocationGuestKpis = () => {
  const { variables } = useVariables()
  const dateRange = variables.lastTimeRange?.value[0][0] || ''

  const { brand } = useBrands()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]

  const { data: locationData, loading: isLocationDataLoading } =
    useLocationMetricTrendsHook(dateRange)
  const { data: systemwideData, loading: isSystemwideDataLoading } =
    useSystemwideMetricTrendsHook(dateRange, {
      customLocationGroupIds: [brandLocationGroupId],
      shouldBypassRowLevelSecurity: true,
    })

  return {
    data: useMemo((): IApiDataType => {
      if (!locationData || !systemwideData) return null

      const timeRangeDataMap: { [timeRange: string]: Record<string, unknown> } =
        {}

      systemwideData.forEach((details: any, index: number) => {
        const timeRange: string = details.date
        if (timeRange) {
          const dataAlreadyInMap = timeRangeDataMap[timeRange] || {}
          timeRangeDataMap[timeRange] = {
            ...dataAlreadyInMap,
            date: details.date,
            timeRangeIndex: index,
            systemwideDeliveryAvgCustomerReviewScoreDoordash:
              details.deliveryAvgCustomerReviewScoreDoordash,
          }
        }
      })

      locationData.forEach((details: any) => {
        const timeRange: string = details.date
        if (timeRange) {
          const dataAlreadyInMap = timeRangeDataMap[timeRange] || {}
          timeRangeDataMap[timeRange] = {
            ...dataAlreadyInMap,
            ...details,
          }
        }
      })

      return _.orderBy(Object.values(timeRangeDataMap), 'timeRangeIndex')
    }, [
      locationData,
      systemwideData,
      isLocationDataLoading,
      isSystemwideDataLoading,
    ]),
    loading: isLocationDataLoading || isSystemwideDataLoading,
  }
}

export default useTrendLocationGuestKpis
