import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useGroupFilter } from '../../groupFilter'
import { useVariables } from '../../variables'
import { IApiDataType } from '../types'

interface ILocationInfo {
  id: number
  code: string
  name: string
}

interface ICorporateSalesmanshipLocations extends ILocationInfo {
  rate: number
  expectRate: number
  checkCount: number
  opportunityCost: number
  annualOpportunityCost: number
}

interface ISalesSummaryNodeType {
  area: string
  locations: ICorporateSalesmanshipLocations[]
  annualOpportunityCost: number
  opportunityCost: number
  totalNetSales: number
  annualTotalNetSales: number
}

interface ISalesSummaryType {
  corporateUSalesmanshipSummary: {
    nodes: ISalesSummaryNodeType[]
  }
}

const query = gql`
  query getCorporateSalesSummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateUSalesmanshipSummary(
      iBrandLocationGroupId: $iBrandLocationGroupId
      iLocationGroupId: $iLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        area
        locations
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`

export const corporateExpoAiSalesSummaryConfigs = {
  storeCount: 'string',
  area: 'string',
  annualOpportunityCost: 'price',
} as const

const createCorporateExpoAiSalesSummaryHook = (isLocationDetails: boolean) => {
  const useCorporateExpoAiSalesSummaryData = () => {
    const { variables } = useVariables()
    const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
    const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
    const { groupFilter } = useGroupFilter()
    const locationGroupId = groupFilter?.ids[0] || 0
    const { brand } = useBrands()
    const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
    const { data, loading } = useQuery<ISalesSummaryType>(query, {
      variables: {
        iLocationGroupId: locationGroupId,
        iBrandLocationGroupId: brandLocationGroupId,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !groupFilter,
    })

    return {
      data: useMemo((): IApiDataType => {
        if (isLocationDetails) {
          const summary = data?.corporateUSalesmanshipSummary?.nodes
          if (!summary) return null

          const area = variables?.aiSummary['uSales']?.clickedId
          if (!area) return null

          const locationData = summary.find((s) => s.area == area)

          return {
            source:
              locationData?.locations?.map((l) => {
                const groupInfo = groupFilter?.list?.find((g) => g.id == l.id)

                return {
                  groupInfo,
                  annualOpportunityCost: l.annualOpportunityCost,
                }
              }) || [],
          }
        } else {
          const summary = data?.corporateUSalesmanshipSummary?.nodes
          if (!summary) return null

          return {
            source: summary.map((n) => {
              return {
                ...n,
                storeCount: `${n.locations.length} stores`,
              }
            }),
          }
        }
      }, [data, variables, groupFilter]),
      loading,
    }
  }

  return useCorporateExpoAiSalesSummaryData
}

export const useCorporateExpoAiSalesSummary =
  createCorporateExpoAiSalesSummaryHook(false)
export const useCorporateExpoAiSalesLocationDetails =
  createCorporateExpoAiSalesSummaryHook(true)
