import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

type IDataType = Record<
  'trendLocationGroupMetricValues' | 'trendLocationGroupYoyMetricValues',
  {
    nodes: {
      startDate: string
      endDate: string
      metricData: Record<
        'total_sales',
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
>

const query = gql`
  query trendLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_month"
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        startDate
        endDate
        metricData
      }
    }

    trendLocationGroupYoyMetricValues: trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_month"
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
        use_yoy: true
      }
    ) {
      nodes {
        startDate
        endDate
        metricData
      }
    }
  }
`

export const bbbAllPeriodMetricConfigs = {
  year: 'string',
  '<%- JSON(date?.getCalendar("period", "year").ids.map(id => ({ key: "compSalesPercent" + id, title: id }))) %>':
    'percent',
} as const

const HARDCODE_DATA = {
  '2022P1': 5.66,
  '2022P2': 15.55,
  '2022P3': 4.62,
  '2022P4': -4.38,
  '2022P5': 2.42,
  '2022P6': 2.52,
  '2022P7': 1.97,
  '2022P8': 11.64,
  '2022P9': 8.27,
  '2022P10': 7.12,
  '2022P11': 6.48,
  '2022P12': 3.45,
} as Record<`${number}P${number}`, number>
const NOW = moment.utc()

const useBbbAllPeriodMetric = () => {
  const { variables } = useVariables()
  const periodCalendar = variables.date?.getCalendar('period')?.calendar || [[]]
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: periodCalendar.slice(-1)[0]?.[0]?.dateRange.startDateStr,
      iEndDate: periodCalendar[0]?.slice(-1)[0]?.dateRange.endDateStr,
      iLocationGroupIds: [variables.bbbConstants?.COMP_GROUP_ID],
      iMetricCodes: ['total_sales'],
    },
    skip: !variables.date || !variables.bbbConstants,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const metricData = data.trendLocationGroupMetricValues.nodes
      const yoyMetricData = data.trendLocationGroupYoyMetricValues.nodes
      const periods = periodCalendar.flat()
      const metricPeriods = metricData.reduce((result, data) => {
        const period: typeof periods[number] | undefined = periods.find(
          (t) =>
            t.dateRange.startDateStr === data.startDate &&
            t.dateRange.endDateStr === data.endDate,
        )
        const yoyMetricPeriod = yoyMetricData.find(
          (y) => y.startDate === data.startDate && y.endDate === data.endDate,
        )

        if (
          !period ||
          !yoyMetricPeriod ||
          period.dateRange.endDate.isAfter(NOW)
        )
          return result

        return {
          ...result,
          [`${period.year}${period.id}`]:
            ((data.metricData.total_sales.value -
              yoyMetricPeriod.metricData.total_sales.value) *
              100) /
            yoyMetricPeriod.metricData.total_sales.value,
        }
      }, {} as Record<string, number>)

      return periodCalendar.map((periods) =>
        periods.reduce(
          (result, period) => ({
            ...result,
            [`compSalesPercent${period.id}`]:
              HARDCODE_DATA[`${period.year}${period.id}`] ||
              metricPeriods[`${period.year}${period.id}`],
          }),
          {
            id: periods[0].year.toString(),
            parentId: 'root',
            year: periods[0].year.toString(),
          },
        ),
      )
    }, [data, periodCalendar]),
    loading,
  }
}

export default useBbbAllPeriodMetric
