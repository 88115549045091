import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

type IDataType = Record<
  'trendLocationGroupMetricValues' | 'trendLocationGroupYoyMetricValues',
  {
    nodes: {
      startDate: string
      endDate: string
      metricData: Record<
        'total_sales',
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
>

const query = gql`
  query trendLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_month"
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        startDate
        endDate
        metricData
      }
    }

    trendLocationGroupYoyMetricValues: trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_month"
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
        use_yoy: true
      }
    ) {
      nodes {
        startDate
        endDate
        metricData
      }
    }
  }
`

export const bbbAllPeriodMetricConfigs = {
  period: 'string',
  '<%- JSON(date?.getCalendar("period", "year").yearIds.map(id => ({ key: "compSalesPercent" + id, label: id }))) %>':
    'percent',
} as const

const HARDCODE_DATA = {
  P1: { compSalesPercent2022: 5.66 },
  P2: { compSalesPercent2022: 15.55 },
  P3: { compSalesPercent2022: 4.62 },
  P4: { compSalesPercent2022: -4.38 },
  P5: { compSalesPercent2022: 2.42 },
  P6: { compSalesPercent2022: 2.52 },
  P7: { compSalesPercent2022: 1.97 },
  P8: { compSalesPercent2022: 11.64 },
  P9: { compSalesPercent2022: 8.27 },
  P10: { compSalesPercent2022: 7.12 },
  P11: { compSalesPercent2022: 6.48 },
  P12: { compSalesPercent2022: 3.45 },
} as Record<string, Record<string, number>>
const NOW = moment.utc()

const useBbbAllPeriodMetric = () => {
  const { variables } = useVariables()
  const periodCalendar = variables.date?.getCalendar('period')?.calendar || [[]]
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: periodCalendar.slice(-1)[0]?.[0]?.dateRange.startDateStr,
      iEndDate: periodCalendar[0]?.slice(-1)[0]?.dateRange.endDateStr,
      iLocationGroupIds: [variables.bbbConstants?.COMP_GROUP_ID],
      iMetricCodes: ['total_sales'],
    },
    skip: !variables.date || !variables.bbbConstants,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const metricData = data.trendLocationGroupMetricValues.nodes
      const yoyMetricData = data.trendLocationGroupYoyMetricValues.nodes
      const periods = periodCalendar.flat()
      const metricPeriods = metricData.reduce((result, data) => {
        const period = periods.find(
          (t) =>
            t.dateRange.startDateStr === data.startDate &&
            t.dateRange.endDateStr === data.endDate,
        )
        const yoyMetricPeriod = yoyMetricData.find(
          (y) => y.startDate === data.startDate && y.endDate === data.endDate,
        )

        if (
          !period ||
          !yoyMetricPeriod ||
          period.dateRange.endDate.isAfter(NOW)
        )
          return result

        return {
          ...result,
          [period.id]: {
            ...result[period.id],
            [`compSalesPercent${period.year}`]:
              ((data.metricData.total_sales.value -
                yoyMetricPeriod.metricData.total_sales.value) *
                100) /
              yoyMetricPeriod.metricData.total_sales.value,
          },
        }
      }, HARDCODE_DATA)

      return Object.keys(metricPeriods)
        .map((period) => ({
          ...metricPeriods[period],
          period,
        }))
        .sort(
          (a, b) =>
            parseInt(a.period.replace(/P/, ''), 10) -
            parseInt(b.period.replace(/P/, ''), 10),
        )
    }, [data, periodCalendar]),
    loading,
  }
}

export default useBbbAllPeriodMetric
