import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'

import { IApiDataType, IExrayDataType } from '../../../types'

type IUnitType = 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'

type IMetricDataType = Record<
  string,
  {
    unit: IUnitType
    value: number
  }
>

interface IDeliveryLocationVsSystemwideNodeType {
  locationCode: string
  locationName: string
  locationCorrectOrderPercent: number
  systemwideCorrectOrderPercent: number
  locationInaccurateOrderPercent: number
  systemwideInaccurateOrderPercent: number
  locationCancelledOrderPercent: number
  systemwideCancelledOrderPercent: number
  locationDelayedOrderPercent: number
  systemwideDelayedOrderPercent: number
}

interface IDeliveryEmployeeMetricValuesNodeType {
  metricData: IMetricDataType
  endDate: string
  metricLocationId: string
  employeeId: string
  employeeNumber: string
  employeeName: string
}

interface IDeliveryDayTrendNodeType {
  locationId: number
  dayOfWeek: number
  dayOfWeekName: string
  allOrderWithAnyIssuePercent: number
  allInaccurateOrderPercent: number
  allCancelledOrderPercent: number
  allDelayedOrderPercent: number
}

interface ICorporateUDeliverySummaryNodeType {
  type: string
  locations: {
    id: number
    code: string
    name: string
    director: {
      id: number
      given_name: string
      family_name: string
      preferred_name: string
    }
  }[]
}

type IDeliverySummaryType =
  | {
      getULocationVsSystemwideDeliveryKpi: {
        nodes: IDeliveryLocationVsSystemwideNodeType[]
      }
      listEmployeeMetricValues: {
        nodes: IDeliveryEmployeeMetricValuesNodeType[]
      }
      listLocationUDeliveryDayTrends: {
        nodes: IDeliveryDayTrendNodeType[]
      }
    }
  | {
      corporateUDeliverySummary: {
        nodes: ICorporateUDeliverySummaryNodeType[]
      }
    }

const query = gql`
  query LocationDeliverySummary(
    $iBrandLocationGroupId: Int!
    $iLocationGroupId: Int!
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    getULocationVsSystemwideDeliveryKpi(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationCode
        locationName
        locationCorrectOrderPercent
        systemwideCorrectOrderPercent
        locationInaccurateOrderPercent
        systemwideInaccurateOrderPercent
        locationCancelledOrderPercent
        systemwideCancelledOrderPercent
        locationDelayedOrderPercent
        systemwideDelayedOrderPercent
      }
    }

    listEmployeeMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        endDate
        metricLocationId
        employeeLocationId
        employeeId
        employeeName
        employeeNumber
        metricData
      }
    }

    listLocationUDeliveryDayTrends(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationId
        dayOfWeek
        dayOfWeekName
        allOrderWithAnyIssuePercent
        allInaccurateOrderPercent
        allCancelledOrderPercent
        allDelayedOrderPercent
      }
    }

    corporateUDeliverySummary(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) @include(if: $hasGroupBy) {
      nodes {
        type
        locations
      }
    }
  }
`

const getCorporateDeliverySummaryData = (
  dataSource: ICorporateUDeliverySummaryNodeType[],
): IExrayDataType => {
  const deliveryStore =
    dataSource?.reduce((result, d) => {
      const locationIds = d.locations
        .map((l) => l.id)
        .filter((id) => !result.includes(id))
      return [...result, ...locationIds]
    }, [] as number[]) || []

  return {
    title: 'Delivery',
    total: null,
    detail: `For the Trailing 30 Days, ${deliveryStore.length} stores have
    higher Delivery Issues than company averages.`,
  }
}

const getDeliverySummaryData = (
  locationName: string,
  data: IDeliverySummaryType,
): IExrayDataType => {
  const deliveryAction = {
    isBetterThanCompany: false,
    issue: '',
    worstDay: '',
    name: ',',
  }
  const deliverySummary = data?.getULocationVsSystemwideDeliveryKpi?.nodes[0]
  let accuracy = false
  let cancellations = false
  let lateness = false

  if (deliverySummary) {
    const {
      locationInaccurateOrderPercent,
      systemwideInaccurateOrderPercent,
      locationCancelledOrderPercent,
      systemwideCancelledOrderPercent,
      locationDelayedOrderPercent,
      systemwideDelayedOrderPercent,
    } = deliverySummary

    if (locationInaccurateOrderPercent > systemwideInaccurateOrderPercent) {
      accuracy = true
    }

    if (locationCancelledOrderPercent > systemwideCancelledOrderPercent) {
      cancellations = true
    }

    if (locationDelayedOrderPercent > systemwideDelayedOrderPercent) {
      lateness = true
    }
  }

  const deliveryDayTrendData = data?.listLocationUDeliveryDayTrends?.nodes || []

  const deliveryEmployeeData =
    data?.listEmployeeMetricValues?.nodes?.map((n) => ({
      ...n,
      ...format(n.metricData),
    })) || []

  if (deliveryDayTrendData?.length > 0 && deliveryEmployeeData?.length > 0) {
    const worstDays = deliveryDayTrendData?.reduce(
      (max, record) => {
        const accuracy =
          record.allInaccurateOrderPercent > max.accuracy.max
            ? {
                max: record.allInaccurateOrderPercent,
                weekDayName: record.dayOfWeekName,
              }
            : max.accuracy
        const cancellations =
          record.allCancelledOrderPercent > max.cancellations.max
            ? {
                max: record.allCancelledOrderPercent,
                weekDayName: record.dayOfWeekName,
              }
            : max.cancellations
        const lateness =
          record.allDelayedOrderPercent > max.lateness.max
            ? {
                max: record.allDelayedOrderPercent,
                weekDayName: record.dayOfWeekName,
              }
            : max.lateness

        return {
          accuracy,
          cancellations,
          lateness,
        }
      },
      {
        accuracy: {
          max: 0,
          weekDayName: '',
        },
        cancellations: {
          max: 0,
          weekDayName: '',
        },
        lateness: {
          max: 0,
          weekDayName: '',
        },
      },
    )

    let employee = ''

    if (accuracy) {
      if (worstDays.accuracy.weekDayName) {
        employee = deliveryEmployeeData
          ?.sort(
            (a, b) =>
              b?.deliveryInaccurateOrderPercentDoordash -
              a?.deliveryInaccurateOrderPercentDoordash,
          )
          ?.slice(0, 1)?.[0]?.employeeName

        deliveryAction.issue = 'Accuracy'
        deliveryAction.worstDay = worstDays.accuracy.weekDayName
      }
    } else if (lateness) {
      if (worstDays.lateness.weekDayName) {
        employee = deliveryEmployeeData
          ?.sort(
            (a, b) =>
              b?.deliveryCancelledOrderPercentDoordash -
              a?.deliveryCancelledOrderPercentDoordash,
          )
          ?.slice(0, 1)?.[0]?.employeeName

        deliveryAction.issue = 'Lateness'
        deliveryAction.worstDay = worstDays.lateness.weekDayName
      }
    } else if (cancellations) {
      if (worstDays.cancellations.weekDayName) {
        employee = deliveryEmployeeData
          ?.sort(
            (a, b) =>
              b?.deliveryDelayedOrderPercentDoordash -
              a?.deliveryDelayedOrderPercentDoordash,
          )
          ?.slice(0, 1)?.[0]?.employeeName

        deliveryAction.issue = 'Cancellations'
        deliveryAction.worstDay = worstDays.cancellations.weekDayName
      }
    } else {
      return {
        title: 'Delivery',
        total: null,
        detail: 'No data available for the selected time period.',
      }
    }

    if (employee) {
      deliveryAction.name = employee
    }

    if (!accuracy && !lateness && !cancellations) {
      deliveryAction.isBetterThanCompany = true

      if (worstDays.accuracy.weekDayName) {
        deliveryAction.issue = 'Accuracy'
        deliveryAction.worstDay = worstDays.accuracy.weekDayName
      } else if (worstDays.lateness.weekDayName) {
        deliveryAction.issue = 'Lateness'
        deliveryAction.worstDay = worstDays.lateness.weekDayName
      } else if (worstDays.cancellations.weekDayName) {
        deliveryAction.issue = 'Cancellations'
        deliveryAction.worstDay = worstDays.cancellations.weekDayName
      }
    }
  } else {
    return {
      title: 'Delivery',
      total: null,
      detail: 'No data available for the selected time period.',
    }
  }

  return {
    title: 'Delivery',
    total: null,
    detail: deliveryAction.isBetterThanCompany
      ? `${locationName} performs better than company averages, but to keep improving: ${deliveryAction.worstDay} is the worst day for ${deliveryAction.issue}.`
      : deliveryAction.issue &&
        `${deliveryAction.issue} is the top area to improve upon at ${locationName}. ${deliveryAction.worstDay} is the worst day. ${deliveryAction.name} is on shift when ${deliveryAction.issue} is worst.  Consider retraining your employees or repositioning them to reduce delivery issues.`,
  }
}

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    switch (value.unit) {
      case 'PERCENTAGE':
      case 'DOLLAR':
        return {
          ...result,
          [_.camelCase(key)]: value.value * 100,
        }
      default:
        return {
          ...result,
          [_.camelCase(key)]: value.value,
        }
    }
  }, {})

const useUDelivery = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const locationGroupId = groupFilter?.ids[0] || 0
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'
  const { brand } = useBrands()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const { data, loading } = useQuery<IDeliverySummaryType>(query, {
    variables: {
      iBrandLocationGroupId: brandLocationGroupId,
      iLocationGroupId: locationGroupId,
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_ids: [locationId],
        metric_groups: ['Expo Module - Delivery Data'],
      },
      hasGroupBy,
    },
    skip: !brandLocationGroupId || !groupFilter || !startDate || !endDate,
  })

  return {
    data: useMemo(() => {
      if (!data) {
        return null
      }

      if (hasGroupBy) {
        return getCorporateDeliverySummaryData(
          data?.corporateUDeliverySummary?.nodes,
        )
      } else {
        return getDeliverySummaryData(locationName, data)
      }
    }, [locationName, data]),
    loading,
  }
}

export default useUDelivery
