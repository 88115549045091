import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const fwWingstopFinancialChartConfig: IConfigsType = {
  'fw_wingstop-financial-chart-239cbabf': {
    '/:brand/sales_chart': {
      variables: ['date'],
      groupFilter: {
        api: 'fwWingstopCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['year'],
        defaultType: 'year',
      },
      pageTitle: {
        type: 'title',
        title: 'Total Sales Charts',
      },
      downloadReport: {
        type: 'download-csv',
        api: 'farwestSalesCharts',
        fileName: 'getexpo-far-west-sales-charts',
        message: 'Download as CSV',
        fields: [
          {
            key: 'period',
            title: 'Date Period - Metric',
          },
          {
            key: 'currentValue',
            title: 'Data for <%- date?.getInfo(0)?.displayName %>',
          },
          {
            key: 'lastYearValue',
            title:
              'Data for <%- (parseInt(date?.getInfo(0)?.displayName) - 1).toString() %>',
          },
        ],
      },
      salesChart: {
        type: 'bar-chart',
        api: 'farwestSales',
        advancedFilter: [
          {
            type: 'select',
            selectKey: 'timeInterval',
            label: 'Time Interval:',
            options: [
              {
                value: 'business_month',
                label: 'Monthly',
              },
              {
                value: 'business_week',
                label: 'Weekly',
              },
            ],
          },
          {
            type: 'select',
            selectKey: 'channel',
            label: 'Channel:',
            api: 'farwestRevenueCenterSales',
          },
        ],
        width: 1500,
        height: 800,
        label: 'period',
        datasets: [
          {
            chartType: 'line',
            key: 'value',
            label: 'Current Year Sales',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
          {
            key: 'valueLastYear',
            label: 'Prior Year Sales',
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          scales: {
            x: {
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
          },
        },
      },
    },
    '/:brand/sss_chart': {
      variables: ['date'],
      groupFilter: {
        api: 'fwWingstopCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['year'],
        defaultType: 'year',
      },
      pageTitle: {
        type: 'title',
        title: 'SSS/SST/SSC Charts',
      },
      downloadReport: {
        type: 'download-csv',
        api: 'farwestSssCharts',
        fileName: 'getexpo-far-west-sss-sst-ssc-charts',
        message: 'Download as CSV',
        fields: [
          {
            key: 'period',
            title: 'Date Period - Metric',
          },
          {
            key: 'currentValue',
            title: 'Data for <%- date?.getInfo(0)?.displayName %>',
          },
          {
            key: 'lastYearValue',
            title:
              'Data for <%- (parseInt(date?.getInfo(0)?.displayName) - 1).toString() %>',
          },
        ],
      },
      sssChart: {
        type: 'bar-chart',
        api: 'farwestSss',
        advancedFilter: [
          {
            type: 'select',
            selectKey: 'timeInterval',
            label: 'Time Interval:',
            options: [
              {
                value: 'business_month',
                label: 'Monthly',
              },
              {
                value: 'business_week',
                label: 'Weekly',
              },
            ],
          },
          {
            type: 'select',
            selectKey: 'channel',
            label: 'Metics - Channel:',
            api: 'farwestSssChart',
          },
        ],
        width: 1500,
        height: 800,
        label: 'period',
        datasets: [
          {
            chartType: 'line',
            key: 'value',
            label: 'Current Year',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
          {
            key: 'valueLastYear',
            label: 'Prior Year',
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ],
        scaleTypes: {
          y: {
            type: 'percent',
          },
        },
        options: {
          scales: {
            x: {
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
          },
        },
      },
    },
    '/:brand/check_average_chart': {
      variables: ['date'],
      groupFilter: {
        api: 'fwWingstopCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['year'],
        defaultType: 'year',
      },
      pageTitle: {
        type: 'title',
        title: 'Check Average Charts',
      },
      downloadReport: {
        type: 'download-csv',
        api: 'farwestSalesCharts',
        fileName: 'getexpo-far-west-check-average-charts',
        message: 'Download as CSV',
        fields: [
          {
            key: 'period',
            title: 'Date Period - Metric',
          },
          {
            key: 'currentValue',
            title: 'Data for <%- date?.getInfo(0)?.displayName %>',
          },
          {
            key: 'lastYearValue',
            title:
              'Data for <%- (parseInt(date?.getInfo(0)?.displayName) - 1).toString() %>',
          },
        ],
      },
      checkAverageChart: {
        type: 'bar-chart',
        api: 'farwestSales',
        advancedFilter: [
          {
            type: 'select',
            selectKey: 'timeInterval',
            label: 'Time Interval:',
            options: [
              {
                value: 'business_month',
                label: 'Monthly',
              },
              {
                value: 'business_week',
                label: 'Weekly',
              },
            ],
          },
          {
            type: 'select',
            selectKey: 'channel',
            label: 'Channel:',
            api: 'farwestRevenueCenterCheckAverage',
          },
        ],
        width: 1500,
        height: 800,
        label: 'period',
        datasets: [
          {
            chartType: 'line',
            key: 'value',
            label: 'Current Year Check Average',
            decimal: 2,
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
          {
            key: 'valueLastYear',
            label: 'Prior Year Check Average',
            decimal: 2,
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
            decimal: 2,
          },
        },
        options: {
          scales: {
            x: {
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
          },
        },
      },
    },
  },
}

export default fwWingstopFinancialChartConfig
