import { IPropsType as ILinkPropsType } from '../../../tableV2/Link'

interface IListLocationGroupType {
  type: 'listLocationGroup'
  tableColumns: [
    {
      key: 'locationGroup'
      title: string
      width: string
    },
  ]
  locationGroupIds: number[]
  intersectedLocationGroupIds?: number[]
  locationGroups: Record<
    number,
    {
      id: number
      displayName: string
      link?: string
      tableRow: {
        locationGroup: ILinkPropsType
      }
    }
  >
}

interface IListLocationType {
  type: 'listLocation'
  tableColumns: [
    {
      key: 'location'
      title: string
      onClick: 'track.locationClicked'
      width: string
    },
    {
      key: 'groupBy'
      title: string
      onClick: 'track.groupByClicked'
      width: string
    },
  ]
  locationGroupIds: number[]
  locations: Record<
    number,
    {
      id: number
      code: number
      name: number
      displayName: string
      link: string
      groupBy?: {
        id: number
        displayName: string
        link?: string
      }
      tableRow: {
        location: ILinkPropsType & { id: number }
        groupBy: ILinkPropsType & { id: number }
      }
    }
  >
}

interface ILocationType {
  type: 'location'
  locationId: number
  location: {
    id: number
    code: number
    name: number
    displayName: string
    link: string
    groupBy?: {
      id: number
      displayName: string
      link?: string
    }
    tableRow: {
      location: ILinkPropsType & { id: number }
      groupBy: ILinkPropsType & { id: number }
    }
  }
}

export type IGroupFilterVariablesNameType = 'corporateGroup' | 'allStores'
export type IGroupFilterType<
  T = 'listLocationGroup' | 'listLocation' | 'location',
> = T extends 'listLocationGroup'
  ? IListLocationGroupType
  : T extends 'listLocation'
  ? IListLocationType
  : ILocationType

export const buildGroupFilterConfigs = <
  V extends IGroupFilterVariablesNameType,
>(
  variablesName: V,
) =>
  ({
    [`<%- JSON(${variablesName}?.tableColumns) %>`]: 'link',
  } as Record<`<%- JSON(${V}?.tableColumns) %>`, 'link'>)
