import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { BRAND_ID } from 'pared/constants/brands'
import { getBusinessLabel } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter } from '../../dateFilter'
import { CONSTANTS } from '../../variables/hooks/bbb/useBbbConstants'
import { IApiDataType, ISelectDataType } from '../types'
import { useBbbPnlCorporateWithoutBreakdownFilter } from './useBbbCorporateWithoutBreakdownFilter'
import { useCorporateGroupFilterQuery } from './useCorporateFilter'

const findDefaultValue = ([value, ...values]: ISelectDataType[]): string[] => {
  if (values.length === 0) return []

  const item = values.find((v) => v.parentId === value.id)

  if (item) return [value.id, ...findDefaultValue(values)]

  return [value.id]
}

const buildBbbCompanyFilter = (
  filter: (v: ISelectDataType) => boolean = () => true,
  defaultValue?: string[],
) => {
  const useBbbCompanyFilter = () => {
    const { brand } = useBrands()
    const directorLabel = getBusinessLabel('director')
    const { startDate, endDate } = useDateFilter()
    const {
      data: newData,
      loading,
      previousData,
    } = useCorporateGroupFilterQuery(
      {
        iBrandId: BRAND_ID[brand],
        iFilter: {
          // FIXME: should get the constants from the variables
          location_group_ids: [
            CONSTANTS.COMPANY_CORPORATE_DEPARTMENTS_GROUP_ID,
            CONSTANTS.COMPANY_STORE_LOCATION_GROUP_ID,
            CONSTANTS.COMPANY_NOT_SHOW_GROUP_ID,
          ],
          brand_ids: [BRAND_ID[brand]],
        },
        iStartDate: startDate,
        iEndDate: endDate,
      },
      !startDate || !endDate,
    )
    const {
      data: corporateGroupFilterWithoutBreakdownData,
      loading: corporateGroupFilterWithoutBreakdownLoading,
    } = useBbbPnlCorporateWithoutBreakdownFilter()
    const data = newData || previousData
    const { search } = useLocation()

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const locationGroups = (
          data?.getLocationAccessGroupsForBrand?.nodes || []
        ).filter((d) =>
          [
            'Brand',
            'Company',
            'Corporate Departments',
            'District Manager',
            'Legal Entity',
          ].includes(d.type),
        )
        const companies = data.listLocationDetails.nodes.map((l) => {
          const director = data.listDirectors.nodes.find((d) =>
            l.locationGroups.some((lg) => lg.id === d.locationGroupId),
          )
          const locationGroup = locationGroups.find(
            (lg) => lg.id === director?.locationGroupId,
          )
          const queryObject = new URLSearchParams(search)

          queryObject.set('store', l.id.toString())

          const link = `/${brand}/store_detail?${queryObject.toString()}`

          return {
            id: l.id,
            name: `${l.code} - ${l.name}`,
            header: 'Stores',
            link,
            groupBy: director && {
              id: director.employeeId,
              header: locationGroup?.type || directorLabel,
              name: `${director.firstName} ${director.lastName}`,
            },
          }
        })
        const values = [
          {
            id: 'company',
            parentId: 'root',
            ids: locationGroups
              .filter((d) => d.type === 'Company')
              .map((d) => d.id),
            label: 'Company',
            list: companies,
          },
          {
            id: 'legal-entity',
            parentId: 'root',
            label: 'Legal Entity',
          },
          ...(locationGroups
            .filter((lg) => lg.type === 'Legal Entity')
            .map((lg) => ({
              id: lg.id.toString(),
              parentId: 'legal-entity',
              ids: [lg.id],
              label: lg.name,
              list: data.listLocationDetails.nodes
                .filter((l) =>
                  l.locationGroups.some(
                    (slg) =>
                      slg.type === 'Legal Entity' && slg.name === lg.name,
                  ),
                )
                .map((l) => companies.find((c) => c.id === l.id)),
            })) as ISelectDataType[]),
          {
            id: 'corporate-departments',
            parentId: 'root',
            label: 'Corporate Departments',
            ids: locationGroups
              .filter((d) => d.name === 'Corporate Departments')
              .map((d) => d.id),
            list: data.listLocationDetails.nodes
              .filter((l) =>
                l.locationGroups.some(
                  (lg) => lg.name === 'Corporate Departments',
                ),
              )
              .map((l) => companies.find((c) => c.id === l.id)),
          },
          {
            id: 'all-corporate-departments',
            parentId: 'corporate-departments',
            ids: locationGroups
              .filter((d) => d.name === 'Corporate Departments')
              .map((d) => d.id),
            label: 'All Corporate Departments',
            list: data.listLocationDetails.nodes
              .filter((l) =>
                l.locationGroups.some(
                  (lg) => lg.name === 'Corporate Departments',
                ),
              )
              .map((l) => companies.find((c) => c.id === l.id)),
          },
          ...(data.listLocationDetails.nodes
            .filter((l) =>
              l.locationGroups.some(
                (lg) => lg.name === 'Corporate Departments',
              ),
            )
            .map((l) => {
              const company = companies.find((c) => c.id === l.id)

              if (!company) return null

              return {
                id: `Company ${company.id}`,
                parentId: 'corporate-departments',
                ids: [company.id],
                label: company.name,
              }
            }) as ISelectDataType[]),
          {
            ...corporateGroupFilterWithoutBreakdownData?.values.find(
              (v) => v.id === 'all-stores',
            ),
            id: 'stores',
            parentId: 'root',
            label: 'Stores',
          },
          ...(corporateGroupFilterWithoutBreakdownData?.values || []).map(
            ({ parentId, ...d }) => ({
              ...d,
              parentId: parentId === 'root' ? 'stores' : parentId,
            }),
          ),
        ]
          .flat()
          .filter(filter)

        return {
          values,
          defaultValue: defaultValue || findDefaultValue(values),
        }
      }, [
        data,
        brand,
        directorLabel,
        corporateGroupFilterWithoutBreakdownData,
        search,
      ]),
      loading: loading || corporateGroupFilterWithoutBreakdownLoading,
    }
  }

  return useBbbCompanyFilter
}

export const useBbbCompanyFilter = buildBbbCompanyFilter()
export const useBbbExecutiveSummaryFilter = buildBbbCompanyFilter(
  (v) => v.parentId === 'root' && v.id !== 'legal-entity',
)
export const useBbbCorporateOpportunitiesFilter = buildBbbCompanyFilter(
  (v) =>
    ['corporate-departments', 'legal-entity'].includes(v.id) ||
    ['corporate-departments'].includes(v.parentId) ||
    ['BIBIBOP DEVELOPMENT', 'BIBIBOP DEVELOPMENT MARKETING FUND'].includes(
      v.label,
    ),
)
export const useBbbStoreOpportunitiesFilter = buildBbbCompanyFilter(
  (v) =>
    !['company', 'corporate-departments'].includes(v.id) &&
    !['corporate-departments'].includes(v.parentId) &&
    !['BIBIBOP DEVELOPMENT', 'BIBIBOP DEVELOPMENT MARKETING FUND'].includes(
      v.label,
    ),
  ['stores', 'all-stores'],
)
