import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'
import { toPercentString, toUsdStr } from 'pared/utils/number'

import { IApiDataType, IExrayDataType } from '../../types'

interface ILocationLaborSummaryNodeType {
  hour: number
  sales: number
  systemwideSales: number
  differenceSales: number
  laborHours: number
  systemwideLaborHours: number
  differenceLaborHours: number
  annualOpportunityCost: number
  salesPerLaborHour: number
  systemwideSalesPerLaborHour: number
  differenceSalesPerLaborHour: number
  totalSales: number
  totalSystemwideSales: number
  totalDifferenceSales: number
  totalLaborHours: number
  totalSystemwideLaborHours: number
  totalDifferenceLaborHours: number
  totalAnnualOpportunityCost: number
  totalSalesPerLaborHour: number
  totalSystemwideSalesPerLaborHour: number
  totalDifferenceSalesPerLaborHour: number
}

interface IDirectorInfo {
  id: number
  given_name: string
  family_name: string
  preferred_name: string
}

interface ILocationInfo {
  id: number
  code: string
  name: string
  director: IDirectorInfo
}
interface ICorporateLaborLocations extends ILocationInfo {
  costHours: number
  expectHours: number
  sales: number
  expectSales: number
  avgHourlyPayRate: number
  opportunityCost: number
  annualOpportunityCost: number
}

interface ICorporateLaborSummaryNodeType {
  hour: number
  locations: ICorporateLaborLocations[]
  locationNum: number
  annualOpportunityCost: number
  opportunityCost: number
  totalNetSales: number
  annualTotalNetSales: number
}

type ILaborSummaryType =
  | {
      getSalesPerLaborHour: {
        nodes: ILocationLaborSummaryNodeType[]
      }
    }
  | {
      corporateULaborSummary: {
        nodes: ICorporateLaborSummaryNodeType[]
      }
    }

const query = gql`
  query GetSalesPerLaborHour(
    $iBrandLocationGroupId: Int!
    $iLocationGroupId: Int!
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $hasGroupBy: Boolean!
  ) {
    getSalesPerLaborHour(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) @skip(if: $hasGroupBy) {
      nodes {
        hour
        sales
        systemwideSales
        differenceSales
        laborHours
        systemwideLaborHours
        differenceLaborHours
        annualOpportunityCost
        salesPerLaborHour
        systemwideSalesPerLaborHour
        differenceSalesPerLaborHour
        totalSales
        totalSystemwideSales
        totalDifferenceSales
        totalLaborHours
        totalSystemwideLaborHours
        totalDifferenceLaborHours
        totalAnnualOpportunityCost
        totalSalesPerLaborHour
        totalSystemwideSalesPerLaborHour
        totalDifferenceSalesPerLaborHour
      }
    }

    corporateULaborSummary(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) @include(if: $hasGroupBy) {
      nodes {
        hour
        locations
        locationNum
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`

const convertHourToString = (hour: number) => {
  if (hour === 0) {
    return '12am'
  }

  if (hour === 12) {
    return '12pm'
  }

  if (hour < 12) {
    return `${hour}am`
  }

  if (hour > 12) {
    return `${hour - 12}pm`
  }

  return ''
}

const getLocationLaborSummaryData = (
  locationName: string,
  laborData: ILocationLaborSummaryNodeType[],
): IExrayDataType => {
  const topOpportunity = [...laborData].sort((a, b) => {
    if (a.differenceLaborHours === null || b.differenceLaborHours === null) {
      return -1
    }

    return b.differenceLaborHours - a.differenceLaborHours
  })
  const totalAnnualOpportunityCost =
    topOpportunity[0].totalAnnualOpportunityCost > 0
      ? topOpportunity[0].totalAnnualOpportunityCost / 100
      : 0

  return {
    title: 'Labor',
    total: totalAnnualOpportunityCost,
    detail:
      topOpportunity[0].differenceLaborHours < 0
        ? `${locationName} has lower staffing than company averages.`
        : `${locationName}'s biggest opportunities are at ${convertHourToString(
            topOpportunity[0].hour,
          )} (${
            topOpportunity[0].differenceLaborHours?.toFixed(1) ?? '-'
          } hours overstaffed).  Consider changing your scheduling during these hours. ${
            totalAnnualOpportunityCost
              ? `Annual opportunity cost for all hours: ${toUsdStr(
                  totalAnnualOpportunityCost,
                )}`
              : ''
          }`,
  }
}

const getCorporateLaborSummaryData = (
  laborData: ICorporateLaborSummaryNodeType[],
): IExrayDataType => {
  const locationNum = laborData[0]?.locationNum || 1

  const firstStoreCountPercent = toPercentString(
    (100.0 * (laborData[0]?.locations.length || 0)) / locationNum,
    0,
  )
  const firstHour = convertHourToString(laborData[0]?.hour || 0)
  const secondStoreCountPercent = toPercentString(
    (100.0 * (laborData[1]?.locations.length || 0)) / locationNum,
    0,
  )
  const secondHour = convertHourToString(laborData[1]?.hour || 0)
  const thirdStoreCountPercent = toPercentString(
    (100.0 * (laborData[2]?.locations.length || 0)) / locationNum,
    0,
  )
  const thirdHour = convertHourToString(laborData[2]?.hour || 0)

  const laborAnnualOpportunityCost =
    laborData?.reduce((total, row) => total + row.annualOpportunityCost, 0) /
    100.0

  const laborbasisPoint = (
    (10000.0 * laborAnnualOpportunityCost) /
    laborData[0]?.annualTotalNetSales
  ).toFixed(0)

  return {
    title: 'Labor',
    total: laborAnnualOpportunityCost,
    detail: `For Trailing 30 days,
    ${firstStoreCountPercent} of your stores have low productivity at ${firstHour}.
    ${secondStoreCountPercent} of your stores have low productivity at ${secondHour}.
    ${thirdStoreCountPercent} of your stores have low productivity at ${thirdHour}.
    Consider decreased staffing during these times to increase productivity and reduce labor costs by
    ${toUsdStr(laborAnnualOpportunityCost)} annually - which amounts to a
    ${laborbasisPoint} basis points improvement for the entire company.`,
    hasDetails: true,
  }
}

const useLabor = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const locationGroupId = groupFilter?.ids[0] || 0
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'
  const { brand } = useBrands()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]

  const { data, loading } = useQuery<ILaborSummaryType>(query, {
    variables: {
      iBrandLocationGroupId: brandLocationGroupId,
      iLocationGroupId: locationGroupId,
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
      hasGroupBy,
    },
    skip: !brandLocationGroupId || !groupFilter || !startDate || !endDate,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      if ('getSalesPerLaborHour' in data) {
        return getLocationLaborSummaryData(
          locationName,
          data.getSalesPerLaborHour.nodes,
        )
      } else {
        return getCorporateLaborSummaryData(data.corporateULaborSummary.nodes)
      }
    }, [locationName, data]),
    loading,
  }
}

export default useLabor
