import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import buildMetricValuesHook from 'pared/Routes/renderer/utils/unifiedEndpoint/buildMetricValuesHook'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DATE_FORMAT } from 'pared/data/getDateRanges'

import { IApiDataType } from '../../types'

interface IGuestSummaryNodeType {
  problemPercentage: number
  mostCommonDay: string[]
  mostCommonDaypart: string[]
}

interface IGuestSummaryType {
  locationGuestSummaryV3: {
    nodes: IGuestSummaryNodeType[]
  }
}

const query = gql`
  query LocationGuestSummaryV3(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
  ) {
    locationGuestSummaryV3(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocationId: $iLocationId
    ) {
      nodes {
        problemPercentage
        mostCommonDay
        mostCommonDaypart
      }
    }
  }
`

const useLocationSmgKpis = buildMetricValuesHook({
  groupFilterType: 'store',
  metrics: {
    codes: [
      'smg_inaccurate_count',
      'smg_bad_taste_count',
      'smg_order_not_on_time_count',
      'smg_rude_team_members_count',
      'smg_not_clean_count',
    ],
  },
})

const useLocationGuest = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'

  const { data: guestReviewData, loading: isGuestReviewDataLoading } =
    useQuery<IGuestSummaryType>(query, {
      variables: {
        iLocationId: groupFilter?.ids?.[0],
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !groupFilter,
    })

  const { data: locationSmgData, loading: isLocationSmgDataLoading } =
    useLocationSmgKpis({ startDate, endDate })

  return {
    data: useMemo(() => {
      if (!guestReviewData || !locationSmgData) return null

      const guestReviewSummary = guestReviewData.locationGuestSummaryV3.nodes[0]

      const areasToImprove: string[] = []
      if (locationSmgData?.smgBadTasteCount) {
        areasToImprove.push('Taste')
      }
      if (locationSmgData?.smgInaccurateCount) {
        areasToImprove.push('Accuracy')
      }
      if (locationSmgData?.smgNotCleanCount) {
        areasToImprove.push('Not Clean')
      }
      if (locationSmgData?.smgOrderNotOnTimeCount) {
        areasToImprove.push('Order Not On Time')
      }
      if (locationSmgData?.smgRudeTeamMembersCount) {
        areasToImprove.push('Rude Team Members')
      }

      const allInsights: string[] = []
      if (areasToImprove.length > 0) {
        allInsights.push(
          `the top problem ${
            areasToImprove.length > 1 ? 'were' : 'was'
          }: ${areasToImprove.join(', ')}.`,
        )
      }
      if (Array.isArray(guestReviewSummary?.mostCommonDay)) {
        allInsights.push(
          `${guestReviewSummary.mostCommonDay
            .map((day: string) => `${day}`)
            .join(', ')} have the most negative reviews.`,
        )
      }
      if (allInsights.length > 0) {
        allInsights.unshift(`For ${locationName},`)
        allInsights.push('Address these issues with your team.')
      }

      const insightMessage =
        allInsights.length > 0
          ? allInsights.join(' ')
          : 'Data is not enough to generate insights ...'

      const hasEnoughData =
        locationName && guestReviewSummary && locationSmgData

      return {
        title: 'Guest',
        total: null,
        detail: hasEnoughData ? insightMessage : 'Loading ...',
      }
    }, [
      locationName,
      guestReviewData,
      locationSmgData,
      isGuestReviewDataLoading,
      isLocationSmgDataLoading,
    ]),
    loading: isGuestReviewDataLoading || isLocationSmgDataLoading,
  }
}

export default useLocationGuest
