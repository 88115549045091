import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter } from 'pared/Routes/renderer/dateFilter'

import calc from '../../../utils/calc'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const bbbOpsDashboardConfigs = {
  opsDashboardName: 'string',
  opsDashboardValue: 'string',
} as const

const ITEMS = {
  compSalesPercentQ: 'Comp Sales % - Current Year Q',
  compSalesPercentQTD: 'Comp Sales % - Current Year Quarter to Date',
  compSalesPercentPTD: 'Comp Sales % - Current Year Period to Date',
  compSalesPercent: 'Comp Sales % - Current Year Current Week',
  compSalesPercentYTD: 'Comp Sales % - Current Year Year to Date',

  compTrafficPercentQ: 'Comp Traffic % - Current Year Q',
  compTrafficPercentQTD: 'Comp Traffic % - Current Year Quarter to Date',
  compTrafficPercentPTD: 'Comp Traffic % - Current Year Period to Date',
  compTrafficPercent: 'Comp Traffic % - Current Year Current Week',
  compTrafficPercentYTD: 'Comp Traffic % - Current Year Year to Date',

  fnpPercentQ: 'F&P % - Current Year Q',
  fnpPercentQTD: 'F&P % - Current Year Quarter to Date',
  fnpPercentPTD: 'F&P % - Current Year Period to Date',
  fnpPercent: 'F&P % - Current Year Current Week',
  fnpPercentYTD: 'F&P % - Current Year Year to Date',

  laborPercentQ: 'Labor % - Current Year Q',
  laborPercentQTD: 'Labor % - Current Year Quarter to Date',
  laborPercentPTD: 'Labor % - Current Year Period to Date',
  laborPercent: 'Labor % - Current Year Current Week',
  laborPercentYTD: 'Labor % - Current Year Year to Date',

  offPremisePercentQ: 'Off Premise % - Current Year Q',
  offPremisePercentQTD: 'Off Premise % - Current Year Quarter to Date',
  offPremisePercentPTD: 'Off Premise % - Current Year Period to Date',
  offPremisePercent: 'Off Premise % - Current Year Current Week',
  offPremisePercentYTD: 'Off Premise % - Current Year Year to Date',

  digitalPercentQ: 'Digital % - Current Year Q',
  digitalPercentQTD: 'Digital % - Current Year Quarter to Date',
  digitalPercentPTD: 'Digital % - Current Year Period to Date',
  digitalPercent: 'Digital % - Current Year Current Week',
  digitalPercentYTD: 'Digital % - Current Year Year to Date',

  cateringPercentQ: 'Catering % - Current Year Q',
  cateringPercentQTD: 'Catering % - Current Year Quarter to Date',
  cateringPercentPTD: 'Catering % - Current Year Period to Date',
  cateringPercent: 'Catering % - Current Year Current Week',
  cateringPercentYTD: 'Catering % - Current Year Year to Date',

  starsPercentQ: 'Stars - Current Year Q',
  starsPercentQTD: 'Stars - Current Year Quarter to Date',
  starsPercentPTD: 'Stars - Current Year Period to Date',
  starsPercent: 'Stars - Current Year Current Week',
  starsPercentYTD: 'Stars - Current Year Year to Date',

  complaintsPercentQ: 'Complaints - Current Year Q',
  complaintsPercentQTD: 'Complaints - Current Year Quarter to Date',
  complaintsPercentPTD: 'Complaints - Current Year Period to Date',
  complaintsPercent: 'Complaints - Current Year Current Week',
  complaintsPercentYTD: 'Complaints - Current Year Year to Date',
} as const

const useBbbCompOpsBoard = buildMetricValuesHook({
  variablesName: 'allStores',
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'net_sales_sss_percent',
      'check_count',
      { key: 'check_count', type: 'yoy' },
    ],
  },
  handler: (data) => ({
    compSalesPercent: data.netSalesSssPercent,
    compTrafficPercent: calc(
      data.checkCount,
      'variancePercentageOf',
      data.yoyCheckCount,
    ),
  }),
  hasSummary: true,
})

const useBbbCommonOpsBoard = buildMetricValuesHook({
  variablesName: 'allStores',
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'total_sales',
      'total_labor',
      'total_food_and_paper',
      'online',
      'catering',
      'yext_review_average_stars',
      'yext_review_complaint_percent',
    ],
  },
  handler: (data) => ({
    fnpPercent: calc(data.totalFoodAndPaper, 'percentageOf', data.totalSales),
    laborPercent: calc(data.totalLabor, 'percentageOf', data.totalSales),
    offPremisePercent: calc(
      (data.online, '+', data.catering),
      'percentageOf',
      data.totalSales,
    ),
    digitalPercent: calc(data.online, 'percentageOf', data.totalSales),
    cateringPercent: calc(data.catering, 'percentageOf', data.totalSales),
    starsPercent: data.yextReviewAverageStars,
    complaintsPercent: data.yextReviewComplaintPercent,
  }),
  hasSummary: true,
})

const format = (suffix: string) => (data: NonNullable<IApiDataType>[number]) =>
  _.mapKeys(
    _.mapValues(data, (value, key) => {
      const newValue = parseFloat(value as string)

      if (isNaN(newValue)) return null

      return [newValue.toFixed(2), /^starsPercent/.test(key) ? '' : '%'].join(
        '',
      )
    }),
    (_, key) =>
      ['location', 'groupBy'].includes(key) ? key : `${key}${suffix}`,
  )

const useBbbOpsDashboardWithDate = (
  suffix: string,
  startDate?: string,
  endDate?: string,
) => {
  const { variables } = useVariables()
  const comp = useBbbCompOpsBoard(
    !variables.bbbConstants
      ? {
          startDate,
          endDate,
          skip: !startDate || !endDate,
        }
      : {
          startDate,
          endDate,
          skip: !startDate || !endDate,
          intersectedLocationGroupIds: [variables.bbbConstants.COMP_GROUP_ID],
        },
  )
  const common = useBbbCommonOpsBoard({
    startDate,
    endDate,
    skip: !startDate || !endDate,
  })

  return useMemo(
    () => [
      {
        data: comp.data?.filter((d) => d.id === 'summary')?.map(format(suffix)),
        loading: comp.loading,
      },
      {
        data: common.data
          ?.filter((d) => d.id === 'summary')
          ?.map(format(suffix)),
        loading: common.loading,
      },
    ],
    [comp, common],
  )
}

const opsDashboardDateQuery = gql`
  query opsDashboardDate($iDate: Date!) {
    opsDashboardDate(iDate: $iDate) {
      nodes {
        ytdStartDate
        businessMonthBeginDate
        quarters
      }
    }
  }
`

const useOpsDashboardDate = () => {
  const { startDate, endDate } = useDateFilter()
  const { data } = useQuery(opsDashboardDateQuery, {
    variables: {
      iDate: endDate,
    },
    skip: !endDate,
  })

  return useMemo(() => {
    if (!startDate || !endDate || !data) return null

    const { ytdStartDate, businessMonthBeginDate, quarters } =
      data.opsDashboardDate.nodes[0]
    const qtd = quarters.filter(
      (q: { start_date: string; end_date: string }) =>
        moment(q.start_date).isSameOrBefore(startDate) &&
        moment(q.end_date).isSameOrAfter(endDate),
    )?.[0]

    return {
      ...quarters.reduce(
        (
          result: Record<`Q${number}`, [string, string] | undefined>,
          {
            business_quarter,
            start_date,
            end_date,
          }: {
            business_quarter: number
            start_date: string
            end_date: string
          },
        ) => ({
          ...result,
          [`Q${business_quarter}`]: moment(endDate).isSameOrBefore(
            moment.utc(end_date),
          )
            ? []
            : [start_date, end_date],
        }),
        {} as Record<`Q${number}`, [string, string] | undefined>,
      ),
      currentWeek: [startDate, endDate],
      ytd: [ytdStartDate, endDate],
      ptd: [businessMonthBeginDate, endDate],
      qtd: !qtd ? [] : [qtd.start_date, endDate],
    }
  }, [startDate, endDate, data])
}

const useBbbOpsDashboard = () => {
  const opsDashboardDate = useOpsDashboardDate()

  const currentWeek = useBbbOpsDashboardWithDate(
    '',
    opsDashboardDate?.currentWeek[0],
    opsDashboardDate?.currentWeek[1],
  )
  const ytd = useBbbOpsDashboardWithDate(
    'YTD',
    opsDashboardDate?.ytd[0],
    opsDashboardDate?.ytd[1],
  )
  const ptd = useBbbOpsDashboardWithDate(
    'PTD',
    opsDashboardDate?.ptd[0],
    opsDashboardDate?.ptd[1],
  )
  const qtd = useBbbOpsDashboardWithDate(
    'QTD',
    opsDashboardDate?.qtd[0],
    opsDashboardDate?.qtd[1],
  )
  const q1 = useBbbOpsDashboardWithDate(
    'Q1',
    opsDashboardDate?.Q1[0],
    opsDashboardDate?.Q1[1],
  )
  const q2 = useBbbOpsDashboardWithDate(
    'Q2',
    opsDashboardDate?.Q2[0],
    opsDashboardDate?.Q2[1],
  )
  const q3 = useBbbOpsDashboardWithDate(
    'Q3',
    opsDashboardDate?.Q3[0],
    opsDashboardDate?.Q3[1],
  )
  const q4 = useBbbOpsDashboardWithDate(
    'Q4',
    opsDashboardDate?.Q4[0],
    opsDashboardDate?.Q4[4],
  )
  const allData = useMemo(
    () => [...currentWeek, ...ytd, ...ptd, ...qtd, ...q1, ...q2, ...q3, ...q4],
    [currentWeek, ytd, ptd, qtd, q1, q2, q3, q4],
  )
  const isLoading = allData.some((c) => c.loading)

  return {
    data: useMemo((): IApiDataType => {
      if (!opsDashboardDate || isLoading) return null

      const quarters = Object.keys(opsDashboardDate || {}).filter(
        (key) => /^Q/.test(key) && opsDashboardDate[key].length !== 0,
      )
      const data = allData.reduce(
        (result, d) => ({
          ...result,
          ...((d.data?.[0] || {}) as Record<string, string | null>),
        }),
        {} as Record<string, string | null>,
      )

      return (Object.keys(ITEMS) as (keyof typeof ITEMS)[])
        .map((item) => {
          if (/Q$/.test(item))
            return quarters.map((q) => {
              const key = item.replace(/Q$/, q)

              return {
                id: key,
                parentId: 'root',
                opsDashboardName: ITEMS[item].replace(/Q$/, q),
                opsDashboardValue: data[key],
              }
            })

          return {
            id: item,
            parentId: 'root',
            opsDashboardName: ITEMS[item],
            opsDashboardValue: data[item],
          }
        })
        .flat()
    }, [opsDashboardDate, allData, isLoading]),
    loading: isLoading || !opsDashboardDate,
  }
}

export default useBbbOpsDashboard
