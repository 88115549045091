import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const burgerworksListLocationGroupSalesmanshipKpisConfigs = {
  checkAverage: 'price',
  itemAttachmentRateDesserts: 'percent',
  itemAttachmentRateBeverages: 'percent',
  itemAttachmentRateBacon: 'percent',
  itemAttachmentRateCheese: 'percent',
  itemAttachmentRateJalapeno: 'percent',
  salesPerLaborHour: 'price',
  upsizedCountPercentage: 'percent',
} as const

const useBurgerworksListLocationGroupSalesmanshipKpis = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'check_average',
      'sales_per_labor_hour',
      'upsized_count_percentage',
    ],
    groups: ['Expo Module - Item Attachment Rate'],
  },
})

export default useBurgerworksListLocationGroupSalesmanshipKpis
