import { corporateGroupTableConfigs } from '../../../../variables'
import buildMetricValuesHook from '../../utils/buildMetricValuesHook'

export const burgerworksListLocationGroupGuestDataConfigs = {
  ...corporateGroupTableConfigs,
  googleRating: 'number',
  googleRatingCount: 'number',
  yelpRating: 'number',
  yelpRatingCount: 'number',
  deliveryAvgCustomerReviewScoreDoordash: 'number',
  smgTotalSurveyCount: 'number',
  smgOverallSatisfactionCount: 'number',
  smgOverallSatisfactionRating: 'number',
  smgAccuracyOfOrderRating: 'number',
  smgOrderNotOnTimeRating: 'number',
  smgFriendlinessOfEmployeesRating: 'number',
  smgTasteOfFoodRating: 'number',
  smgOverallCleanlinessRating: 'number',
} as const

const useBurgerworkListLocationGroupGuestData = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['delivery_avg_customer_review_score_doordash'],
    groups: ['Expo Module - Guest', 'Expo Module - Location SMG'],
  },
})

export default useBurgerworkListLocationGroupGuestData
