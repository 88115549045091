import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../../dateFilter'
import { useGroupFilter } from '../../../../groupFilter'
import { IApiDataType } from '../../../types'

interface IDataType {
  listLocationUDeliveryDayTrends: {
    nodes: {
      locationId: number
      dayOfWeek: number
      dayOfWeekName: string
      allTotalOrderCount: number
      lunchTotalOrderCount: number
      dinnerTotalOrderCount: number
      allOrderWithAnyIssuePercent: number
      lunchOrderWithAnyIssuePercent: number
      dinnerOrderWithAnyIssuePercent: number
      allInaccurateOrderPercent: number
      lunchInaccurateOrderPercent: number
      dinnerInaccurateOrderPercent: number
      allCancelledOrderPercent: number
      lunchCancelledOrderPercent: number
      dinnerCancelledOrderPercent: number
      allDelayedOrderPercent: number
      lunchDelayedOrderPercent: number
      dinnerDelayedOrderPercent: number
      allAvgCustomerReviewScore: number
      lunchAvgCustomerReviewScore: number
      dinnerAvgCustomerReviewScore: number
    }[]
  }
}

const query = gql`
  query ListLocationUDeliveryDayTrends(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationUDeliveryDayTrends(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locationId
        dayOfWeek
        dayOfWeekName
        allTotalOrderCount
        lunchTotalOrderCount
        dinnerTotalOrderCount
        allOrderWithAnyIssuePercent
        lunchOrderWithAnyIssuePercent
        dinnerOrderWithAnyIssuePercent
        allInaccurateOrderPercent
        lunchInaccurateOrderPercent
        dinnerInaccurateOrderPercent
        allCancelledOrderPercent
        lunchCancelledOrderPercent
        dinnerCancelledOrderPercent
        allDelayedOrderPercent
        lunchDelayedOrderPercent
        dinnerDelayedOrderPercent
        allAvgCustomerReviewScore
        lunchAvgCustomerReviewScore
        dinnerAvgCustomerReviewScore
      }
    }
  }
`

export const burgerworksListLocationDeliveryDayTrendsDataConfigs = {
  dayOfWeekName: 'string',
  allTotalOrderCount: 'number',
  lunchTotalOrderCount: 'number',
  dinnerTotalOrderCount: 'number',
  allOrderWithAnyIssuePercent: 'percent',
  lunchOrderWithAnyIssuePercent: 'percent',
  dinnerOrderWithAnyIssuePercent: 'percent',
  allInaccurateOrderPercent: 'percent',
  lunchInaccurateOrderPercent: 'percent',
  dinnerInaccurateOrderPercent: 'percent',
  allCancelledOrderPercent: 'percent',
  lunchCancelledOrderPercent: 'percent',
  dinnerCancelledOrderPercent: 'percent',
  allDelayedOrderPercent: 'percent',
  lunchDelayedOrderPercent: 'percent',
  dinnerDelayedOrderPercent: 'percent',
  allAvgCustomerReviewScore: 'number',
  lunchAvgCustomerReviewScore: 'number',
  dinnerAvgCustomerReviewScore: 'number',
} as const

const useListLocationDeliveryDayTrendsData = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iLocationId: groupFilter?.ids?.[0] || 0,
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      return data.listLocationUDeliveryDayTrends.nodes.map((n) => ({
        id: n.dayOfWeekName,
        parentId: 'root',
        ...n,
      }))
    }, [data]),
    loading: loading,
  }
}

export default useListLocationDeliveryDayTrendsData
