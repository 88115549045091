import { IRouteType } from '../../types'
import fwUploadReportConfig from './fwUploadReport'
import fwWingstopDailyOps from './fwWingstopDailyOps'
import fwWingstopDailySalesKpisReport from './fwWingstopDailySalesKpisReport'
import fwWingstopExpoAi from './fwWingstopExpoAi'
import fwWingstopFinancialChart from './fwWingstopFinancialChart'
import fwWingstopFlashCogsTracker from './fwWingstopFlashCogsTracker'
import fwWingstopFlashDiscountsAndVoids from './fwWingstopFlashDiscountsAndVoids'
import fwWingstopFlashFoodCost from './fwWingstopFlashFoodCost'
import fwWingstopFlashProductMix from './fwWingstopFlashProductMix'
import fwWingstopFlashSalesAndTicketCompReport from './fwWingstopFlashSalesAndTicketCompReport'
import fwWingstopFlashSalesByOrderMode from './fwWingstopFlashSalesByOrderMode'
import fwWingstopFlashWeeklyLaborTracker from './fwWingstopFlashWeeklyLaborTracker'
import fwWingstopGroupManagement from './fwWingstopGroupManagement'
import fwWingstopGuestConfig from './fwWingstopGuest'
import fwWingstopHome from './fwWingstopHome'
import fwWingstopLocationManagement from './fwWingstopLocationManagement'
import fwWingstopLossPreventionConfig from './fwWingstopLossPreventionConfig'
import fwWingstopOriginalStorePages from './fwWingstopOriginalStorePages'
import fwWingstopOutliers from './fwWingstopOutliers'
import fwWingstopTimeClock from './fwWingstopTimeClock'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Expo AI',
        link: '/:brand/expo_ai',
        hideFromNavMenu: true,
      },
      {
        title: 'Daily Ops',
        link: '/:brand/daily_ops',
      },
      {
        title: 'Outliers',
        link: '/:brand/outliers',
      },
      {
        title: 'Time Clock',
        link: '/:brand/corporate_time_clock',
      },
      {
        title: 'Daily Sales KPIs Report',
        link: '/:brand/daily_sales_kpis_report',
      },
      {
        title: 'Financial Chart',
        collapsible: true,
        children: [
          {
            title: 'Total Sales',
            link: '/:brand/sales_chart',
          },
          {
            title: 'SSS/SST/SSC',
            link: '/:brand/sss_chart',
          },
          {
            title: 'Check Average',
            link: '/:brand/check_average_chart',
          },
        ],
      },
      {
        title: 'Customized Reports',
        collapsible: true,
        children: [
          {
            title: 'Sales and Ticket Comp Report',
            link: '/:brand/sales_and_ticket_comp',
          },
          {
            title: 'Discounts and Voids',
            link: '/:brand/discounts_and_voids',
          },
          {
            title: 'Food Cost by Store',
            link: '/:brand/food_cost',
          },
          {
            title: 'Weekly Labor Tracker',
            link: '/:brand/weekly_labor_tracker',
          },
          {
            title: 'Sales by Order Mode',
            link: '/:brand/sales_by_order_mode',
          },
          {
            title: 'Cogs Tracker',
            link: '/:brand/cogs_tracker',
          },
          {
            title: 'Product Mix by Week',
            link: '/:brand/product_mix',
          },
        ],
      },
      {
        title: 'Loss Prevention',
        link: '/:brand/corporate_loss_prevention',
        hideFromNavMenu: true,
      },
      {
        title: 'Upload Reports',
        link: '/:brand/upload-reports',
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Home',
        link: '/:brand/home',
        hideFromNavMenu: true,
      },
      {
        title: 'Store Performance',
        link: '/:brand/store_detail',
        useOriginal: true,
      },
      {
        title: 'Loss Prevention',
        link: '/:brand/loss_prevention',
        hideFromNavMenu: true,
      },
      {
        title: 'Ops Excellence',
        link: '/:brand/guest',
      },
      {
        title: 'Delivery',
        link: '/:brand/delivery',
        useOriginal: true,
      },
    ],
  },
  {
    title: 'Administration',
    children: [
      {
        title: 'Groups',
        link: '/:brand/groups',
      },
      {
        title: 'Locations',
        link: '/:brand/locations',
      },
    ],
  },
]

export default [
  fwWingstopFlashSalesAndTicketCompReport,
  fwWingstopFlashWeeklyLaborTracker,
  fwWingstopFlashCogsTracker,
  fwWingstopFlashProductMix,
  fwWingstopFlashDiscountsAndVoids,
  fwWingstopFlashFoodCost,
  fwWingstopDailyOps,
  fwWingstopOutliers,
  fwWingstopFlashSalesByOrderMode,
  fwWingstopTimeClock,
  fwUploadReportConfig,
  fwWingstopOriginalStorePages,
  fwWingstopGroupManagement,
  fwWingstopLocationManagement,
  fwWingstopDailySalesKpisReport,
  fwWingstopFinancialChart,
  fwWingstopLossPreventionConfig,
  fwWingstopGuestConfig,
  fwWingstopExpoAi,
  fwWingstopHome,
]
