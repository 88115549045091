import { IApiDataType } from '../types'
import { api as arbApi, configs as arbConfigs } from './arb'
import {
  api as burgerworksApi,
  configs as burgerworksConfigs,
} from './burgerworks'
import { api as commonApi, configs as commonConfigs } from './common'
import {
  api as fwWingstopApi,
  configs as fwWingstopConfigs,
} from './fw_wingstop'
import { api as lfrApi, configs as lfrConfigs } from './lfr'

export type IApiKeyType = keyof typeof api

export const configs = {
  ...commonConfigs,
  ...burgerworksConfigs,
  ...fwWingstopConfigs,
  ...arbConfigs,
  ...lfrConfigs,
}

const api = {
  ...commonApi,
  ...burgerworksApi,
  ...fwWingstopApi,
  ...arbApi,
  ...lfrApi,
}

const useApi = (
  apiName: IApiKeyType,
): { data: IApiDataType; loading: boolean } => api[apiName]()

export default useApi
