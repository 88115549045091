import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT' | 'SECONDS' | 'SCORE'
    value: number
  }
>

type IDataType<
  T extends string =
    | 'listLocationGroupMetricValues'
    | 'listLocationMetricValues',
> = Record<
  T,
  {
    nodes: ((T extends 'listLocationGroupMetricValues'
      ? {
          locationGroupId: number
        }
      : {
          locationId: number
        }) &
      Record<'metricSummaryData', IMetricDataType>)[]
  }
>

const query = gql`
  query metricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $isLocationGroup: Boolean!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isLocationGroup) {
      nodes {
        locationGroupId
        metricSummaryData
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isLocationGroup) {
      nodes {
        locationId
        metricSummaryData
      }
    }
  }
`

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    switch (value.unit) {
      case 'PERCENTAGE':
      case 'DOLLAR':
        return {
          ...result,
          [_.camelCase(key)]: value.value * 100,
        }
      case 'SECONDS':
        return {
          ...result,
          [_.camelCase(key)]: value.value / 60.0,
        }
      default:
        return {
          ...result,
          [_.camelCase(key)]: value.value,
        }
    }
  }, {})

type IMetricType = string | { key: string; type: 'yoy' }

const buildMetricValuesHook = (
  options: {
    groupFilterType: 'corporate' | 'store'
    metrics: { codes?: IMetricType[]; groups?: IMetricType[] }
  },
  handler: (data: Record<string, unknown>) => Record<string, unknown> = (
    data,
  ) => data,
) => {
  const useMetricValues = () => {
    const { startDate, endDate } = useDateFilter()
    const { groupFilter } = useGroupFilter()
    const { locationFilter, isLocationGroup } = (() => {
      if (options.groupFilterType === 'corporate') {
        return {
          locationFilter: {
            location_group_ids: groupFilter?.ids,
          },
          isLocationGroup: true,
        }
      } else {
        return {
          locationFilter: {
            location_ids: groupFilter?.ids,
          },
          isLocationGroup: false,
        }
      }
    })()
    const { data, loading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          ...locationFilter,
          metrics:
            options.metrics.codes
              ?.map((m) => (typeof m === 'string' ? m : null))
              ?.filter(Boolean) || [],
          metric_groups:
            options.metrics.groups
              ?.map((m) => (typeof m === 'string' ? m : null))
              ?.filter(Boolean) || [],
        },
        isLocationGroup,
      },
      skip:
        !startDate ||
        !endDate ||
        !groupFilter ||
        (!options.metrics?.codes?.some((m) => typeof m === 'string') &&
          !options.metrics?.groups?.some((m) => typeof m === 'string')),
    })
    const { data: yoyData, loading: yoyLoading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          ...locationFilter,
          metrics: options.metrics.codes
            ?.map((m) =>
              typeof m !== 'string' && m.type === 'yoy' ? m.key : null,
            )
            ?.filter(Boolean),
          metric_groups:
            options.metrics.groups
              ?.map((m) =>
                typeof m !== 'string' && m.type === 'yoy' ? m.key : null,
              )
              ?.filter(Boolean) || [],
          use_yoy: true,
        },
        isLocationGroup,
      },
      skip:
        !startDate ||
        !endDate ||
        !groupFilter ||
        (!options.metrics?.codes?.some(
          (m) => typeof m !== 'string' && m.type === 'yoy',
        ) &&
          !options.metrics?.groups?.some(
            (m) => typeof m !== 'string' && m.type === 'yoy',
          )),
    })

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const nodes = (
          data.listLocationGroupMetricValues || data.listLocationMetricValues
        ).nodes.find((n) => n.metricSummaryData)
        const yoyNodes = (
          yoyData?.listLocationGroupMetricValues ||
          yoyData?.listLocationMetricValues
        )?.nodes?.find((n) => n.metricSummaryData)

        return {
          ...handler(
            format({
              ...(nodes?.metricSummaryData || {}),
              ..._.mapKeys(
                yoyNodes?.metricSummaryData || {},
                (_, key) => `yoy_${key}`,
              ),
            }),
          ),
        }
      }, [data, yoyData]),
      loading: loading || yoyLoading,
    }
  }

  return useMetricValues
}

export default buildMetricValuesHook
