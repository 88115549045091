import { IConfigsType } from '../../types'

const primantiConfig: IConfigsType = {
  primanti: {
    '/:brand/corporate_guest': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      kpis: {
        type: 'list',
        api: 'arbListLocationGroupPrimantiTattleKpis',
        title: 'Systemwide Guest',
        fieldWidth: '150px',
        fields: [
          {
            content: {
              key: 'avgScore',
              decimal: 1,
            },
            bottom: 'Average Rating',
          },
          {
            content: {
              key: 'negativeReviews',
            },
            bottom: 'Negative Reviews',
          },
          {
            content: {
              key: 'totalReviews',
            },
            bottom: 'Total Reviews',
          },
        ],
      },
      categoryRatings: {
        type: 'table',
        api: 'arbListLocationGroupPrimantiTattleCategoryRating',
        title: 'Category Ratings',
        defaultSortedField: 'label',
        rowKey: 'label',
        pageSize: 10,
        columns: [
          {
            key: 'label',
            title: 'Category',
            sortable: true,
          },
          {
            key: 'avgScore',
            title: 'Average Rating',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'incidentInvolvement',
            title: 'Incident Involvement',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
        ],
      },
      tattleBreakdownByStore: {
        type: 'table',
        api: 'arbPrimantiTattle',
        title: 'Tattle Breakdown By Store',
        pageSize: 10,
        defaultSortedField: 'groupInfo',
        columns: [
          {
            key: 'totalReviews',
            title: 'Total Reviews',
            align: 'center',
            sortable: true,
          },
          {
            key: 'avgScore',
            title: 'Avg Score',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'negativeReviews',
            title: 'Negative Reviews',
            align: 'center',
            sortable: true,
          },
          {
            key: 'numFiveStar',
            title: '5 star',
            align: 'center',
            sortable: true,
          },
          {
            key: 'numFourStar',
            title: '4 star',
            align: 'center',
            sortable: true,
          },
          {
            key: 'numThreeStar',
            title: '3 star',
            align: 'center',
            sortable: true,
          },
          {
            key: 'numTwoStar',
            title: '2 star',
            align: 'center',
            sortable: true,
          },
          {
            key: 'numOneStar',
            title: '1 star',
            align: 'center',
            sortable: true,
          },
        ],
      },
    },
    '/:brand/guest': {
      groupFilter: {
        api: 'locationFilter',
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      kpis: {
        type: 'list',
        api: 'arbListLocationPrimantiTattleKpis',
        title: 'Guest',
        fieldWidth: '150px',
        fields: [
          {
            content: {
              key: 'avgScore',
              decimal: 1,
            },
            bottom: 'Average Rating',
          },
          {
            content: {
              key: 'negativeReviews',
            },
            bottom: 'Negative Reviews',
          },
          {
            content: {
              key: 'totalReviews',
            },
            bottom: 'Total Reviews',
          },
        ],
      },
      categoryRatings: {
        type: 'table',
        api: 'arbListLocationPrimantiTattleCategoryRating',
        title: 'Category Ratings',
        defaultSortedField: 'label',
        rowKey: 'label',
        pageSize: 10,
        columns: [
          {
            key: 'label',
            title: 'Category',
            sortable: true,
          },
          {
            key: 'avgScore',
            title: 'Average Rating',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'incidentInvolvement',
            title: 'Incident Involvement',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
        ],
      },
    },
  },
}

export default primantiConfig
