import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const burgerworksCorporateSalesmanshipConfig: IConfigsType = {
  burgerworks: {
    '/:brand/corporate_salesmanship': {
      variables: ['corporateGroup', 'lastTimeRange'],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      title: {
        type: 'title',
        title: 'Salesmanship',
      },
      listLocationGroupSalesmanshipKpis: {
        type: 'list',
        api: 'burgerworksListLocationGroupSalesmanshipKpis',
        fieldWidth: '125px',
        fields: [
          {
            content: {
              key: 'checkAverage',
              decimal: 2,
            },
            bottom: 'Check Average',
          },
          {
            content: {
              key: 'itemAttachmentRateDesserts',
            },
            bottom: 'Desserts Attachment Rate',
          },
          {
            content: {
              key: 'itemAttachmentRateBeverages',
            },
            bottom: 'Beverages Attachment Rate',
          },
          {
            content: {
              key: 'itemAttachmentRateBacon',
            },
            bottom: 'Bacon Attachment Rate',
          },
          {
            content: {
              key: 'itemAttachmentRateCheese',
            },
            bottom: 'Cheese Attachment Rate',
          },
          {
            content: {
              key: 'itemAttachmentRateJalapeno',
            },
            bottom: 'Jalapeno Attachment Rate',
          },
          {
            content: {
              key: 'salesPerLaborHour',
              decimal: 2,
            },
            bottom: 'Sales per Labor Hour',
          },
          {
            content: {
              key: 'upsizedCountPercentage',
            },
            bottom: '% Upsized',
          },
        ],
      },
      attachmentRateTrendTimeRangeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Attachment Rate Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
            label: ' ',
          },
        },
      },
      attachmentRateTrendChart: {
        type: 'line-chart',
        api: 'burgerworksTrendLocationGroupSalesmanshipKpis',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'itemAttachmentRateDesserts',
            label: 'Desserts Attachment Rate',
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
            chartType: 'line',
          },
          {
            key: 'itemAttachmentRateBeverages',
            label: 'Beverages Attachment Rate',
            borderColor: COLORS.Mango,
            backgroundColor: COLORS.Mango,
            chartType: 'line',
          },
          {
            key: 'itemAttachmentRateBacon',
            label: 'Bacon Attachment Rate',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
            chartType: 'line',
          },
          {
            key: 'itemAttachmentRateCheese',
            label: 'Cheese Attachment Rate',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
            chartType: 'line',
          },
          {
            key: 'itemAttachmentRateJalapeno',
            label: 'Jalapeno Attachment Rate',
            borderColor: COLORS.DarkOrchid,
            backgroundColor: COLORS.DarkOrchid,
            chartType: 'line',
          },
          {
            key: 'checkAverage',
            label: 'Check Average',
            decimal: 2,
            borderColor: COLORS.Smoke,
            backgroundColor: COLORS.Smoke,
            chartType: 'bar',
            yAxisID: 'y1',
          },
        ],
        scaleTypes: {
          y: {
            type: 'percent',
          },
          y1: {
            type: 'price',
            decimal: 2,
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: '%',
              },
            },
            y1: {
              title: {
                text: '$',
                display: true,
              },
              position: 'right',
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      },
      upsizingBreakdownTable: {
        type: 'table-v2',
        title: 'Upsizing',
        api: 'burgerworksCorporateSalesmanship',
        rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
        sorting: [
          {
            id: 'upsizedCountPercentage',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'upsizingCountSmall',
            title: 'Small',
            align: 'center',
            width: '100px',
            sortable: true,
          },
          {
            key: 'upsizingCountMedium',
            title: 'Medium',
            align: 'center',
            width: '100px',
            sortable: true,
          },
          {
            key: 'upsizingCountLarge',
            title: 'Large',
            align: 'center',
            width: '100px',
            sortable: true,
          },
          {
            key: 'upsizingCountTotal',
            title: 'Total',
            align: 'center',
            width: '100px',
            sortable: true,
          },
          {
            key: 'upsizedCountPercentage',
            title: '% Upsized',
            align: 'center',
            width: '125px',
            sortable: true,
            background: {
              range: [0, 100],
              colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
              value: '<%- values?.upsizedCountPercentageHeatmap %>',
            },
          },
        ],
      },
      upsizingTrendTimeRangeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Upsizing Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
            label: ' ',
          },
        },
      },
      upsizingTrendChart: {
        type: 'line-chart',
        api: 'burgerworksTrendLocationGroupSalesmanshipKpis',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'upsizedCountPercentage',
            label: '% Upsized',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
        ],
        scaleTypes: {
          y: {
            type: 'percent',
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: '% Upsized',
              },
            },
          },
        },
      },
      salesmanshipKpiBreakdownTable: {
        type: 'table-v2',
        title: 'Breakdown By Store',
        api: 'burgerworksCorporateSalesmanship',
        rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
        sorting: [
          {
            id: 'checkAverage',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'checkAverage',
            title: 'Check Average',
            align: 'center',
            width: '150px',
            sortable: true,
            decimal: 2,
            background: {
              range: [0, 100],
              colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
              value: '<%- values?.checkAverageHeatmap %>',
            },
          },
          {
            key: 'itemAttachmentRateDesserts',
            title: '% Desserts',
            align: 'center',
            width: '125px',
            sortable: true,
          },
          {
            key: 'itemAttachmentRateBeverages',
            title: '% Beverages',
            align: 'center',
            width: '125px',
            sortable: true,
          },
          {
            key: 'itemAttachmentRateBacon',
            title: '% Bacon',
            align: 'center',
            width: '125px',
            sortable: true,
          },
          {
            key: 'itemAttachmentRateCheese',
            title: '% Cheese',
            align: 'center',
            width: '125px',
            sortable: true,
          },
          {
            key: 'itemAttachmentRateJalapeno',
            title: '% Jalapeno',
            align: 'center',
            width: '125px',
            sortable: true,
          },
          {
            key: 'salesPerLaborHour',
            title: 'Sales per Labor Hour',
            align: 'center',
            width: '200px',
            decimal: 2,
            sortable: true,
          },
        ],
      },
      coffeeBreakdownTable: {
        type: 'table-v2',
        title: 'Coffee',
        api: 'burgerworksCorporateSalesmanship',
        rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
        sorting: [
          {
            id: 'itemAttachmentRateHotCoffee',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'hotCoffee',
            title: 'Hot',
            align: 'center',
            children: [
              {
                key: 'itemSaleQuantityHotCoffee',
                title: 'Quantity',
                align: 'center',
                width: '100px',
                sortable: true,
              },
              {
                key: 'itemSalesHotCoffee',
                title: 'Sales',
                align: 'center',
                width: '100px',
                sortable: true,
              },
              {
                key: 'itemAttachmentRateHotCoffee',
                title: '%',
                align: 'center',
                width: '100px',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.itemAttachmentRateHotCoffeeHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'icedCoffee',
            title: 'Iced',
            align: 'center',
            children: [
              {
                key: 'itemSaleQuantityIcedCoffee',
                title: 'Quantity',
                align: 'center',
                width: '100px',
                sortable: true,
              },
              {
                key: 'itemSalesIcedCoffee',
                title: 'Sales',
                align: 'center',
                width: '100px',
                sortable: true,
              },
              {
                key: 'itemAttachmentRateIcedCoffee',
                title: '%',
                align: 'center',
                width: '100px',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.itemAttachmentRateIcedCoffeeHeatmap %>',
                },
              },
            ],
          },
        ],
      },
      shakeBreakdownTable: {
        type: 'table-v2',
        title: 'Shake',
        api: 'burgerworksCorporateSalesmanship',
        rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
        sorting: [
          {
            id: 'itemAttachmentRateShake',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'shake',
            title: 'Shake',
            align: 'center',
            children: [
              {
                key: 'itemSaleQuantityShake',
                title: 'Quantity',
                align: 'center',
                width: '100px',
                sortable: true,
              },
              {
                key: 'itemSalesShake',
                title: 'Sales',
                align: 'center',
                width: '100px',
                sortable: true,
              },
              {
                key: 'itemAttachmentRateShake',
                title: '%',
                align: 'center',
                width: '100px',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.itemAttachmentRateShakeHeatmap %>',
                },
              },
            ],
          },
          {
            key: 'malt',
            title: 'Malt',
            align: 'center',
            children: [
              {
                key: 'itemSaleQuantityMalt',
                title: 'Quantity',
                align: 'center',
                width: '100px',
                sortable: true,
              },
              {
                key: 'itemSalesMalt',
                title: 'Sales',
                align: 'center',
                width: '100px',
                sortable: true,
              },
              {
                key: 'itemAttachmentRateMalt',
                title: '%',
                align: 'center',
                width: '100px',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.itemAttachmentRateMaltHeatmap %>',
                },
              },
            ],
          },
        ],
      },
    },
  },
}

export default burgerworksCorporateSalesmanshipConfig
