import { useQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'
import { toUsdStr } from 'pared/utils/number'

import { IApiDataType, IExrayDataType } from '../../../types'

interface ILocationInfo {
  id: string
  code: string
  name: string
}

interface ICorporateSalesmanshipLocations extends ILocationInfo {
  rate: number
  expectRate: number
  checkCount: number
  opportunityCost: number
  annualOpportunityCost: number
}

interface ILocationSalesmanshipDataType
  extends ICorporateSalesmanshipLocations {
  menuCategory: string
}

interface ISalesSummaryNodeType {
  area: string
  locations: ICorporateSalesmanshipLocations[]
  annualOpportunityCost: number
  opportunityCost: number
  totalNetSales: number
  annualTotalNetSales: number
}

type ISalesSummaryType<
  T extends string =
    | 'locationUSalesmanshipSummary'
    | 'corporateUSalesmanshipSummary',
> = Record<
  T,
  {
    nodes: ISalesSummaryNodeType[]
  }
>

const query = gql`
  query GetSummary(
    $iBrandLocationGroupId: Int!
    $iLocationGroupId: Int!
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $hasGroupBy: Boolean!
  ) {
    locationUSalesmanshipSummary(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) @skip(if: $hasGroupBy) {
      nodes {
        area
        locations
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }

    corporateUSalesmanshipSummary(
      iBrandLocationGroupId: $iBrandLocationGroupId
      iLocationGroupId: $iLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) @include(if: $hasGroupBy) {
      nodes {
        area
        locations
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`

const getCorporateSalesSummaryData = (
  dataSource: ISalesSummaryNodeType[],
): IExrayDataType => {
  const totalAnnualOpportunityCost = dataSource?.reduce((sum, d) => {
    return (sum += d.annualOpportunityCost || 0) / 100.0
  }, 0)

  const basisPoint = (
    (10000.0 * totalAnnualOpportunityCost) /
    dataSource[0]?.annualTotalNetSales
  ).toFixed(0)

  return {
    title: 'Sales',
    total: totalAnnualOpportunityCost,
    detail: `For the trailing 30 days, the following restaurants have lower
    than average Attachment Rates.
    Consider running contests or training on suggestive selling.
    Bringing these restaurants up to system averages constitutes a
    ${toUsdStr(totalAnnualOpportunityCost)} annual
    opportunity cost - which amounts to a ${basisPoint} basis
    points improvement for the entire company.`,
    hasDetails: true,
  }
}

const getLocationSalesSummaryData = (
  locationId: number,
  locationName: string,
  dataSource: ISalesSummaryNodeType[],
): IExrayDataType => {
  const salesAction = {
    isBetterThanCompany: false,
    menuCategory: '',
    annualOpportunityCost: 0,
    basisPoint: '0',
  }

  const locationData =
    dataSource?.reduce(
      (
        result: ILocationSalesmanshipDataType[],
        salesData: ISalesSummaryNodeType,
      ) => {
        const location = salesData?.locations?.filter(
          (l) => parseInt(l.id) === locationId,
        )?.[0]
        if (location)
          return [{ menuCategory: salesData.area, ...location }, ...result]
        else return [...result]
      },
      [] as ILocationSalesmanshipDataType[],
    ) || []

  if (locationData.length === 0) {
    salesAction.isBetterThanCompany = true
  } else {
    const cost = Math.max(
      ...locationData?.map(
        (l: ILocationSalesmanshipDataType) => l.annualOpportunityCost,
      ),
    )

    salesAction.annualOpportunityCost = cost / 100

    if (dataSource) {
      salesAction.basisPoint = (
        (10000.0 * cost) /
        dataSource?.[0]?.annualTotalNetSales
      ).toFixed(0)
    }

    const topOpportunity = locationData?.filter(
      (l: ILocationSalesmanshipDataType) => l.annualOpportunityCost === cost,
    )?.[0]

    salesAction.menuCategory = topOpportunity?.menuCategory
  }

  const detail = (() => {
    if (salesAction.isBetterThanCompany)
      return `${locationName} performs better than company averages.`

    return `Your top opportunities to increase check averages are ${
      salesAction.menuCategory
    }.
Consider running contests or training on suggestive selling.  Bringing these upsell categories up to system averages constitutes a ${toUsdStr(
      salesAction.annualOpportunityCost,
    )} annual opportunity cost which amounts to a ${
      salesAction.basisPoint
    } basis point improvement for ${locationName}.
`
  })()

  return {
    title: 'Sales',
    total: salesAction.annualOpportunityCost,
    detail,
  }
}

const useUSales = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const locationGroupId = groupFilter?.ids[0] || 0
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'
  const { brand } = useBrands()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const { data, loading } = useQuery<ISalesSummaryType>(query, {
    variables: {
      iBrandLocationGroupId: brandLocationGroupId,
      iLocationGroupId: locationGroupId,
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
      hasGroupBy,
    },
    skip: !brandLocationGroupId || !groupFilter || !startDate || !endDate,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      return hasGroupBy
        ? getCorporateSalesSummaryData(
            data?.corporateUSalesmanshipSummary?.nodes,
          )
        : getLocationSalesSummaryData(
            locationId,
            locationName,
            data?.locationUSalesmanshipSummary?.nodes,
          )
    }, [locationId, data, locationName]),
    loading,
  }
}

export default useUSales
