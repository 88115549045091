import MuiModal from '@material-ui/core/Modal'
import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useVariables } from '../variables'

export interface IPropsType {
  children: React.ReactElement
  open: string
  onClose: string
}

export interface IConfigsType extends Omit<IPropsType, 'children'> {
  type: 'modal'
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px 70px;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
  overflow: hidden scroll;
  border-radius: 10px;
`

const Modal = ({ children, ...props }: IPropsType) => {
  const { variables } = useVariables()
  const { open, onClose } = useMemo(
    () => ({
      open: _.get(variables, props.open) as boolean,
      onClose: _.get(variables, props.onClose) as (open: boolean) => void,
    }),
    [props, variables],
  )

  return (
    <MuiModal open={open} onClose={onClose}>
      <Container>{children}</Container>
    </MuiModal>
  )
}

export default Modal
