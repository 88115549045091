import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import buildMetricValuesHook from 'pared/Routes/renderer/utils/unifiedEndpoint/buildMetricValuesHook'
import { getBrandLocationGroupId } from 'pared/utils/brand'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'

interface IGuestSummaryNodeType {
  problemPercentage: number
  mostCommonDay: string[]
  mostCommonDaypart: string[]
}

interface IGuestSummaryType {
  locationGuestSummaryV3: {
    nodes: IGuestSummaryNodeType[]
  }
}

const query = gql`
  query LocationGuestSummaryV3(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
  ) {
    locationGuestSummaryV3(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocationId: $iLocationId
    ) {
      nodes {
        problemPercentage
        mostCommonDay
        mostCommonDaypart
      }
    }
  }
`

const useLocationSmgKpis = buildMetricValuesHook({
  groupFilterType: 'store',
  metrics: {
    codes: [
      'smg_inaccurate_count',
      'smg_bad_taste_count',
      'smg_order_not_on_time_count',
      'smg_rude_team_members_count',
      'smg_not_clean_count',
      'smg_wingyou_score',
    ],
  },
})

const useSystemwideGroupSmgKpis = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['smg_wingyou_score'],
  },
})

const useLocationGuestAiSummary = (): IApiDataType => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const brandLocationGroupId = getBrandLocationGroupId()

  const { data: guestReviewData, loading: isGuestReviewDataLoading } =
    useQuery<IGuestSummaryType>(query, {
      variables: {
        iLocationId: groupFilter?.ids?.[0],
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !groupFilter,
    })

  const { data: locationSmgData, loading: isLocationSmgDataLoading } =
    useLocationSmgKpis()
  const { data: systemwideSmgData, loading: isSystemwideSmgDataLoading } =
    useSystemwideGroupSmgKpis({
      customLocationGroupIds: [brandLocationGroupId],
      shouldBypassRowLevelSecurity: true,
    })

  return {
    data: useMemo(() => {
      const guestReviewSummary =
        guestReviewData?.locationGuestSummaryV3.nodes[0]

      const summaryMessage = (() => {
        if (!guestReviewSummary) return `There are no SMG comments.`

        if (guestReviewSummary.problemPercentage) {
          return `${guestReviewSummary.problemPercentage}% of your SMG responses had a problem.`
        } else {
          return `Great job! None of your SMG responses had a problem!`
        }
      })()

      const areasToImprove: string[] = []
      if (locationSmgData?.smgBadTasteCount) {
        areasToImprove.push('Taste')
      }
      if (locationSmgData?.smgInaccurateCount) {
        areasToImprove.push('Accuracy')
      }
      if (locationSmgData?.smgNotCleanCount) {
        areasToImprove.push('Not Clean')
      }
      if (locationSmgData?.smgOrderNotOnTimeCount) {
        areasToImprove.push('Order Not On Time')
      }
      if (locationSmgData?.smgRudeTeamMembersCount) {
        areasToImprove.push('Rude Team Members')
      }

      let wingyouInsight = ''
      if (
        locationSmgData?.smgWingyouScore &&
        systemwideSmgData?.smgWingyouScore
      ) {
        wingyouInsight = `Wing You completion rate is ${(
          locationSmgData.smgWingyouScore as number
        ).toFixed(2)}% which is `
        if (
          locationSmgData.smgWingyouScore > systemwideSmgData.smgWingyouScore
        ) {
          wingyouInsight += `higher than system average.`
        } else if (
          locationSmgData.smgWingyouScore < systemwideSmgData.smgWingyouScore
        ) {
          wingyouInsight += `lower than system average.`
        } else {
          wingyouInsight += `the same as system average.`
        }
      }

      const allInsights: string[] = []
      if (areasToImprove.length > 0) {
        allInsights.push(
          `The top problem ${
            areasToImprove.length > 1 ? 'were' : 'was'
          }: ${areasToImprove.join(', ')}.`,
        )
      }
      if (Array.isArray(guestReviewSummary?.mostCommonDay)) {
        allInsights.push(
          `${guestReviewSummary.mostCommonDay
            .map((day: string) => `${day}`)
            .join(', ')} have the most negative reviews.`,
        )
      }
      if (allInsights.length > 0) {
        allInsights.push('Address these issues with your team.')
      }
      if (wingyouInsight) {
        allInsights.unshift(wingyouInsight)
      }

      const insightMessage =
        allInsights.length > 0
          ? allInsights.join(' ')
          : 'Data is not enough to generate insights ...'

      const hasEnoughData =
        guestReviewSummary && locationSmgData && systemwideSmgData

      return {
        conclusion: hasEnoughData ? summaryMessage : 'Loading ...',
        insight: hasEnoughData ? insightMessage : 'Loading ...',
      }
    }, [
      guestReviewData,
      isGuestReviewDataLoading,
      locationSmgData,
      isLocationSmgDataLoading,
      systemwideSmgData,
      isSystemwideSmgDataLoading,
    ]),
    loading:
      isGuestReviewDataLoading ||
      isLocationSmgDataLoading ||
      isSystemwideSmgDataLoading,
  }
}

export default useLocationGuestAiSummary
